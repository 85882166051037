import { CHALLENGE_RESULT_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { ChallengeResults } from 'core/models/Challenge';

const initialState: { challengeResult: ChallengeResults[], isLoading: boolean } = {
  challengeResult: [],
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    challengeResult: ChallengeResults[];
  }
}

export default function challengeResultReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case CHALLENGE_RESULT_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case CHALLENGE_RESULT_ACTION.SUCCESS:
      const { challengeResult } = action.payload;
      return { ...state, challengeResult, isLoading: false }

    case CHALLENGE_RESULT_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}