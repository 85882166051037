import history from "App/History";
import clsx from "clsx";
import { PlayoffRound } from "core/models/PlayoffRound"
import Groups from 'shared/icons/Groups';
import SuperModerator from 'shared/icons/SuperModerator';
import Team from 'shared/icons/Team';

interface PlayoffRoundCardProps {
  roundDetails: PlayoffRound;
}

export default function PlayoffRoundCard({ roundDetails }: PlayoffRoundCardProps) {

  function handleRoundClick() {
    history.push(`/playoff/tournaments/${roundDetails.tournamentId}/${roundDetails.roundSequence}`)
  }

  return (
    <div className={clsx("playoff-round-card-wrapper", roundDetails.isSuperModerator && "moderator")} onClick={handleRoundClick}>
      <div className={clsx("playoff-round-img-wrapper ")}>
        <img src={roundDetails.media.banner || roundDetails.media.icon} alt={roundDetails.roundName} className='playoff-round-img' />
        {
          roundDetails.isSuperModerator &&
          <div className='playoff-round-super-moderator-symbol'>
            <SuperModerator fontSize='45' />
          </div>
        }
      </div>
      <div className='playoff-round-detail'>
        <div className='round-name'>{roundDetails.roundName}</div>
        <div className='playoff-round-name'>
          {roundDetails.name}
        </div>
        <div className='team-groups'>
          <div className='team-groups-detail'>
            <div className='team-groups-icon'>
              <Team className='icon' />
              <div className='team-groups-text'>Teams</div>
            </div>
            <div className='team-groups-text'>
              {roundDetails.totalTeams}
            </div>
          </div>
          <div className='team-groups-detail'>
            <div className='team-groups-icon'>
              <Groups className='icon' />
              <div className='team-groups-text'>Groups</div>
            </div>
            <div className='team-groups-text'>
              {roundDetails.groupCount}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
