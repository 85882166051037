import axios from 'axios'
import { handleError } from 'core/utils/HandleError'
import { handleResponse } from 'core/utils/HandleResponse'
import { AuthHttpClient } from 'core/utils/HttpClient'

export function getShorts(spaceId: string | number, params: any) {
  return AuthHttpClient
    .get(`/moderator/space/${spaceId}/shorts`, { params })
    .then(handleResponse)
    .catch(handleError)
}

export function getShortsDetail(spaceId: number | string, shortsId: string | number) {
  return AuthHttpClient
    .get(`/moderator/space/${spaceId}/shorts/${shortsId}`)
    .then(handleResponse)
    .catch(handleError)
}

export function deleteShorts(shortsId: any) {
  return AuthHttpClient
    .delete(`/moderator/shorts/${shortsId}`)
    .then(handleResponse)
    .catch(handleError)
}

export function requestShorts(shortsData: any) {
  return AuthHttpClient
    .post('/moderator/shorts/request', shortsData)
    .then(handleResponse)
    .catch(handleError)
}

export function uploadShorts(url: string, file: File, cb?: (data: number) => void) {
  return axios.put(url, file, {
    headers: { 'Content-Type': file.type || 'application/octet-stream' },
    onUploadProgress: (progressEvent) => {
      if (cb) {
        cb(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      }
    }
  })
    .then(handleResponse)
    .catch(handleError)
}

export function processShorts(shortsData: any) {
  return AuthHttpClient
    .post('/moderator/shorts/process', shortsData)
    .then(handleResponse)
    .catch(handleError)
}

export function createShortsComment(shortComment: any) {
  return AuthHttpClient
    .post('/moderator/shorts/comment', shortComment)
    .then(handleResponse)
    .catch(handleError)
}

export function getShortsComment(spaceId: string | number, shortsId: string | number, params: any) {
  return AuthHttpClient
    .get(`/moderator/space/${spaceId}/shorts/${shortsId}/comment`, { params })
    .then(handleResponse)
    .catch(handleError)
}

export function deleteShortsComment(spaceId: string | number, commentId: string | number) {
  return AuthHttpClient
    .delete(`/moderator/space/${spaceId}/shorts/comment/${commentId}`)
    .then(handleResponse)
    .catch(handleError)
}