
import { TypedDispatch, TypedThunk } from 'App/Store'
import { PLAYOFF_TOURNAMENT_LIST_ACTION } from 'core/constants/Actions'
import { getPlayoffTournamentList } from 'core/services/Groups'

interface Params {
  page: number;
  size: number;
  resultStatus?: "live" | "completed";
  tournamentType: "tournament" | "Rivalry Zone" | "Challenges";
}

export function playoffTournamentList(
  params: Params
): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {

    dispatch({ type: PLAYOFF_TOURNAMENT_LIST_ACTION.REQUEST })
    const apiResponse = await getPlayoffTournamentList(params)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: PLAYOFF_TOURNAMENT_LIST_ACTION.SUCCESS,
        payload: { playoffTournaments: apiResponse.data.playoffTournaments, count: apiResponse.data.count, currentPage: params.page }
      })
    } else {
      dispatch({
        type: PLAYOFF_TOURNAMENT_LIST_ACTION.FAILURE
      })
    }
  }
}