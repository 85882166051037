import { TypedDispatch, TypedThunk } from 'App/Store'
import { GROUP_LIST_ACTION } from 'core/constants/Actions'
import { getGroupList } from 'core/services/Groups'

export function groupList(params: any): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: GROUP_LIST_ACTION.REQUEST })
    const apiResponse = await getGroupList(params)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: GROUP_LIST_ACTION.SUCCESS,
        payload: { groups: apiResponse.data.groups }
      })
    } else {
      dispatch({
        type: GROUP_LIST_ACTION.FAILURE
      })
    }
  }
}