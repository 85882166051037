import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Button, Drawer, Modal } from 'rsuite'
import { useTypedDispatch, useTypedSelector } from 'App/Store'
import { hideLoader, showLoader } from 'redux/action/loaderAction'
import { groupList } from 'redux/action/groupListActions'
import PlayoffCard from 'components/PlayoffCard'
import history from 'App/History'
import AutoSchedule from 'components/AutoSchedule'

interface UrlParams {
  tournamentId: string;
  round: string;
}

export default function PlayoffPage() {
  const playoffsDetails = useTypedSelector(state => state.groups)
  const { groups, isLoading } = playoffsDetails
  const dispatch = useTypedDispatch()
  const urlParams = useParams<UrlParams>()
  const [autoSchedulePopup, setAutoSchedulePopup] = useState(false)
  const [isMobile, setMobile] = useState(true)
  const [screenWidth, setScreenWidth] = useState(0)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])


  useEffect(() => {
    dispatch(groupList({ id: urlParams.tournamentId, round: urlParams.round }))
  }, [dispatch, urlParams])

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [isLoading, dispatch])

  function handleAutoScheduleOpen() {
    setAutoSchedulePopup(true)
  }
  function handleDashBoard() {
    history.push(`/playoff/tournaments/schedule/${urlParams.tournamentId}/${urlParams.round}`)
  }


  return (
    <>
      <div className='playoff-btn-container'>
        <div className='playoff-grp-title'>Groups</div>
        <div>
          <Button appearance="primary" className='schedule-btn' style={{ marginRight: 10 }} onClick={handleAutoScheduleOpen}>Schedule</Button>
          <Button appearance="primary" id='schedule-button' className='schedule-btn' onClick={handleDashBoard}>View Match Schedule</Button>
        </div>
      </div>
      <Row gutter={25}>
        {
          groups.map(
            group =>
              <Col key={group.groupId} xs={24} sm={12} md={6} lg={6} style={{ marginBottom: "2rem" }} >
                <PlayoffCard group={group} />
              </Col>
          )
        }
      </Row>
      {
        isMobile ?
          <Drawer show={autoSchedulePopup} onHide={() => setAutoSchedulePopup(false)} placement='bottom' style={{ height: 'var(--app-height)' }}>
            <Drawer.Header>
              <Drawer.Title>
                {`You're Scheduling for Round ${groups[0]?.roundSequence}`}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <AutoSchedule
                group={groups[0]}
                onClose={() => setAutoSchedulePopup(false)}
                isSuperModerator={groups.some((playoff) => playoff.isSuperModerator)}
              />
            </Drawer.Body>
          </Drawer>
          :
          <Modal show={autoSchedulePopup} size="md" overflow onHide={() => setAutoSchedulePopup(false)} backdrop="static">
            <Modal.Header>
              <Modal.Title>
                {`You're Scheduling for Round ${groups[0]?.roundSequence}`}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AutoSchedule
                group={groups[0]}
                onClose={() => setAutoSchedulePopup(false)}
                isSuperModerator={groups.some((playoff) => playoff.isSuperModerator)}
              />
            </Modal.Body>
          </Modal>
      }

    </>
  )
}