import { TypedDispatch, TypedThunk } from 'App/Store'
import { ASSIGNED_SPACE_POST_DETAILS_ACTION } from 'core/constants/Actions'
import { getSpacePostById } from 'core/services/Space'

export function getSpacePostDetails(postId: string | number, spaceId: string | number): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: ASSIGNED_SPACE_POST_DETAILS_ACTION.REQUEST })
    const apiResponse = await getSpacePostById(postId, spaceId,)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: ASSIGNED_SPACE_POST_DETAILS_ACTION.SUCCESS,
        payload: { spacePost: apiResponse.data.spacePost }
      })
    } else {
      dispatch({
        type: ASSIGNED_SPACE_POST_DETAILS_ACTION.FAILURE
      })
    }
  }
}