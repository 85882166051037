import history from "App/History"
import { useTypedDispatch } from "App/Store"
import { DEFAULT_API_ERROR } from "core/constants/Defaults"
import { login } from "core/services/Auth"
import { clearUser, setToken } from "core/services/Storage"
import { useCallback, useEffect, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { hideLoader, showLoader } from "redux/action/loaderAction"
import { useNotification } from "shared/hooks/useNotification"

export default function Callback() {

  const dispatch = useTypedDispatch()
  const showNotification = useNotification()
  const location = useLocation()
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])

  const handleLogin = useCallback(async (token: string) => {
    dispatch(showLoader())
    const response = await login({ token, loginType: 'gauth' })
    dispatch(hideLoader())
    if (response.status && response.data) {
      setToken(response.data.token)
      history.replace('/playoff/tournaments')
      showNotification({
        message: 'Welcome to the Moderator Dashboard',
        title: 'Success',
        type: 'success'
      })
    } else {
      history.replace('/')
      showNotification({
        message: response.message ?? DEFAULT_API_ERROR,
        title: 'Error',
        type: 'error'
      })
    }
  }, [dispatch, showNotification])

  useEffect(() => {
    const code = query.get('code')
    if (code) {
      handleLogin(code)
    } else {
      clearUser()
      history.replace('/login')
    }
  }, [handleLogin, query])

  return <></>

}
