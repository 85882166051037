import { FC } from 'react'
import { FormGroup, InputGroup, Input, Icon, FormControl, ControlLabel } from 'rsuite'
import { IconNames } from 'rsuite/lib/Icon'

interface Meta {
  touched: boolean;
  error: string;
}

interface ComponentProps {
  icon?: IconNames;
  input: any;
  placeholder: string;
  meta?: Meta;
  type: string;
  label?: string;
  className?: string;
  disabled?: boolean;
}

const CustomInput: FC<ComponentProps> = ({ placeholder, input, type, disabled }) => (
  <Input placeholder={placeholder} {...input} type={type} autoComplete="off" disabled={disabled} />
)

const InputWithLabel: FC<ComponentProps> = (props: ComponentProps) => {
  const { placeholder = "Input", icon, meta, input, type = "text", label, disabled, className } = props
  return (
    <FormGroup className={className}>
      <ControlLabel>{label}</ControlLabel>
      <InputGroup inside style={{ width: '100%' }}>
        <FormControl
          accepter={CustomInput}
          placeholder={placeholder}
          input={input}
          type={type}
          disabled={disabled}
          errorMessage={meta?.touched && meta.error}
        />
        {
          icon &&
          <InputGroup.Addon>
            <Icon icon={icon} />
          </InputGroup.Addon>
        }
      </InputGroup>
    </FormGroup>
  )
}

export default InputWithLabel