import { IconTypes } from "./Icons";

export default function Comment({ color = '#AAAAB0', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path d="M5.60587 14.1001L5.28604 14.4199L3.89493 15.811C3.89491 15.8111 3.8949 15.8111 3.89488 15.8111C3.86578 15.8402 3.84597 15.8773 3.83794 15.9177C3.8299 15.9581 3.83403 16 3.84979 16.0381C3.86556 16.0761 3.89225 16.1087 3.9265 16.1315L3.64937 16.5462L3.9265 16.1315C3.96072 16.1544 4.00095 16.1666 4.04212 16.1667L5.60587 14.1001ZM5.60587 14.1001L5.31961 13.7499M5.60587 14.1001L5.31961 13.7499M5.31961 13.7499C4.37355 12.5926 3.84951 11.1478 3.83368 9.65306C3.81785 8.15835 4.31117 6.70274 5.2325 5.52564C6.15383 4.34854 7.44832 3.52003 8.90308 3.17636C10.3578 2.83269 11.8862 2.99433 13.2369 3.6347C14.5876 4.27507 15.6802 5.35603 16.3349 6.69982C16.9896 8.0436 17.1675 9.5702 16.8394 11.0285C16.5112 12.4869 15.6966 13.7901 14.5294 14.7239C13.3622 15.6578 11.9119 16.1666 10.4171 16.1667C10.4171 16.1667 10.4171 16.1667 10.4171 16.1667H4.04223L5.31961 13.7499Z" stroke="#B7B5C6" />
    </svg>
  )
}