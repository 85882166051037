import decodeJwt from 'jwt-decode'
import { getToken } from './Storage'
import { DefaultHttpClient } from '../utils/HttpClient'
import { handleResponse } from '../utils/HandleResponse'
import { handleError } from '../utils/HandleError'

export function isLoggedIn() {
  const token = getToken()
  if (token) {
    try {
      decodeJwt(token)
      return true
    } catch {
      return false
    }
  }
  return false
}

export function login(data: any) {
  return DefaultHttpClient
    .post('/auth/moderator', data)
    .then(handleResponse)
    .catch(handleError)
}
export function switchLogin(data: any) {
  return DefaultHttpClient
    .post('/auth/moderator/switch', data)
    .then(handleResponse)
    .catch(handleError)
}

export function loginSwitchCheck(token: any) {
  return DefaultHttpClient
    .get(`/auth/test/switch?token=${token}`)
    .then(handleResponse)
    .catch(handleError)
}