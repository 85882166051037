import { handleError } from '../utils/HandleError'
import { handleResponse } from '../utils/HandleResponse'
import { AuthHttpClient } from '../utils/HttpClient'

export function getPlayoffTournamentList(params: any) {
  return AuthHttpClient
    .get('/moderator/dashboard', { params })
    .then(handleResponse)
    .catch(handleError)
}

export function getGroupList(params: any) {
  return AuthHttpClient
    .get('/moderator/playoff/tournament', { params })
    .then(handleResponse)
    .catch(handleError)
}

export function getplayoffRounds(params: any) {
  return AuthHttpClient
    .get('/moderator/playoff/rounds', { params })
    .then(handleResponse)
    .catch(handleError)
}

export function getGroupDetails(params: any) {
  return AuthHttpClient
    .get('/moderator/playoff', { params })
    .then(handleResponse)
    .catch(handleError)
}

export function sendRoomCredentials(data: any) {
  return AuthHttpClient
    .post('/moderator/credentials/send', data)
    .then(handleResponse)
    .catch(handleError)
}

export function getResultFormConfigs(params: any) {
  return AuthHttpClient
    .get('/moderator/tournament/config', { params })
    .then(handleResponse)
    .catch(handleError)
}

export function sendResults(data: any) {
  return AuthHttpClient
    .post('/moderator/results', data)
    .then(handleResponse)
    .catch(handleError)
}

export function getTeamScores(params: any) {
  return AuthHttpClient
    .get('/moderator/player', { params })
    .then(handleResponse)
    .catch(handleError)
}

export function getPlacementPoints(params: any) {
  return AuthHttpClient
    .get('/moderator/playoff/config', { params })
    .then(handleResponse)
    .catch(handleError)
}

export function addRewards(data: any) {
  return AuthHttpClient
    .put('/moderator/playoff/reward', data)
    .then(handleResponse)
    .catch(handleError)
}

export function getRewards(tournamentId: string) {
  return AuthHttpClient
    .get(`/moderator/playoff/reward/${tournamentId}`)
    .then(handleResponse)
    .catch(handleError)
}
export function autoScheduleRoundWise(data: any) {
  return AuthHttpClient
    .put('/moderator/schedule/auto', data)
    .then(handleResponse)
    .catch(handleError)
}

export function getScheduleList(params: any) {
  return AuthHttpClient
    .get('/moderator/schedule/view', params)
    .then(handleResponse)
    .catch(handleError)
}
export function getPlayoffModerators(tournamentId: any) {
  return AuthHttpClient
    .get(`/moderator/schedule/moderator/${tournamentId}`)
    .then(handleResponse)
    .catch(handleError)
}

export function getScheduledGroupWiseMatches(params: any) {
  return AuthHttpClient
    .get('/moderator/schedule/manual', { params })
    .then(handleResponse)
    .catch(handleError)
}

export function manualScheduleGroupWise(data: any) {
  return AuthHttpClient
    .put('/moderator/schedule/manual', data)
    .then(handleResponse)
    .catch(handleError)
}

export function banTeams(data: any) {
  return AuthHttpClient
    .put('/moderator/playoff/team/ban', data)
    .then(handleResponse)
    .catch(handleError)
}
