
import { IconTypes } from "./Icons";

export default function VersusMode({ color = '#AAAAB0', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <rect width="32" height="32" rx="12" fill="#272c35" className="fill-path" />
      <path className='stroke-fill' d="M21.0888 18.6784H20.2853C20.0737 18.676 19.8717 18.5902 19.7229 18.4398C19.5742 18.2894 19.4908 18.0863 19.4908 17.8748C19.4908 17.6632 19.5742 17.4602 19.7229 17.3098C19.8716 17.1593 20.0737 17.0736 20.2852 17.0712H22.696C22.767 17.071 22.835 17.0427 22.8852 16.9925C22.9354 16.9423 22.9637 16.8743 22.9639 16.8033V15.7318C22.9637 15.6609 22.9354 15.5929 22.8852 15.5427C22.835 15.4925 22.767 15.4642 22.696 15.464H20.2852C19.9684 15.4637 19.6547 15.5258 19.3619 15.6468C19.0691 15.7678 18.803 15.9453 18.5789 16.1692C18.3548 16.3931 18.177 16.659 18.0556 16.9516C17.9343 17.2443 17.8719 17.558 17.8719 17.8748C17.8719 18.1916 17.9343 18.5053 18.0556 18.7979C18.177 19.0906 18.3548 19.3565 18.5789 19.5804C18.803 19.8043 19.0691 19.9818 19.3619 20.1028C19.6547 20.2238 19.9684 20.2859 20.2852 20.2856H21.0888C21.3003 20.288 21.5024 20.3737 21.6511 20.5242C21.7999 20.6746 21.8833 20.8776 21.8833 21.0892C21.8833 21.3007 21.7999 21.5038 21.6512 21.6542C21.5024 21.8046 21.3004 21.8904 21.0888 21.8928H18.678C18.6071 21.893 18.539 21.9213 18.4889 21.9715C18.4387 22.0217 18.4104 22.0897 18.4102 22.1606V23.2321C18.4104 23.3031 18.4387 23.3711 18.4889 23.4213C18.539 23.4715 18.6071 23.4998 18.678 23.5H21.0888C21.4056 23.5003 21.7194 23.4382 22.0122 23.3172C22.305 23.1962 22.571 23.0187 22.7952 22.7948C23.0193 22.5709 23.1971 22.305 23.3184 22.0123C23.4397 21.7197 23.5022 21.406 23.5022 21.0892C23.5022 20.7724 23.4397 20.4587 23.3184 20.166C23.1971 19.8734 23.0193 19.6075 22.7952 19.3836C22.571 19.1597 22.305 18.9822 22.0122 18.8612C21.7194 18.7402 21.4056 18.678 21.0888 18.6784Z" fill={color} />
      <path className='stroke-fill' d="M11.5394 16.4364L14.06 9.17454C14.0715 9.14078 14.0761 9.10507 14.0737 9.0695C14.0713 9.03393 14.0618 8.99919 14.0459 8.96729C14.03 8.9354 14.0078 8.90699 13.9808 8.88371C13.9538 8.86042 13.9225 8.84273 13.8886 8.83166L12.8653 8.51291C12.7978 8.49198 12.7248 8.49852 12.6621 8.53112C12.5994 8.56372 12.5521 8.61974 12.5305 8.68701L11.2876 12.6621L10.0447 8.68701C10.023 8.61982 9.97565 8.56388 9.91299 8.5313C9.85033 8.49871 9.77737 8.49211 9.70987 8.51291L8.68662 8.83166C8.65273 8.84273 8.62137 8.86043 8.59437 8.88371C8.56736 8.90699 8.54525 8.9354 8.5293 8.9673C8.51336 8.99919 8.5039 9.03393 8.50148 9.0695C8.49905 9.10508 8.50372 9.14078 8.51519 9.17454L11.0331 16.4364C11.0523 16.488 11.0869 16.5326 11.1321 16.564C11.1774 16.5955 11.2311 16.6123 11.2862 16.6123C11.3414 16.6123 11.3951 16.5955 11.4404 16.564C11.4856 16.5326 11.5202 16.488 11.5394 16.4364Z" fill={color} />
      <path className='stroke-fill' d="M18.0083 14.3926H17.0119L17.5985 12.0461C17.6078 12.0067 17.6081 11.9657 17.5995 11.9261C17.5908 11.8866 17.5733 11.8495 17.5484 11.8176C17.5234 11.7857 17.4916 11.7598 17.4553 11.7419C17.419 11.7239 17.3792 11.7144 17.3387 11.7139H15.3297C15.2733 11.7144 15.2184 11.7324 15.1728 11.7655C15.1272 11.7987 15.093 11.8453 15.0752 11.8988L13.7359 15.9167C13.723 15.9569 13.7198 15.9996 13.7265 16.0412C13.7332 16.0829 13.7495 16.1224 13.7743 16.1566C13.7991 16.1907 13.8315 16.2186 13.8691 16.2379C13.9066 16.2572 13.9481 16.2674 13.9903 16.2677H15.6832L15.0645 19.9749C15.0557 20.0375 15.0693 20.1011 15.1028 20.1547C15.1364 20.2083 15.1877 20.2483 15.2478 20.2678C15.3078 20.2873 15.3729 20.285 15.4315 20.2613C15.49 20.2377 15.5384 20.1941 15.5681 20.1383L18.2467 14.781C18.2668 14.7402 18.2763 14.6949 18.2742 14.6495C18.2721 14.604 18.2585 14.5598 18.2347 14.521C18.2109 14.4823 18.1776 14.4501 18.138 14.4277C18.0985 14.4052 18.0538 14.3932 18.0083 14.3926Z" fill={color} />
      <path className='stroke-fill' d="M14.508 17.1123L10.4526 21.1677C10.4275 21.1925 10.4075 21.2221 10.3938 21.2546C10.3801 21.2872 10.373 21.3221 10.3729 21.3575C10.3728 21.3928 10.3797 21.4278 10.3931 21.4604C10.4066 21.4931 10.4264 21.5228 10.4514 21.5477C10.4763 21.5727 10.506 21.5925 10.5387 21.606C10.5713 21.6194 10.6063 21.6263 10.6416 21.6262C10.677 21.6261 10.7119 21.619 10.7445 21.6053C10.777 21.5916 10.8066 21.5716 10.8314 21.5465L14.8868 17.4911C14.9354 17.4405 14.9622 17.3729 14.9615 17.3028C14.9608 17.2327 14.9327 17.1656 14.8831 17.116C14.8335 17.0664 14.7664 17.0383 14.6963 17.0376C14.6262 17.0369 14.5586 17.0637 14.508 17.1123Z" fill={color} />
      <path className='stroke-fill' d="M18.4102 13.8568C18.4454 13.8568 18.4802 13.8499 18.5127 13.8364C18.5452 13.823 18.5747 13.8032 18.5996 13.7783L21.5461 10.8318C21.5946 10.7812 21.6214 10.7136 21.6207 10.6435C21.62 10.5735 21.5918 10.5064 21.5422 10.4569C21.4927 10.4073 21.4257 10.3791 21.3556 10.3784C21.2855 10.3777 21.2179 10.4045 21.1673 10.453L18.2208 13.3995C18.1842 13.4373 18.1595 13.485 18.1496 13.5366C18.1398 13.5883 18.1452 13.6417 18.1654 13.6903C18.1855 13.7389 18.2194 13.7806 18.2629 13.8102C18.3064 13.8397 18.3576 13.8559 18.4102 13.8568Z" fill={color} />
    </svg>
  )
}