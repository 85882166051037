import icon from '../assets/icons/battlexo-icon.png'
import { useSelector } from 'react-redux'
import { Store } from './Store'
import { Fragment } from 'react'

export default function Loader() {
  const isLoading = useSelector((state: Store) => state.loader.isLoading)
  return (
    isLoading ?
      <div className="loader-overlay">
        <div className="loader-icon">
          <img src={icon} alt="BattleXo" />
        </div>
      </div>
      :
      <Fragment />
  )
}
