import { IconTypes } from './Icons'

export default function Members({ fontSize = '1.6rem', rotate = 0, className = '' }: Omit<IconTypes, 'color'>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M6.3002 4.20059C6.3002 5.36038 5.35999 6.30059 4.2002 6.30059C3.0404 6.30059 2.1002 5.36038 2.1002 4.20059C2.1002 3.04079 3.0404 2.10059 4.2002 2.10059C5.35999 2.10059 6.3002 3.04079 6.3002 4.20059Z" fill="white" fillOpacity="0.4" />
      <path d="M11.9002 4.20059C11.9002 5.36038 10.96 6.30059 9.8002 6.30059C8.6404 6.30059 7.7002 5.36038 7.7002 4.20059C7.7002 3.04079 8.6404 2.10059 9.8002 2.10059C10.96 2.10059 11.9002 3.04079 11.9002 4.20059Z" fill="white" fillOpacity="0.4" />
      <path d="M9.05059 11.9006C9.08329 11.672 9.10021 11.4383 9.10021 11.2006C9.10021 10.056 8.70774 9.003 8.05002 8.16893C8.56487 7.87107 9.16263 7.70059 9.80021 7.70059C11.7332 7.70059 13.3002 9.26759 13.3002 11.2006V11.9006H9.05059Z" fill="white" fillOpacity="0.4" />
      <path d="M4.2002 7.70059C6.13319 7.70059 7.7002 9.26759 7.7002 11.2006V11.9006H0.700195V11.2006C0.700195 9.26759 2.2672 7.70059 4.2002 7.70059Z" fill="white" fillOpacity="0.4" />
    </svg>
  )
}
