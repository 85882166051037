import { Table, TableCellProps, Button } from 'rsuite'
import { GroupResult } from "core/models/GropuResult"
import { useEffect, useState } from 'react';

interface RoundResultProps {
  roundResults: GroupResult[];
  onTeamClick(data: any): void;
}

interface ActionCellProps extends TableCellProps {
  onClick(rowData: any): void;
}

function ActionCell({ onClick, rowData, ...props }: ActionCellProps) {
  return (
    <Table.Cell {...props}>
      <Button id='result-view-detail' style={{ maxWidth: 100, height: 'auto', letterSpacing: '-0.1rem' }} appearance="primary" onClick={() => onClick(rowData)}>View Details</Button>
    </Table.Cell>
  )
}

export default function RoundResult({ roundResults, onTeamClick }: RoundResultProps) {
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])

  return (
    <div className="round-result-container">
      {
        roundResults.length && <h5 className='match-result-subhead'>Results</h5>
      }
      {
        roundResults.map(
          roundDetails =>
            <div key={`${roundDetails.matchSequence}${roundDetails.roundSequence}`} style={{ marginBottom: "2.4rem" }}>
              <h5 className='match-result-head'>{`Round ${roundDetails.roundSequence} Match ${roundDetails.matchSequence}`}</h5>
              <Table wordWrap height={400} data={roundDetails.teamResults} className='match-result-data'>
                <Table.Column flexGrow={isMobile ? 1 : 1} width={isMobile ? 100 : 150} >
                  <Table.HeaderCell style={{ fontWeight: 600 }} >Team Name</Table.HeaderCell>
                  <Table.Cell dataKey="teamName" className='match-result-value' />
                </Table.Column>
                <Table.Column width={isMobile ? 95 : 150}>
                  <Table.HeaderCell style={{ fontWeight: 600 }}>Team Score</Table.HeaderCell>
                  <Table.Cell dataKey="teamScore" className='match-result-value' />
                </Table.Column>
                <Table.Column width={isMobile ? 95 : 150}>
                  <Table.HeaderCell style={{ fontWeight: 600 }}>Action</Table.HeaderCell>
                  <ActionCell onClick={(rowData) => onTeamClick({ ...rowData, ...roundDetails })} className='match-result-value' />
                </Table.Column>
              </Table>
            </div>
        )
      }
    </div >
  )
}
