import { IconTypes } from "./Icons";

export default function SuperModerator({ color = '#62676B', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="40"
      height="50"
      viewBox="0 0 40 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path d="M0 0H40V21C40 32.0457 31.0457 41 20 41C8.95431 41 0 32.0457 0 21V0Z" fill="url(#paint0_linear_1746_5670)" />
      <path d="M13.3332 24.667H26.6665V26.3337H13.3332V24.667ZM27.4998 12.167C27.2788 12.167 27.0669 12.2548 26.9106 12.4111C26.7543 12.5674 26.6665 12.7793 26.6665 13.0003C26.6666 13.0951 26.6847 13.189 26.7198 13.277C26.7542 13.3621 26.8011 13.4416 26.859 13.5128L25.0923 14.9228L23.3282 16.3337L21.8665 13.7728L20.4065 11.2128C20.5318 11.1417 20.6374 11.0406 20.714 10.9187C20.7922 10.7931 20.8335 10.6482 20.8332 10.5003C20.8332 10.2793 20.7454 10.0674 20.5891 9.91107C20.4328 9.75479 20.2209 9.66699 19.9998 9.66699C19.7788 9.66699 19.5669 9.75479 19.4106 9.91107C19.2543 10.0674 19.1665 10.2793 19.1665 10.5003C19.1662 10.6482 19.2075 10.7931 19.2857 10.9187C19.3623 11.0406 19.4679 11.1417 19.5932 11.2128L18.1307 13.7728L16.6682 16.3337L14.9048 14.9237L13.1407 13.5128C13.1986 13.4416 13.2455 13.3621 13.2798 13.277C13.315 13.189 13.3331 13.0951 13.3332 13.0003C13.3332 12.8355 13.2843 12.6744 13.1927 12.5374C13.1012 12.4003 12.971 12.2935 12.8187 12.2304C12.6665 12.1674 12.4989 12.1509 12.3373 12.183C12.1756 12.2152 12.0271 12.2945 11.9106 12.4111C11.794 12.5276 11.7147 12.6761 11.6825 12.8378C11.6504 12.9994 11.6669 13.167 11.7299 13.3192C11.793 13.4715 11.8998 13.6016 12.0369 13.6932C12.1739 13.7848 12.335 13.8337 12.4998 13.8337C12.5114 13.8333 12.5229 13.8314 12.534 13.8278C12.5451 13.8239 12.5565 13.8211 12.5682 13.8195L12.9507 18.4103L13.3332 23.0003H26.6665L27.049 18.4103L27.4315 13.8195C27.4429 13.8212 27.454 13.824 27.4648 13.8278C27.4762 13.8315 27.4879 13.8334 27.4998 13.8337C27.7208 13.8337 27.9328 13.7459 28.0891 13.5896C28.2454 13.4333 28.3332 13.2213 28.3332 13.0003C28.3332 12.7793 28.2454 12.5674 28.0891 12.4111C27.9328 12.2548 27.7208 12.167 27.4998 12.167Z" fill="#CCCCDD" />
      <defs>
        <linearGradient id="paint0_linear_1746_5670" x1="40.597" y1="37.5833" x2="-13.3772" y2="-10.4191" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C01B4C" />
          <stop offset="1" stopColor="#DC4B2B" />
        </linearGradient>
      </defs>
    </svg>
  )
}