import ReplyCommentCard from 'components/ReplyCommentCard';
import { SpacePost, SpacePostComment, SpaceUser } from "core/models/Space"
import { timeElapsed } from 'core/utils/Date';
import { getShortName } from 'core/utils/StringHelper';
import { useEffect, useState } from 'react';
import { Avatar } from "rsuite";

interface CommentProps {
  post: SpacePost;
  comment: SpacePostComment;
  onDeleteComment(data: any): void;
  onMention(commentUser: SpaceUser, comment: SpacePostComment): void
}

export default function CommentCard({ post, comment, onDeleteComment, onMention }: CommentProps) {
  const [replyLength, setReplyLength] = useState(1)
  const [viewReply, setViewReply] = useState(true)

  useEffect(() => {
    if (!viewReply) {
      setReplyLength(Infinity)
    } else {
      setReplyLength(1)
    }
  }, [viewReply])


  function getUserIcon() {
    if (comment.type === 'space' && post?.space?.media && post?.space?.media.icon) {
      return post?.space?.media?.icon || post?.space?.media?.banner
    } else if (comment?.user?.media?.profileImg) {
      return comment?.user?.media?.profileImg
    }
  }

  function handleDeleteComment() {
    onDeleteComment(comment.id)
  }

  function handleViewComment() {
    setViewReply(!viewReply)
  }

  function handleChangeMention() {
    onMention(comment.user, comment)
  }

  return (
    <>
      <div className="comment-card-container">
        <Avatar circle src={getUserIcon()} className='comment-icon' >
          {getShortName(comment.user.name ?? comment.user.userName ?? '')}
        </Avatar>
        <div className='commenter-name'>
          <p>
            <span className='comment-post-by'>
              {comment.type === 'space' ? post.space.name : comment.user.name || comment.user.userName}
            </span>
            <span className='post-comment' >
              &nbsp;{comment.comment}
            </span>
          </p>
          <div className='comment-options-container'>
            <div className='comment-options'>{timeElapsed(comment.createdAt)}</div>
            <div className='dot' />
            <button id='comment-reply' className='comment-options' onClick={handleChangeMention}>Reply</button>
            {
              comment.replies.length > 1 &&
              <>
                <div className='dot' />
                <button id='show-reply' className="show-reply" onClick={handleViewComment}>{viewReply ? 'View Replies' : 'Hide Replies'}</button>
              </>
            }
            <div className='dot' />
            <button id='comment-delete' className='comment-options' onClick={handleDeleteComment}>Delete</button>
          </div>
        </div>
      </div>
      <div className="reply-container">
        {
          comment.replies.slice(0, replyLength).map(reply => {
            return <ReplyCommentCard
              key={reply.id}
              reply={reply}
              spacePost={post}
              onDeleteComment={onDeleteComment}
            />
          })
        }
      </div>
    </>
  )
}
