import clsx from 'clsx';
import { Group } from 'core/models/Group';
import { getDate, getTime } from 'core/utils/Date';
import { Tag } from 'rsuite';
import SuperModerator from 'shared/icons/SuperModerator';

interface PannelHeaderProps {
  teamName: string;
  round: number | string;
  gameName: string;
  matchSequence: number | string;
  slot?: number | string;
  group: Group;
}

export default function PlayOffDetailCard({ group }: PannelHeaderProps) {
  return (
    <div className={clsx('play-off-detail-card-container', group.isSuperModerator && 'super-mod')}>
      {group.isSuperModerator &&
        <SuperModerator
          className='super-mod-icon'
          fontSize={40} />}
      <div className='play-off-detail-card-wrapper'>
        <div className='game-tournamentImage-container'>
          <img src={group.tournamentImage} alt="Tournament" className='game-tournament-image' />
        </div>
        <div className='play-off-detail-container'>
          <div className='play-off-tournament-name'>{group.tournamentName}</div>
          <div className='play-off-tournament-detail' >
            <div className='tournament-detail-info' >
              <div className='info-text'>Time:</div>
              <div className='info-value-text'> {getTime(group.tournamentDate, "hh:mm:IST")}</div>
            </div>
            <div className='tournament-detail-info' >
              <div className='info-text'>Moderator:</div>
              <div className='info-value-text' >{group.moderatorEmail}</div>
            </div>
            <div className='tournament-detail-info' >
              <div className='info-text'>Round:</div>
              <div className='info-value-text'>{group.roundSequence}</div>
            </div>
            {!group.gameName && <div className='tournament-detail-info' >
              <div className='info-text'>Group:</div>
              <div className='info-value-text'>{group.groupName}</div>
            </div>}
            <div className='tournament-detail-info' >
              <div className='info-text'>Type:</div>
              <div className='info-value-text'>{group.roundType}</div>
            </div>
            <div className='tournament-detail-info' >
              <div className='info-text'>Teams</div>
              <div className='info-value-text'>{group.teams}</div>
            </div>
            <div className='tournament-detail-info' >
              <div className='info-text'>Date:</div>
              <div className='info-value-text'>{getDate(group.tournamentDate, 'MMM DD, YYYY')}</div>
            </div>
            <div className='tournament-detail-info' >
              <div className='info-text'>Status:</div>
              <div className={clsx('info-value-text')} >
                {
                  group.isCompleted ?
                    <Tag color="red">COMPLETED</Tag>
                    :
                    <Tag color="green" >LIVE</Tag>
                }
              </div>
            </div>

          </div>
        </div>
      </div>
    </div >
  )
}
