import { ReactNode } from "react";
import { Button, Modal } from "rsuite";

interface PromptModalProps {
  onAccept(): void;
  onReject(): void;
  message: ReactNode;
  acceptText?: string;
  rejectText?: string;
  title?: ReactNode;
  open: boolean;
}

export default function PromptModal({
  message,
  onAccept,
  onReject,
  open,
  acceptText,
  rejectText,
  title
}: PromptModalProps) {
  return (
    <Modal show={open} onHide={onReject}>
      <Modal.Header>
        <Modal.Title>{title || 'Are You Sure?'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          typeof message === 'string' ?
            <p>{message}</p>
            :
            message
        }
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={onAccept}>{acceptText || 'Yes'}</Button>
        <Button appearance="ghost" onClick={onReject}>{rejectText || 'No'}</Button>
      </Modal.Footer>
    </Modal>
  )
}
