import { Route, Switch } from "react-router-dom"
import Layout from "../Layout"
import LoginPage from "../pages/Login"
import PlayoffTournament from "../pages/PlayoffTournament"
import PlayoffDetailsPage from "../pages/PlayoffDetails"
import PrivateRoutes from "./PrivateRoute"
import PlayoffPage from "pages/Playoff"
import PlayoffRounds from "pages/PlayoffRounds"
import SpaceList from "pages/Space"
import SpaceDetail from "pages/SpaceDetails"
import SpacePostDetail from "pages/SpacePostDetail"
import RivalryZoneDetail from 'pages/RivalryZoneDetail'
import RivalryZone from 'pages/RivalryZone'
import Callback from "pages/Callback"
import MatchSchedule from 'pages/MatchSchedule'
import Shorts from 'pages/Shorts'
import ShortsDetail from 'pages/ShortsDetail'
import ExternalLoginCallback from "pages/ExternalLoginCallback"
import Challenge from 'pages/Challenges'
import AddChallengeResult from 'pages/ChallengeResult'
import ChallengeDetailPage from 'pages/ChallengeDetail'



const PlayoffPages = () => (
  <Switch>
    <PrivateRoutes path="/playoff/tournaments/details/:playoffId" component={PlayoffDetailsPage} />
    <PrivateRoutes path="/playoff/tournaments/rounds/:tournamentId" component={PlayoffRounds} />
    <PrivateRoutes path="/playoff/tournaments/schedule/:tournamentId/:round" component={MatchSchedule} />
    <PrivateRoutes path="/playoff/tournaments/:tournamentId/:round" component={PlayoffPage} />
    <PrivateRoutes path="/playoff/tournaments" component={PlayoffTournament} />
  </Switch>
)

const SpacePages = () => (
  <Switch>
    <PrivateRoutes path="/space/:spaceId/short-detail/:shortsId" component={ShortsDetail} />
    <PrivateRoutes path="/space/shorts/:spaceId" component={Shorts} />
    <PrivateRoutes path="/space/:spaceId/:postId" component={SpacePostDetail} />
    <PrivateRoutes path="/space/:spaceId" component={SpaceDetail} />
    <PrivateRoutes path="/space" component={SpaceList} />
  </Switch>
)

const RivalryZonePage = () => (
  <Switch>
    <PrivateRoutes path="/rivalryZone/:groupId" component={RivalryZoneDetail} />
    <PrivateRoutes path="/rivalryZone" component={RivalryZone} />
  </Switch>
)

const ChallengePage = () => (
  <Switch>
    <PrivateRoutes path="/challenges/:challengeId/result" component={AddChallengeResult} />
    <PrivateRoutes path="/challenges/:challengeId" component={ChallengeDetailPage} />
    <PrivateRoutes path="/challenges" component={Challenge} />
  </Switch>
)

const WrappedRoutes = () => (
  <Layout>
    <PrivateRoutes path="/playoff" component={PlayoffPages} />
    <PrivateRoutes path="/space" component={SpacePages} />
    <PrivateRoutes path="/rivalryZone" component={RivalryZonePage} />
    <PrivateRoutes path="/challenges" component={ChallengePage} />
  </Layout>
)

const Router = () => (
  <Switch>
    <Route exact path="/" component={LoginPage} />
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/user/login/callback" component={Callback} />
    <Route exact path="/user/login/external/callback" component={ExternalLoginCallback} />
    <PrivateRoutes path="/" component={WrappedRoutes} />
  </Switch>
)

export default Router