import { useEffect, useState } from 'react'
import { Table, Loader } from 'rsuite'
import { PlayerStat } from 'core/models/GropuResult'
import { camelCaseToTitle } from 'core/utils/StringHelper'
import { getTeamScores } from 'core/services/Groups'
import { DEFAULT_API_ERROR } from 'core/constants/Defaults'
import { useNotification } from 'shared/hooks/useNotification'

interface PlayerStatsProps {
  onClose(): void;
  teamData: any;
  show: boolean;
}

interface TableConfig {
  headerName: string;
  dataKey: string;
}

export default function PlayerStats({ onClose, teamData, show }: PlayerStatsProps) {

  const [teamResults, setTeamResults] = useState<any[]>([])
  const [playerResults, setPlayerResults] = useState<PlayerStat[]>([])
  const [dataLoaded, setDataLoaded] = useState(false)
  const [tableConfigs, setTableConfigs] = useState<TableConfig[]>([])
  const [teamConfigs, setTeamConfigs] = useState<TableConfig[]>([])
  const showNotification = useNotification()

  useEffect(() => {
    if (show) {
      getTeamScores(teamData)
        .then(res => {
          if (res.status && res.data) {
            if (res.data.playerStats?.length || Object.keys(res.data.teamStats).length) {
              if (res.data.playerStats?.length) {
                const stats = res.data.playerStats[0]
                const { inGameName, userId, ...rest } = stats
                const tableData: TableConfig[] = []
                tableData.push({
                  dataKey: 'inGameName',
                  headerName: 'Player Name'
                })
                Object.keys(rest).forEach(data => {
                  tableData.push({
                    dataKey: data,
                    headerName: camelCaseToTitle(data)
                  })
                })
                setTableConfigs(tableData)
                setPlayerResults(res.data.playerStats)
              }
              if (Object.keys(res.data.teamStats).length) {
                const teamStats = res.data.teamStats
                const { teamName, ...restTeamData } = teamStats
                const teamLevelData: TableConfig[] = []
                teamLevelData.push({
                  dataKey: 'teamName',
                  headerName: 'Team Name'
                })
                Object.keys(restTeamData).forEach(teamMeta => {
                  teamLevelData.push({
                    dataKey: teamMeta,
                    headerName: camelCaseToTitle(teamMeta)
                  })
                })
                setTeamConfigs(teamLevelData)
                setTeamResults([teamStats])
              }
              setDataLoaded(true)
            } else {
              showNotification({
                message: 'No Data Found',
                title: 'Error',
                type: 'error'
              })
              onClose()
            }
          } else {
            showNotification({
              message: res.message || DEFAULT_API_ERROR,
              title: 'Error',
              type: 'error'
            })
            onClose()
          }
        })
    }
  }, [show, teamData, onClose, showNotification])

  return (
    <div className="team-result-details-wrapper">
      {
        dataLoaded ?
          <>
            <div>
              {
                Boolean(teamResults.length) &&
                <div>
                  <h6 className='sub-heading'>Team</h6>
                  <div className='hr-line'></div>
                  <Table height={150} data={teamResults} className="team-score-table" >
                    {
                      teamConfigs.map(
                        teamMeta =>
                          <Table.Column flexGrow={1} key={teamMeta.dataKey} minWidth={100} >
                            <Table.HeaderCell style={{ fontWeight: 600 }}>{teamMeta.headerName}</Table.HeaderCell>
                            <Table.Cell dataKey={teamMeta.dataKey} className="team-score-value" />
                          </Table.Column>
                      )
                    }
                  </Table>
                  <div className='hr-line'></div>
                </div>
              }
              {
                Boolean(playerResults.length) &&
                <div>
                  <h6 className='sub-heading'>Players</h6>
                  <div className='hr-line'></div>
                  <Table height={150} data={playerResults} className="team-score-table" >
                    {
                      tableConfigs.map(
                        config =>
                          <Table.Column key={config.dataKey} flexGrow={1}>
                            <Table.HeaderCell style={{ fontWeight: 600 }} >{config.headerName}</Table.HeaderCell>
                            <Table.Cell dataKey={config.dataKey} className="team-score-value" />
                          </Table.Column>
                      )
                    }
                  </Table>
                  <div className='hr-line'></div>
                </div>
              }
              <div className='total-score-container'>
                <div className='total-score-heading'>Total Score</div>
                <div className='total-score-value'>
                  {teamData.teamScore}
                </div>
              </div>
            </div>
          </>
          :
          <div style={{ textAlign: 'center' }}>
            <Loader size="md" vertical content="Loading Team Score" />
          </div>
      }
    </div >
  )
}
