import { IconTypes } from './Icons'

export default function WowEmoji({ fontSize = '1.6rem', className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize }}
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M20 10C20 15.5228 15.5228 20 10 20C4.47722 20 0 15.5228 0 10C0 4.47722 4.47722 0 10 0C15.5228 0 20 4.47722 20 10Z" fill="#FFA24D" />
      <path d="M9.99993 16.6669C11.2272 16.6669 12.2222 15.4232 12.2222 13.8891C12.2222 12.355 11.2272 11.1113 9.99993 11.1113C8.77263 11.1113 7.77771 12.355 7.77771 13.8891C7.77771 15.4232 8.77263 16.6669 9.99993 16.6669Z" fill="#292F33" />
      <path d="M6.6666 9.44455C7.43366 9.44455 8.05549 8.574 8.05549 7.50011C8.05549 6.42622 7.43366 5.55566 6.6666 5.55566C5.89954 5.55566 5.27771 6.42622 5.27771 7.50011C5.27771 8.574 5.89954 9.44455 6.6666 9.44455Z" fill="#292F33" />
      <path d="M13.3335 9.44455C14.1005 9.44455 14.7224 8.574 14.7224 7.50011C14.7224 6.42622 14.1005 5.55566 13.3335 5.55566C12.5664 5.55566 11.9446 6.42622 11.9446 7.50011C11.9446 8.574 12.5664 9.44455 13.3335 9.44455Z" fill="#292F33" />
    </svg>
  )
}
