import history from 'App/History';
import clsx from 'clsx';
import { SpacePost } from 'core/models/Space'
import { timeElapsed } from 'core/utils/Date';
import { MouseEvent, useState } from 'react'
import { Dropdown, Tag } from 'rsuite'
import EmptyPostIcon from 'shared/icons/EmptyPost';
import MenuThreeDot from 'shared/icons/MenuThreeDot';

interface PostCardProps {
  post: SpacePost;
  onUpdateStatus(postData: any): void;
}

export default function PostCard({ post, onUpdateStatus }: PostCardProps) {

  const [menuOpen, setMenuOpen] = useState(false)

  function handleSpacePost() {
    history.push(`/space/${post.space.id}/${post.id}`)
  }

  function handleOptionClick(eve: any) {
    eve.stopPropagation()
    setMenuOpen(prevState => !prevState)
  }

  function onSelectOption(option: any, eve: MouseEvent<HTMLElement>) {
    eve.stopPropagation()
    handleStatusUpdate(option)
  }

  function handleStatusUpdate(status: string) {
    onUpdateStatus({ status, postId: post.id })
  }

  function getPostImage() {
    if (post.media?.postImage?.length) {
      return <div className='post-card-image-container'>
        <img src={post.media?.postImage[0]} alt='Post Snap' className='space-post-card-image' />
      </div>
    }
    return (
      <div className="empty-space-post-container">
        <EmptyPostIcon fontSize='6.0rem' className='empty-space-post' color='#ffffff' />
      </div>
    );
  }

  return (
    <div className='space-post-card-container' onClick={handleSpacePost}>
      {getPostImage()}
      <div className='post-status'>
        <Tag className='post-status-tag' color={post.status === 'live' ? 'green' : 'red'}>{post.status}</Tag>
        <Tag className='post-status-tag' color={post.visibility === 'All' ? 'green' : 'red'}>{post.visibility}</Tag>
      </div>
      <div className='post-caption-time-container'>
        <div className='space-post-caption'>{post.post}</div>
        <div className='space-post-created-time'>{timeElapsed(post.createdAt)}</div>
        <div className='space-post-by-status-btn'>
          <div className='post-by-name' style={{ fontWeight: 'lighter' }}>
            By
            <span className='post-by-name' style={{ marginLeft: '4px' }}>
              {post.user.userName || post.space.name}
            </span>
          </div>
          <Dropdown
            renderTitle={() => {
              return <div onClick={handleOptionClick}>
                <MenuThreeDot fontSize={16} />
              </div>;
            }}
            placement='leftEnd'
            className={clsx(post.status === 'live' ? 'menu-inactive' : 'menu-live')}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            onSelect={onSelectOption}
          >
            {
              post.status === 'live'
                ?
                <Dropdown.Item eventKey="inactive" >Inactive</Dropdown.Item>
                :
                <Dropdown.Item eventKey="live">Live</Dropdown.Item>
            }
          </Dropdown>
        </div>
      </div >
    </div >
  )
}
