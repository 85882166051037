
import { NavLink } from 'react-router-dom'
import { Nav, Drawer } from 'rsuite'
import PlayOff from 'shared/icons/PlayOff';
import RivalryZone from 'shared/icons/RivalryZone';
import Spaces from 'shared/icons/Spaces';
import VersusMode from 'shared/icons/VersusMode';

interface SideDrawerProps {
  open: boolean;
  onClose(): void;
}

export default function SideDrawer({ open, onClose }: SideDrawerProps) {
  return (
    <Drawer show={open} onHide={onClose} placement='left' className='side-drawer'>
      <Drawer.Header>
        <Drawer.Title>Menu</Drawer.Title>
      </Drawer.Header >
      <Drawer.Body >
        <div className='drawer-nav-item-wrapper'>
          <Nav.Item
            onSelect={onClose}
            componentClass={NavLink}
            activeClassName="active"
            to="/playoff/tournaments"
            icon={<PlayOff fontSize={32} className='drawer-nav-icon' />}
          >
            <p className="drawer-nav-content">
              Playoffs
            </p>
          </Nav.Item>
          <Nav.Item
            onSelect={onClose}
            componentClass={NavLink}
            activeClassName="active"
            to="/challenges"
            icon={<VersusMode fontSize={32} className='drawer-nav-icon' />}
          >
            <p className="drawer-nav-content">
              Challenge
            </p>
          </Nav.Item>
          <Nav.Item
            onSelect={onClose}
            componentClass={NavLink}
            activeClassName="active"
            to="/space"
            icon={<Spaces fontSize={32} className='drawer-nav-icon' />}
          >
            <p className="drawer-nav-content">
              Spaces
            </p>
          </Nav.Item>
          <Nav.Item
            componentClass={NavLink}
            onSelect={onClose}
            activeClassName="active"
            to="/rivalryZone"
            icon={<RivalryZone fontSize={32} className='drawer-nav-icon' />}
          >
            <p className="drawer-nav-content">
              Rivalry Zone
            </p>
          </Nav.Item>
        </div>
      </Drawer.Body>
    </Drawer >

  )
}