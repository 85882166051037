import { IconTypes } from "./Icons";

export default function SendButton({ color = '#6E6AF0', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path d="M13.9573 8.71516C14.2283 8.57965 14.3995 8.30264 14.3995 7.99962C14.3995 7.6966 14.2283 7.41959 13.9573 7.28408L2.75728 1.68408C2.47352 1.5422 2.13273 1.5814 1.8886 1.78401C1.64447 1.98662 1.54314 2.31435 1.63029 2.61939L2.77315 6.61939C2.87127 6.96283 3.18518 7.19961 3.54237 7.19961H7.19951C7.64134 7.19961 7.99951 7.55778 7.99951 7.99961C7.99951 8.44144 7.64134 8.79961 7.19951 8.79961H3.54237C3.18519 8.79961 2.87128 9.03639 2.77315 9.37983L1.63029 13.3798C1.54314 13.6849 1.64447 14.0126 1.8886 14.2152C2.13273 14.4178 2.47352 14.457 2.75728 14.3152L13.9573 8.71516Z" fill={color} />
    </svg>
  )
}