import { PrizeConfig, Result } from 'core/models/Tournament';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { Button, Form } from 'rsuite';
import Select from 'shared/components/redux-form/Select';
import { validate } from './validate'
import { uniqueId } from 'core/utils/StringHelper';

interface PrizeDistributionProps {
  onClose(): void;
  teams: Result[];
  prizeConfig: PrizeConfig[];
  rewards: any;
  onAddRewards(data: any): void;
}

type FinalProps = InjectedFormProps<{}, PrizeDistributionProps> & PrizeDistributionProps

function PrizeDistribution({
  onClose,
  teams,
  prizeConfig,
  handleSubmit,
  rewards,
  onAddRewards
}: FinalProps) {

  const [disabledTeams, setDisabledTeams] = useState<string[]>([])

  function handleForm(formData: any) {
    const assignedRewards: { [key: string]: string; } = formData.rewards
    const prizeDistribution: any[] = []
    Object.entries(assignedRewards).forEach(([k, v]) => {
      const prizConfig = prizeConfig.find(conf => conf.title === k)
      if (prizConfig) {
        prizeDistribution.push({ teamId: v, prize: prizConfig.prize })
      }
    })
    onAddRewards({ prizeConfig: prizeDistribution })
  }

  useEffect(() => {
    if (rewards) {
      setDisabledTeams(Object.values(rewards))
    }
  }, [rewards])

  return (
    <div>
      <Form onSubmit={handleSubmit(handleForm)}>
        {
          prizeConfig.map(config => (
            <Field
              name={`rewards.${config.title}`}
              component={Select}
              placeholder="Select Team"
              options={teams.map(team => ({ value: team.teamId, label: `${team.teamName} - Score: ${team.score}` }))}
              label={config.title}
              key={uniqueId()}
              disabledItemValues={disabledTeams}
              cleanable
            />
          ))
        }
        <div className='prize-distribution-button-container'>
          <Button id='close-btn' appearance="primary" onClick={onClose} style={{ marginRight: "1.6rem" }}>Close</Button>
          <Button id='prize-submit' appearance="primary" type="submit">Submit</Button>
        </div>
      </Form>
    </div>
  );
}

const PrizeDistributionForm = reduxForm<{}, PrizeDistributionProps>({
  form: 'prize-distribution-form',
  validate
})(PrizeDistribution)

const selector = formValueSelector('prize-distribution-form')

export default connect(state => {
  const rewards = selector(state, 'rewards')
  return {
    rewards
  }
})(PrizeDistributionForm)
