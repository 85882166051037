import { CSSProperties, FC } from 'react'
import { FormGroup, InputGroup, SelectPicker, FormControl, ControlLabel } from 'rsuite'
import { SelectOptions } from 'core/models/Settings'

interface Meta {
  touched: boolean;
  error: string;
}

interface ComponentProps {
  input: any;
  placeholder: string;
  meta?: Meta;
  options: SelectOptions[];
  label?: string;
  searchable: boolean;
  disabled?: boolean;
  cleanable?: boolean;
  groupBy?: string;
  className?: string;
  disabledItemValues?: string[];
  style: CSSProperties;
}

const CustomSelect: FC<ComponentProps> = ({ placeholder, input, options, searchable, disabled, cleanable, groupBy, style, disabledItemValues }) => (
  <SelectPicker
    searchable={searchable}
    block
    placeholder={placeholder}
    data={options}
    maxHeight={140}
    disabled={disabled}
    cleanable={cleanable}
    groupBy={groupBy}
    disabledItemValues={disabledItemValues}
    {...input}
    style={style}
  />
)

const Select: FC<ComponentProps> = (props: ComponentProps) => {
  const {
    placeholder = "Select",
    meta,
    input,
    label,
    options,
    searchable = false,
    disabled,
    cleanable = false,
    groupBy = '',
    disabledItemValues = []
    , style,
    className
  } = props
  return (
    <FormGroup className={className}>
      <ControlLabel>{label}</ControlLabel>
      <InputGroup inside style={{ width: '100%' }}>
        <FormControl
          disabledItemValues={disabledItemValues}
          accepter={CustomSelect}
          placeholder={placeholder}
          input={input}
          options={options}
          disabled={disabled}
          searchable={searchable}
          cleanable={cleanable}
          errorMessage={meta?.touched && meta.error}
          groupBy={groupBy}
          style={style}
        />
      </InputGroup>
    </FormGroup>
  )
}

export default Select