import { ChangeEvent, createRef } from 'react'
import FileUpload from 'shared/icons/FileUpload';

interface UploadWithPreviewProps {
  placeholder?: string;
  label?: string;
  onFileChange(files: File[]): void;
  accept?: string;
  multiple?: boolean;
  value?: string;
  onRemove?(): void;
  error?: string;
}

export default function UploadWithPreview({
  placeholder = "Upload",
  label = "Image",
  onFileChange,
  accept = '*/*',
  multiple,
  value,
  onRemove,
  error = ''
}: UploadWithPreviewProps) {

  const uploadRef = createRef<HTMLInputElement>()

  function onFileSelection(event: ChangeEvent<HTMLInputElement>) {
    const files = Array.from(event.target.files || []);
    onFileChange(files)
  }

  const uploadClass = error ? 'uploader error' : 'uploader'

  return (
    <div className="uploader-conatiner">
      <input
        onChange={onFileSelection}
        onClick={event => (event.target as HTMLInputElement).value = ''}
        type="file"
        style={{ display: 'none' }}
        ref={uploadRef}
        accept={accept}
        multiple={multiple}
      />
      {
        value ?
          <div className="uploader-preview">
            <div className="label-overlay">
              {label}
            </div>
            <img src={value} alt="Uploader Preview" />
            <div className="close-icon" title="Remove" onClick={onRemove}>
              <h6>X</h6>
            </div>
          </div>
          :
          <div className={uploadClass} onClick={() => uploadRef.current?.click()}>
            <FileUpload fontSize={48} />
            <p style={{ color: '#343C68' }}>{placeholder}</p>
            <p style={{ color: 'red' }}>{error}</p>
          </div>
      }
    </div>
  )
}