import { Button, Drawer, Modal } from 'rsuite'
interface DeleteCommentProps {
  deleteComment(): void;
  onCancel(): void;
  onOpen: boolean;
  onClose(): void;
  isMobile?: boolean;
}

export default function DeleteComment({ isMobile, onCancel, deleteComment, onOpen, onClose }: DeleteCommentProps) {

  const deleteForm = <>
    <p className='delete-text'>Are you sure you want to delete this Comment ?</p>
    <div className='delete-btn-container'>
      <Button appearance='primary' id='cancel-comment' onClick={onCancel} className='delete-btn'>
        Cancel
      </Button>
      <Button appearance='primary' id='delete-comment' onClick={deleteComment}>
        Delete
      </Button>
    </div>
  </>

  return (
    <>
      {
        isMobile ? <Drawer show={onOpen} onHide={onClose} placement='bottom' style={{ height: '35rem' }}>
          <Drawer.Header>
            <Drawer.Title>
              Confirm Deletion
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            {deleteForm}
          </Drawer.Body>
        </Drawer >
          :
          <Modal show={onOpen} onHide={onClose} >
            <Modal.Header>
              <Modal.Title>
                Confirm Deletion
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {deleteForm}
            </Modal.Body>
          </Modal>
      }
    </>
  )
}
