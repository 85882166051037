import clsx from 'clsx';
import ReactionPrompt from 'components/ReactionPrompt';
import { SpacePost } from "core/models/Space";
import { timeElapsed } from 'core/utils/Date';
import { numberFormatter } from "core/utils/NumberHelper";
import { MouseEvent, useState } from 'react';
import { Dropdown, Icon, Tag } from "rsuite";
import Comment from 'shared/icons/Comment';
import HappyPlusEmoji from 'shared/icons/HappyPlusEmoji';
import MenuThreeDot from 'shared/icons/MenuThreeDot';

interface PostMediaProps {
  post: SpacePost;
  onUpdateStatus(postData: any): void;
  onChangeStatus(status: any): void;
}

export default function PostMedia({ post, onUpdateStatus, onChangeStatus }: PostMediaProps) {
  const [menuOpen, setMenuOpen] = useState(false)


  function handleLikeTag() {
    return (
      <div className="like-comment" >
        <HappyPlusEmoji fontSize={20} />
        <span className='like-comment-text' >{numberFormatter(post?.reactionCounts?.total, 1)}</span>
      </div>
    )
  }

  function handleCommentTag() {
    return (
      <div className="like-comment" >
        <Comment fontSize={20} />
        <span className='like-comment-text'>{numberFormatter(post?.commentCount, 1)}</span>
      </div>
    )
  }

  function getPostIcon() {
    let icon = <Icon icon="user" className='icon' />
    if (post?.type === 'space' && post?.space.media && post?.space?.media.icon) {
      icon = <img src={post?.space?.media?.icon} alt="Space Icon" className='space-post-detail-icon' />
    } else if (post?.user?.media?.profileImg) {
      icon = <img src={post?.user?.media?.profileImg} alt="User Icon" className='space-post-detail-icon' />
    }
    return icon
  }
  function handleOptionClick(eve: any) {
    eve.stopPropagation()
    setMenuOpen(prevState => !prevState)
  }

  function onSelectOption(option: any, eve: MouseEvent<HTMLElement>) {
    eve.stopPropagation()
    handleStatusUpdate(option)
  }

  function handleStatusUpdate(status: string) {
    onUpdateStatus({ status, postId: post?.id })
    onChangeStatus(status)
  }

  return (
    <div className="post-detail-wrapper">
      <div className="space-post-detail-header">
        <div className='post-detail-header-left'>
          {getPostIcon()}
          <div className='post-name-time'>
            <div className="post-name">{post?.type === 'member' ? post?.user?.userName : post?.space?.name}</div>
            <div className="time-elapsed">{timeElapsed(post?.createdAt)}</div>
          </div>
        </div>
        <div className="post-detail-header-right">
          <Tag className={clsx(post?.visibility === 'All' ? 'tag-active' : 'tag-inactive')}>{post?.visibility}</Tag>
          <Tag className={clsx(post?.status === 'live' ? 'tag-active' : 'tag-inactive')}>{post?.status}</Tag>
          <Dropdown
            renderTitle={() => {
              return <div onClick={handleOptionClick}>
                <MenuThreeDot rotate={90} fontSize="2rem" />
              </div>
            }}
            placement='bottomEnd'
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            onSelect={onSelectOption}
            className="drop-down-menu"
          >
            {
              post?.status === 'live'
                ?
                <Dropdown.Item eventKey="inactive">Inactive</Dropdown.Item>
                :
                <Dropdown.Item eventKey="live">Live</Dropdown.Item>
            }
          </Dropdown>
        </div>
      </div>
      <div className="post-detail-image-container">
        {
          post?.media?.postImage ?
            <img src={post?.media?.postImage[0]} alt="Post" width="100%" className='image-container' />
            :
            <div className="thumbnail">
              <div className="no-image-comtainer">
                <Icon icon="image" size="4x" style={{ color: '#c4c4c4' }} />
                <span>No Image to Preview</span>
              </div>
            </div>
        }
      </div>
      <div className='like-comment-caption-wrapper'>
        <div className="like-comment-container">
          <div className="tag" >
            {handleLikeTag()}
          </div>
          <div className="tag" style={{ marginLeft: 16 }}>{handleCommentTag()}</div>
        </div>
        <ReactionPrompt reactionCount={post?.reactionCounts} />
        <div className="post-detail-caption">
          {post?.post}
        </div>
      </div>
    </div>
  )
}
