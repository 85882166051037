import { useTypedDispatch, useTypedSelector } from 'App/Store';
import Pagination from 'components/Pagination';
import PostShorts from 'components/PostShort';
import ShortsCard from 'components/ShortsCard';
import { deleteShorts, processShorts, requestShorts, uploadShorts } from 'core/services/Short';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { hideLoader, showLoader } from 'redux/action/loaderAction';
import { shortsList } from 'redux/action/shortsListAction';
import { Button, Col, Drawer, Modal, Row } from 'rsuite';
import { useNotification } from 'shared/hooks/useNotification';

const PAGE_SIZE = 8

interface UrlParams {
  spaceId: string;
}

export default function Shorts() {
  const [shortsOpen, setShortsOpen] = useState(false)
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)
  const { count, currentPage, shorts } = useTypedSelector(state => state.shorts)
  const dispatch = useTypedDispatch();
  const showNotification = useNotification()
  const urlParams = useParams<UrlParams>()
  const [intermediateLabel, setIntermediateLabel] = useState('')
  const [isIntermediate, setIsIntermediate] = useState(false)

  const [shortsFile, setShortsFile] = useState<File | null>(null)


  useEffect(() =>
    dispatch(shortsList(urlParams.spaceId))
    , [dispatch, urlParams.spaceId])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])


  function handlePostShortOpen() {
    setShortsOpen(true)
  }


  function handleCloseCreateShortsModal() {
    setShortsOpen(false)
    setShortsFile(null)
    setIsIntermediate(false)
    setIntermediateLabel('')
  }


  async function handleCreateShort(shortsData: any) {
    const finalData: any = {}
    const { shortsFile, caption } = shortsData
    finalData.fileName = shortsFile.name
    finalData.mimeType = shortsFile.type || 'application/octet-stream'
    if (caption) {
      finalData.caption = caption
    }
    finalData.spaceId = Number(urlParams.spaceId)
    setIntermediateLabel('intermediate')
    setIntermediateLabel('Uploading...')
    const requestRes = await requestShorts(finalData)
    setIsIntermediate(true)
    if (requestRes.status && requestRes.data) {
      const { shortsId, signedUrl } = requestRes.data.shortsRequest
      const uploadRes = await uploadShorts(signedUrl, shortsFile, (progress) => { setIntermediateLabel(`Uploading ${progress}%`) })
      if (uploadRes.code >= 200 && uploadRes.code <= 299) {
        setIntermediateLabel('Processing...')
        const processRes = await processShorts({ shortsId, spaceId: urlParams.spaceId })
        if (processRes.status) {
          showNotification({
            title: 'success',
            message: 'Bytes Uploaded Successfully',
            type: 'success'
          })
          setIntermediateLabel('completed')

        } else {
          showNotification({
            title: 'error',
            message: processRes?.message || 'Failed to Process',
            type: 'error'
          })
        }
        handleCloseCreateShortsModal()
      }
      else {
        showNotification({
          message: uploadRes?.message || 'Failed to Upload',
          title: 'error',
          type: 'success'
        })
      }
    }
    else {
      showNotification({
        message: requestRes?.message || 'Failed to Upload',
        title: 'error',
        type: 'success'
      })
    }
    setShortsOpen(false)
  }


  function handlePagination(pageNumber: number) {
    const page = pageNumber - 1;
    dispatch(shortsList(urlParams.spaceId, { size: PAGE_SIZE, page }))
  }

  async function handleDeleteShort(shortId: any) {
    dispatch(showLoader())
    const res = await deleteShorts(shortId)
    if (res.status) {
      showNotification(
        {
          message: 'Bytes Deleted Successfully',
          type: 'success',
          title: 'success'
        }
      )
    }
    else {
      showNotification({
        title: 'Error',
        type: 'error',
        message: res?.message || 'Failed to Delete Bytes!!'
      })
    }
    dispatch(hideLoader())
    dispatch(shortsList(urlParams.spaceId, { size: PAGE_SIZE, page: 0 }))
  }

  function handleIntermediateState() {
    if (isIntermediate) {
      return (
        <div className='intermediate-state-wrapper'>
          <div className='loader-container'>
            <div className='state-buffer'></div>
            <div className='state-text'>{intermediateLabel}</div>
          </div>
          <span className='by-line'>Please Don&apos;t close this Window!!</span>
        </div>
      )
    } else {
      return <PostShorts
        shortsFile={shortsFile}
        onSelectShortsFile={setShortsFile}
        onCreatePost={handleCreateShort}
      />
    }
  }

  return (
    <>
      <div className='short-title-button'>
        <h2 className='shorts-title'>Bytes</h2>
        <Button className='shorts-btn' appearance='primary' onClick={handlePostShortOpen}>Upload Bytes</Button>
      </div>
      <Row gutter={10}>
        {
          shorts.map(short =>
            <Col key={short.id} sm={12} md={8} lg={6}>
              <ShortsCard
                spaceId={urlParams.spaceId} onDelete={handleDeleteShort} short={short} isMobile={isMobile} />
            </Col>
          )
        }
      </Row>
      {
        count > PAGE_SIZE &&
        <div className="pagination">
          <Pagination
            pages={Math.ceil(count / 8)}
            limit={10}
            activePage={currentPage + 1}
            maxButtons={6}
            onPageChange={handlePagination}
          />
        </div>
      }
      {
        isMobile
          ?
          <Drawer
            show={shortsOpen}
            onHide={handleCloseCreateShortsModal}
            placement="bottom"
            style={{ height: '50rem' }}
          >
            <Drawer.Header>
              <Drawer.Title>Upload Bytes</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              {handleIntermediateState()}
            </Drawer.Body>
          </Drawer>
          :
          <Modal show={shortsOpen} onHide={handleCloseCreateShortsModal}>
            <Modal.Header>
              <Modal.Title>Upload Bytes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {handleIntermediateState()}
            </Modal.Body>
          </Modal>
      }
    </>
  )
}
