import { PlayoffRound } from 'core/models/PlayoffRound';
import { PLAYOFF_ROUND_LIST_ACTION } from 'core/constants/Actions';
import { Action } from 'core/models/ActionTypes'
import { PrizeConfig, Result } from 'core/models/Tournament';

const initialState: {
  playoffRounds: PlayoffRound[],
  isLoading: boolean,
  rewarded: boolean,
  tournamentCompleted: boolean,
  winnerTeams: Result[],
  prizeConfig: PrizeConfig[]
} = {
  playoffRounds: [],
  rewarded: false,
  tournamentCompleted: false,
  prizeConfig: [],
  winnerTeams: [],
  isLoading: false
}

interface ActionType extends Action {
  payload: {
    playoffRounds: PlayoffRound[];
    rewarded: boolean;
    tournamentCompleted: boolean;
    winnerTeams: Result[];
    prizeConfig: PrizeConfig[];
  }
}

export default function playoffRoundsReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case PLAYOFF_ROUND_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case PLAYOFF_ROUND_LIST_ACTION.SUCCESS:
      const { playoffRounds, prizeConfig, rewarded, tournamentCompleted, winnerTeams } = action.payload;
      return { ...state, playoffRounds, prizeConfig, rewarded, tournamentCompleted, winnerTeams, isLoading: false }

    case PLAYOFF_ROUND_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}