
import { TypedDispatch, TypedThunk } from 'App/Store'
import { SHORTS_COMMENTS_ACTION } from 'core/constants/Actions'
import { getShortsComment } from 'core/services/Short';

interface Params {
  spaceId: string | number,
  shortsId: string | number,
  page: number;
  size: number;
}

export function getShortsCommentsList(
  params: Params,
  acc?: boolean
): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: SHORTS_COMMENTS_ACTION.REQUEST })
    const apiResponse = await getShortsComment(params.spaceId, params.shortsId, { page: params.page, size: params.size })
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: SHORTS_COMMENTS_ACTION.SUCCESS,
        payload: { shortsComments: apiResponse.data.shortsComments, count: apiResponse.data.count, currentPage: params.page, acc: acc }
      })
    } else {
      dispatch({
        type: SHORTS_COMMENTS_ACTION.FAILURE
      })
    }
  }
}
