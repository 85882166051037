import { IconTypes } from "./Icons";

export default function SupportEmoji({ fontSize = '20', className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize }}
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M20 10C20 15.5228 15.5228 20 10 20C4.47722 20 0 15.5228 0 10C0 4.47722 4.47722 0 10 0C15.5228 0 20 4.47722 20 10Z" fill="#95BD42" />
      <g clipPath="url(#clip0_13582_12592)">
        <path d="M6.40002 13.4004C6.40002 8.60039 6.40002 7.40039 6.40002 7.40039" stroke="#074E36" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M9.40002 10.4004C4.60002 10.4004 3.40002 10.4004 3.40002 10.4004" stroke="#074E36" strokeWidth="1.5" strokeLinecap="round" />
        <path d="M14.7662 4.91992V14.9004H12.9547V7.03223L10.5553 7.8252V6.3623L14.5612 4.91992H14.7662Z" fill="#074E36" />
      </g>
      <defs>
        <clipPath id="clip0_13582_12592">
          <rect width="16" height="16" fill="white" transform="translate(2.00012 2)" />
        </clipPath>
      </defs></svg>
  )
}
