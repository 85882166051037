const MODE_MAP: any = {
  Solo: 'Solo',
  '2v2': 'Duo',
  '3v3': 'Trio',
  '4v4': 'Squad'
}

export function getShortName(username: string) {
  if (!username) return username
  return username.split(' ').map(n => n[0]).join('').substr(0, 2).toUpperCase()
}

export function camelCaseToTitle(camelText: string) {
  if (!camelText) return camelText
  const result = camelText.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function convertToRank(rank: number) {
  const tens = rank % 10
  const hundred = rank % 100
  if (tens === 1 && hundred !== 11) {
    return rank + "st"
  }
  if (tens === 2 && hundred !== 12) {
    return rank + "nd"
  }
  if (tens === 3 && hundred !== 13) {
    return rank + "rd"
  }
  return rank + "th"
}

export function getTournamentFormat(format: string) {
  const formatData = { format: 'Battle Royale', mode: 'Squad' }
  if (format.trim()) {
    const tForm = format.split('-').map(chunk => chunk.trim())
    formatData.format = tForm[0]
    if (tForm[0] === 'Versus Mode') {
      formatData.mode = tForm[1] === 'Solo' ? '1v1' : tForm[1]
    } else {
      formatData.mode = MODE_MAP[tForm[1]] || tForm[1]
    }
  }
  return formatData
}

export const uniqueId = (function () {
  let num = 0;
  return function (prefix: string = 'bxo') {
    prefix = String(prefix) || '';
    num += 1;
    return prefix + '-' + num;
  }
}()); 
