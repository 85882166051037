import { Icon } from "rsuite";

interface CommentMentionProps {
  onClearMention(): void;
  onMentionTags(): void;
}

export default function CommentMention({ onClearMention, onMentionTags }: CommentMentionProps) {
  return (
    <div className='comment-mention-wrapper'>
      @{onMentionTags()}
      <div onClick={onClearMention} className="close-icon">
        <Icon icon='close' />
      </div>
    </div>
  )
}
