import { Tag } from 'rsuite'
import { Tournament } from 'core/models/Tournament'
import { getDate, getTime } from 'core/utils/Date'
import clsx from 'clsx';
import SuperModerator from 'shared/icons/SuperModerator';
import { getTournamentFormat } from 'core/utils/StringHelper';

interface TournamentCardProps {
  tournament: Tournament;
  onClick?(tournament: Tournament): void;
}

export default function TournamentCard({ tournament, onClick }: TournamentCardProps) {

  function handleGameClick() {
    if (onClick) {
      onClick(tournament)
    }
  }

  return (
    <div className={clsx("tournament-card-wrapper", tournament.isSuperModerator && "moderator")} onClick={handleGameClick}>
      <div className={clsx("tournament-img-wrapper")}>
        <img src={tournament?.content?.icon || tournament?.content?.banner} alt={tournament.gameData.name} className='tournament-card-img' />
        <Tag className='tournament-status' color={tournament.resultUpdateStatus === 'live' ? 'green' : "blue"}>{tournament.resultUpdateStatus.toUpperCase()}</Tag>
        {
          tournament.isSuperModerator &&
          <div className='super-moderator-symbol'>
            <SuperModerator fontSize='45' />
          </div>
        }
        <img src={tournament.gameData.media.icon} alt={tournament.gameData.name} className='tournament-icon' />
      </div>
      <div className='tournament-detail'>
        <div className='space-name'>{tournament.space.name}</div>
        <div className='tournament-title'>
          {tournament.title}
        </div>
        <div className='tournament-type-date'>
          <div className='type-date' >
            {getTournamentFormat(tournament.type).mode}
          </div>
          <div className='dot'></div>
          <div className='type-date'>
            {getDate(tournament.deadline, "DD MMM")}
          </div>
          <div className='dot'></div>
          <div className='type-date'>
            {getTime(tournament.startDate, "HH:mm")}
          </div>
        </div>
      </div>
    </div >
  )
}