import { useTypedDispatch, useTypedSelector } from 'App/Store';
import AutoSchedule from 'components/AutoSchedule';
import ManualSchedule from 'components/ManualSchedule';
import MatchScheduleCard from 'components/MatchScheduleCard';
import { ScheduledMatches } from 'core/models/Group';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { groupList } from 'redux/action/groupListActions';
import { matchScheduleList, resetBannerDetails } from 'redux/action/matchScheduleListActions';
import { Button, Col, Drawer, Modal, Row } from 'rsuite';

interface UrlParams {
  tournamentId: string;
  round: string;
}

export default function MatchSchedule() {
  const playoffsDetails = useTypedSelector(state => state.groups)
  const { groups } = playoffsDetails
  const scheduledMatchesList = useTypedSelector(state => state.scheduledMatches)
  const { scheduledMatches, roundName } = scheduledMatchesList
  const urlParams = useParams<UrlParams>()
  const dispatch = useTypedDispatch()
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)
  const [autoSchedulePopup, setAutoSchedulePopup] = useState(false)
  const [confirmPopOpen, setConfirmPopOpen] = useState(false)
  const [reload, setReload] = useState(false)
  const [sequence, setSequence] = useState<any>()
  const [groupId, setGroupId] = useState<any>()

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])

  useEffect(() => {
    dispatch(matchScheduleList({ tournamentId: urlParams.tournamentId, roundSequence: urlParams.round }))
    return () => { dispatch(resetBannerDetails()) }
  }, [dispatch, urlParams, reload, confirmPopOpen])

  useEffect(() => {
    dispatch(groupList({ id: urlParams.tournamentId, round: urlParams.round }))
  }, [dispatch, urlParams])

  function handleAutoScheduleOpen() {
    setAutoSchedulePopup(true)
    setReload(true)
  }

  function handleTime(data: ScheduledMatches) {
    if (!data.isCompleted) {
      setSequence(data.matchSequence)
      setGroupId(data.groupId)
      setConfirmPopOpen(true)
    }
  }

  function handleClose() {
    setConfirmPopOpen(false)
  }

  function handleSchedule() {
    setReload(false)
    setAutoSchedulePopup(false)
  }

  return (
    <>
      <div className='schedule-match-container'>
        <div className='title-button'>
          <div className='schedule-title'>Schedule Matches for {roundName}</div>
          {
            !!scheduledMatches.length
            &&
            <Button id='schedule-button' appearance="primary" className='schedule-btn' onClick={handleAutoScheduleOpen}>Schedule</Button>
          }
        </div>
        {
          scheduledMatches.length ?
            <Row gutter={12}>
              {
                scheduledMatches.map(matchDetail =>
                  <Col key={matchDetail.groupId} sm={12} md={8} lg={8}>
                    <MatchScheduleCard matchDetail={matchDetail} onHandleTime={handleTime} />
                  </Col>
                )
              }
            </Row>
            :
            <div className='no-matches'>
              <p> No match found for this round</p>
            </div>
        }
      </div>
      {
        isMobile ?
          <Drawer onHide={() => setConfirmPopOpen(false)} show={confirmPopOpen} placement="bottom" style={{ height: 'var(--app-height)' }}>
            <Drawer.Header>
              <Drawer.Title>Schedule Match</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <ManualSchedule open={confirmPopOpen} onClose={handleClose} isMobile={isMobile} matchSequence={sequence} groupId={groupId} />
            </Drawer.Body>
          </Drawer >
          :
          <Modal overflow backdrop="static" onHide={() => setConfirmPopOpen(false)} show={confirmPopOpen} size="sm" >
            <Modal.Header>
              <Modal.Title>Schedule Match</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ManualSchedule open={confirmPopOpen} onClose={handleClose} isMobile={isMobile} matchSequence={sequence} groupId={groupId} />
            </Modal.Body>
          </Modal>
      }
      {
        isMobile ?
          <Drawer show={autoSchedulePopup} onHide={() => setAutoSchedulePopup(false)} placement='bottom' style={{ height: 'var(--app-height)' }}>
            <Drawer.Header>
              <Drawer.Title>
                You're Scheduling for {roundName}
              </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <AutoSchedule
                group={groups[0]}
                onClose={handleSchedule}
                isSuperModerator={groups.some((playoff) => playoff.isSuperModerator)}
              />
            </Drawer.Body>
          </Drawer>
          :
          <Modal show={autoSchedulePopup} size="md" overflow onHide={() => setAutoSchedulePopup(false)} backdrop="static">
            <Modal.Header>
              <Modal.Title>
                You're Scheduling for {roundName}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AutoSchedule
                group={groups[0]}
                onClose={handleSchedule}
                isSuperModerator={groups.some((playoff) => playoff.isSuperModerator)}
              />
            </Modal.Body>
          </Modal>
      }
    </>
  )
}