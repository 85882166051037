import { PLAYOFF_TOURNAMENT_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Tournament } from 'core/models/Tournament';

const initialState: { playoffTournaments: Tournament[], count: number, currentPage: number, isLoading: boolean } = {
  playoffTournaments: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    playoffTournaments: Tournament[];
    count: number;
    currentPage: number;
  }
}

export default function playoffTournamentReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case PLAYOFF_TOURNAMENT_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case PLAYOFF_TOURNAMENT_LIST_ACTION.SUCCESS:
      const { playoffTournaments, count, currentPage } = action.payload;
      return { ...state, playoffTournaments, isLoading: false, count, currentPage }

    case PLAYOFF_TOURNAMENT_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}