import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { reducer as reduxFormReducer } from 'redux-form'
import {
  loaderReducer,
  groupListReducer,
  groupDetailsReducer,
  playoffTournamentReducer,
  playoffRoundsReducer,
  assignedSpacesReducer,
  spaceDetailsReducer,
  spacePostsReducer,
  spacePostDetailsReducer,
  matchScheduleListReducer,
  shortsDetailsReducer,
  shortsCommentsReducer,
  enableToggleSwitchReducer,
  challengeResultReducer
} from '../redux/reducer'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Action } from 'core/models/ActionTypes'
import shortsListReducer from 'redux/reducer/shortsListReducer'

const reducer = combineReducers({
  form: reduxFormReducer,
  loader: loaderReducer,
  groups: groupListReducer,
  groupDetails: groupDetailsReducer,
  playoffTournaments: playoffTournamentReducer,
  playoffRoundDetails: playoffRoundsReducer,
  spaces: assignedSpacesReducer,
  space: spaceDetailsReducer,
  spacePosts: spacePostsReducer,
  spacePost: spacePostDetailsReducer,
  scheduledMatches: matchScheduleListReducer,
  shorts: shortsListReducer,
  spaceShort: shortsDetailsReducer,
  shortsComments: shortsCommentsReducer,
  isSwitchable: enableToggleSwitchReducer,
  challengeResult: challengeResultReducer,
})

const middlewares = [thunkMiddleware]

const store = createStore(reducer, applyMiddleware(...middlewares))

export default store

export type Store = ReturnType<typeof reducer>
export type TypedDispatch = ThunkDispatch<Store, any, Action>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, Store, unknown, Action>;

export const useTypedDispatch = () => useDispatch<TypedDispatch>();
export const useTypedSelector: TypedUseSelectorHook<Store> = useSelector;