import { Button } from "rsuite";

interface ConfirmationProps {
  onAccept(): void;
  onClose(): void;
}
export default function ConfirmationPop({ onAccept, onClose }: ConfirmationProps) {
  return (
    <>
      <div className='confirmation-pop'>Make sure current time doesn't conflict with existing schedule? </div>
      <div className='btn-container'>
        <Button id='schedule-cancel' className='cancel-btn' appearance='primary' onClick={onClose}>No</Button>
        <Button id='schedule-confirm' appearance='primary' onClick={onAccept}>Yes</Button>
      </div>
    </>
  )
}