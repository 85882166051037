import { IconTypes } from './Icons'

export default function FireGlow({ color = "#ECA51D", fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M4.67009 5.07913C3.19978 7.12135 2 9.45414 2 11.629C2 13.5839 2.84285 15.4588 4.34315 16.8411C5.84344 18.2234 7.87827 19 10 19C12.1217 19 14.1566 18.2234 15.6569 16.8411C17.1571 15.4588 18 13.5839 18 11.629C18 7.06602 14.5714 3.55602 11.7927 1L11.7926 1.00007L8.47619 7.41702L4.67019 5.07918L4.67009 5.07913Z" fill="#ECA51D" />
    </svg>
  )
}
