import { IconTypes } from './Icons'

export default function GoggleFaceOPEmoji({ fontSize = '1.6rem', className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize }}
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M20 10C20 15.5228 15.5228 20 10 20C4.47722 20 0 15.5228 0 10C0 4.47722 4.47722 0 10 0C15.5228 0 20 4.47722 20 10Z" fill="#FFCC4D" />
      <path d="M1.24242 9.55426H1.95383V11.0547H7.64636V9.55426H8.35777V8.05469H9.06918V6.55512H10.4924V8.05469H11.2038V9.55426H11.9157V11.0547H17.6078V9.55426H18.3192V8.05469H19.031V5.05469H0.531006V8.05469H1.24242V9.55426Z" fill="#141414" />
      <path d="M2.66576 8.05426H1.95435V6.55469H2.66576V8.05426ZM12.6272 6.55469H11.9158V8.05426H12.6272V6.55469ZM14.05 6.55469H13.3386V8.05426H14.05V6.55469ZM3.37717 8.05426H2.66576V9.55383H3.37717V8.05426ZM4.8 8.05426H4.08858V9.55383H4.8V8.05426ZM13.3386 8.05426H12.6272V9.55383H13.3386V8.05426ZM14.7618 8.05426H14.0504V9.55383H14.7618V8.05426ZM4.08858 6.55469H3.37717V8.05426H4.08858V6.55469Z" fill="#F3F3F3" />
      <path d="M15.1862 13.1271C15.0873 13.0377 14.9396 13.0321 14.8334 13.111C14.8118 13.1271 12.6546 14.7221 10.0001 14.7221C7.35233 14.7221 5.18789 13.1271 5.16678 13.111C5.06067 13.0321 4.91289 13.0388 4.814 13.1271C4.71567 13.216 4.69344 13.3627 4.76178 13.476C4.83344 13.5954 6.549 16.3888 10.0001 16.3888C13.4512 16.3888 15.1673 13.5954 15.2384 13.476C15.3068 13.3621 15.2851 13.216 15.1862 13.1271Z" fill="#664500" />
    </svg>
  )
}
