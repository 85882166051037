import { GroupDetails } from 'core/models/GroupDetails';

interface ChallengeResultTableProps {
  challengeDetail: GroupDetails;
  isMobile: boolean;
}

export default function ChallengeResultTable({ challengeDetail, isMobile }: ChallengeResultTableProps) {

  function result() {
    return (
      <div>
        {challengeDetail?.results[1].map((result: any) => {
          const matchingTeam = challengeDetail?.roundResults[0].teamResults?.find(
            team => team?.teamId === result?.teamId
          );

          return (
            matchingTeam && (
              <div className='table-data-container'>
                <div className='rank'>{result?.teamRank}</div>
                <div className='name'>{matchingTeam?.teamName}</div>
              </div>
            )
          );
        })}
      </div>
    );
  };


  return (
    <div className='challenge-result-table-container'>
      <div className='table-heading-container'>
        <div className='heading'>Rank</div>
        <div className='heading'>Player Name</div>
      </div>
      {result()}
    </div >
  )
}
