import { Header, Icon, Avatar, Dropdown } from 'rsuite'
import { useDispatch } from 'react-redux'
import logo from '../assets/icons/logo.png'
import icon from 'assets/icons/battlexo-icon.png'
import DropdownText from '../shared/components/DropdownText'
import { clearUser } from '../core/services/Storage'
import { hideLoader, showLoader } from '../redux/action/loaderAction'
import history from '../App/History'
import MenuLine from 'shared/icons/MenuLine'
import { useEffect, useState } from 'react'
import SideDrawer from './SideDrawer'
import { CONFIG } from 'core/config'
import { useTypedSelector } from 'App/Store'
import { enableToggleSwitch } from 'redux/action/enableToggleSwitchAction'

export default function Topbar() {
  const [open, setOpen] = useState(false)
  const [screenWidth, setScreenWidth] = useState(0)
  const dispatch = useDispatch()
  const { isSwitchable } = useTypedSelector((state) => state.isSwitchable);

  useEffect(() => {
    const token = localStorage.getItem('moderator-token')
    if (token) {
      dispatch(enableToggleSwitch(token))
    }
  }, [dispatch])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setOpen(false)
    }
  }, [screenWidth])


  async function logoutUser() {
    dispatch(showLoader())
    clearUser()
    history.replace('/')
    dispatch(hideLoader())
  }

  function handleDrawer() {
    setOpen(true)
  }

  function handleSelect() {
    dispatch(showLoader())
    const modToken = localStorage.getItem('moderator-token')
    window.location.href = `${CONFIG.adminUrl}/user/login/external/callback?code=${modToken}`;
    setTimeout(() => {
      dispatch(hideLoader())
    }, 5000)
  }

  return (
    <>
      <Header className="app-topbar">
        <div className='top-bar-left-container'>
          <img src={logo} alt="BattleXo" className="topbar-logo" />
          <div onClick={handleDrawer} className='menu-style'>
            <MenuLine fontSize={20} />
          </div>
        </div>
        <div>
          {
            isSwitchable &&
            <Dropdown
              title='Moderator'
              className='switch-role'
              onSelect={handleSelect}
            >
              <Dropdown.Item>Admin</Dropdown.Item>
            </Dropdown>
          }
          <Dropdown
            title='XO'
            renderTitle={(children) => <Avatar src={icon} circle>{children}</Avatar>}
            placement="bottomEnd"
          >
            <Dropdown.Item componentClass={DropdownText}><Icon icon="user" style={{ marginRight: '1rem' }} />Moderator</Dropdown.Item>
            <Dropdown.Item onSelect={logoutUser}><Icon icon="sign-out" /> Logout</Dropdown.Item>
          </Dropdown>
        </div>
      </Header >
      <SideDrawer open={open} onClose={() => setOpen(false)} />
    </>

  )
}
