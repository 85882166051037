import { List, Avatar } from 'rsuite'
import { Team, TeamMember } from 'core/models/GroupDetails'
import { getShortName } from 'core/utils/StringHelper'
import checked from 'assets/icons/checked.png'
import captain from 'assets/icons/captain.png'

interface TeamDetailsProps {
  teamMembers: Team[];
  isMobile: boolean;
}

export default function TeamDetails({ teamMembers, isMobile }: TeamDetailsProps) {
  return (
    <div className='team-detail-container'>
      <div className='team'>
        {
          teamMembers.map(eachTeam =>
            <div key={eachTeam.id}>
              <div className='team-name-slot'>
                <p className='team-name'>{eachTeam.name}</p>
                {
                  !!eachTeam.slot
                  &&
                  <p className='team-name'>Slot {eachTeam.slot}</p>
                }
              </div>
              <List bordered className="team-details-list">
                {
                  eachTeam.user_teams.map(
                    (teamMember: TeamMember) =>
                      <List.Item key={teamMember.userId} className='member-name-checked-wrapper'>
                        <div className='member-name-wrapper'>
                          <Avatar size={isMobile ? 'sm' : 'md'} circle style={{ backgroundColor: '#6e6af0' }}>{getShortName(teamMember?.inGameName)}</Avatar>
                          <p className="member-name">{`${teamMember?.inGameName} <${teamMember?.userName}>`} </p>
                          <span>{teamMember.role.toLowerCase() === 'captain' && <img src={captain} alt='captain' className='team-captain' />}</span>
                        </div>
                        {
                          teamMember.checkedIn &&
                          <img src={checked} alt='checked-in' className='checked-in' />
                        }
                      </List.Item>
                  )
                }
              </List>
              {
                teamMembers.length > 1
                &&
                <div className='hr-line'></div>
              }
            </div>
          )
        }
      </div>
    </div>
  )
}
