import { Pagination as Pager } from 'rsuite'

interface PaginationProps {
  pages: number;
  limit: number;
  activePage: number;
  maxButtons?: number;
  onPageChange?(pageNumber: number): void;
}

export default function Pagination({ activePage, limit, pages, maxButtons = 6, onPageChange }: PaginationProps) {
  return (
    <Pager
      className='bxo-pagination'
      pages={pages}
      limit={limit}
      activePage={activePage}
      prev
      next
      maxButtons={maxButtons}
      boundaryLinks
      ellipsis
      onSelect={onPageChange}
      size="md"
      buttonComponentClass='button'
    />
  )
}
