import { Button, Drawer, Dropdown, Icon, IconStack, Modal } from "rsuite"
import { GroupDetails } from "core/models/GroupDetails"
import RoundResult from "../RoundResult"
import { Group, ScheduledGroupWiseMatches } from "core/models/Group"
import PlayerStats from "../PlayerStats"
import { useEffect, useState } from "react"
import PlayOffDetailCard from 'components/PlayOffDetailCard'
import TeamDetails from './TeamDetails'
import GroupWiseScheduleTable from 'components/GroupWiseScheduleTable'
import ManualSchedule from 'components/ManualSchedule'
import BanTeam from "components/BanTeam"

interface PlayoffDetailsProps {
  onSendCredentials(): void;
  onAddResults(): void;
  groupDetails: GroupDetails;
  matchDetails?: ScheduledGroupWiseMatches[];
  setConfirmPopOpen?(value: any): void;
  confirmPopOpen?: boolean;
  onBanTeams?(teams: number[]): void;
}

export default function PlayoffDetails({
  onSendCredentials,
  onAddResults,
  groupDetails,
  matchDetails,
  setConfirmPopOpen,
  confirmPopOpen,
  onBanTeams
}: PlayoffDetailsProps) {
  const [showPlayerStats, setShowPlayerStats] = useState(false);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [teamPop, setTeamPop] = useState(false);
  const [teamData, setTeamData] = useState<any>({});
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)
  const [banOpen, setBanOpen] = useState(false)
  const [sequence, setSequence] = useState<any>()

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])


  function getGroup(): Group {
    return {
      gameName: groupDetails.gameName,
      groupId: groupDetails.groupId,
      isCompleted: groupDetails.isCompleted,
      roundSequence: groupDetails.roundSequence,
      roundType: groupDetails.roundType,
      teams: groupDetails.teams.length,
      tournamentDate: groupDetails.tournamentDate,
      tournamentId: groupDetails.tournamentId,
      tournamentImage: groupDetails.tournamentImage,
      tournamentName: groupDetails.tournamentName,
      isSuperModerator: groupDetails.isSuperModerator,
      moderatorEmail: groupDetails.moderatorEmail,
      groupName: groupDetails.groupName
    };
  }

  function handleTeamStatusDetails(teamDetails: any) {
    setTeamData(teamDetails);
    setShowPlayerStats(true);
  }

  function handlePlayerStatsClose() {
    setShowPlayerStats(false);
  }

  function handleTime(data: ScheduledGroupWiseMatches) {
    setSequence(data.matchSequence)
    if (setConfirmPopOpen) {
      setConfirmPopOpen(true)
    }
  }
  function handleSchedule() {
    if (setConfirmPopOpen) {
      setConfirmPopOpen(false)
    }
  }

  function handleTeamBan(teams: number[]) {
    setBanOpen(false)
    if (onBanTeams) {
      onBanTeams(teams)
    }
  }

  return (
    <>
      <div className='playoff-result-credential-btn'>
        <Dropdown
          renderTitle={() => <Button appearance="primary">Manage <Icon icon="arrow-down-line" /></Button>}
          placement='bottomEnd'
          className='mods-menu'
        >
          {
            !groupDetails.isCompleted &&
            <>
              <Dropdown.Item onSelect={onAddResults} >
                <Icon icon='bar-chart' />Add Results
              </Dropdown.Item>
              <Dropdown.Item id='playoff-add-result' onSelect={onSendCredentials}>
                <Icon icon='share-square-o' />Send Credentials
              </Dropdown.Item>
            </>
          }
          {
            !!matchDetails?.length &&
            <Dropdown.Item id='playoff-add-result' onSelect={() => setScheduleModal(true)}>
              <Icon icon='calendar' />Schedule
            </Dropdown.Item>
          }
          <Dropdown.Item id='playoff-add-result' onSelect={() => setTeamPop(true)}>
            <Icon icon='group' />View Team
          </Dropdown.Item>
          {
            !groupDetails.isCompleted &&
            <Dropdown.Item id='playoff-add-result' onSelect={() => setBanOpen(true)}>
              <IconStack style={{ height: 20, width: 20, lineHeight: 1, marginRight: 2 }}>
                <Icon icon='group' stack="1x" style={{ fontSize: 10, top: 4 }} />
                <Icon icon="ban" stack="1x" style={{ color: '#f44336', fontSize: 20 }} />
              </IconStack>Ban Team
            </Dropdown.Item>
          }
        </Dropdown>
      </div>
      <div className="playoff-teams">
        {groupDetails.teams.map((team) => (
          <PlayOffDetailCard
            teamName={team.name}
            gameName={groupDetails.gameName}
            round={groupDetails.roundSequence}
            matchSequence={groupDetails.matchSequence}
            slot={team.slot}
            group={getGroup()}
            key={team.id}
          />
        )).slice(0, 1)}
      </div>
      {
        Boolean(groupDetails?.roundResults?.length) && (
          <RoundResult
            roundResults={groupDetails.roundResults}
            onTeamClick={handleTeamStatusDetails}
          />
        )
      }
      <Drawer show={teamPop} className='team-drawer' onHide={() => setTeamPop(false)} placement="right" style={{ height: 'var(--app-height)', width: isMobile ? '100%' : '54rem' }}>
        <Drawer.Header>
          <Drawer.Title>
            Teams
          </Drawer.Title>
        </Drawer.Header>
        <TeamDetails isMobile={isMobile} teamMembers={groupDetails.teams} />
      </Drawer>
      {
        isMobile
          ?
          <Drawer show={showPlayerStats} onHide={handlePlayerStatsClose} placement='bottom' style={{ width: "content-ft" }}  >
            <Drawer.Header>
              <Drawer.Title style={{ textTransform: "capitalize" }}>{`${teamData.teamName} Scores`}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <PlayerStats
                onClose={handlePlayerStatsClose}
                show={showPlayerStats}
                teamData={teamData}
              />
            </Drawer.Body>
          </Drawer>
          :
          <Modal show={showPlayerStats} onHide={handlePlayerStatsClose} className='player-stat-modal' >
            <Modal.Header>
              <Modal.Title style={{ textTransform: "capitalize" }}>{`${teamData.teamName} Scores`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PlayerStats
                onClose={handlePlayerStatsClose}
                show={showPlayerStats}
                teamData={teamData}
              />
            </Modal.Body>
          </Modal>
      }
      {
        isMobile ?
          <Drawer onHide={handleSchedule} show={confirmPopOpen} placement="bottom" style={{ height: 'var(--app-height)' }}>
            <Drawer.Header>
              <Drawer.Title>Schedule matches of {groupDetails.groupName}</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <ManualSchedule open={confirmPopOpen} onClose={handleSchedule} isMobile={isMobile} matchSequence={sequence} groupId={groupDetails.groupId} />
            </Drawer.Body>
          </Drawer >
          :
          <Modal overflow backdrop="static" onHide={handleSchedule} show={confirmPopOpen} size='md' >
            <Modal.Header>
              <Modal.Title>Schedule matches of {groupDetails.groupName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ManualSchedule open={confirmPopOpen} onClose={handleSchedule} isMobile={isMobile} matchSequence={sequence} groupId={groupDetails.groupId} />
            </Modal.Body>
          </Modal>
      }
      <GroupWiseScheduleTable matchDetails={matchDetails} open={scheduleModal} onClose={() => setScheduleModal(false)} isMobile={isMobile} onHandleTime={handleTime} />
      <BanTeam
        isMobile={isMobile}
        teams={groupDetails.teams}
        isOpen={banOpen}
        onBanTeam={handleTeamBan}
        onClose={() => setBanOpen(false)}
      />
    </>
  );
}