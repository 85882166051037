import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "App/Store";
import AddResults from "components/AddResults";
import PlayoffDetails from "components/PlayoffDetails";
import SendCredentialModal from "components/SendCredentialsModal";
import { groupDetails as getGroupDetails } from "redux/action/groupDetailsAction";
import { hideLoader, showLoader } from "redux/action/loaderAction";
import {
  sendRoomCredentials,
  getResultFormConfigs,
  sendResults,
  getPlacementPoints,
  manualScheduleGroupWise,
  getScheduledGroupWiseMatches,
  banTeams,
} from "core/services/Groups";
import { DEFAULT_API_ERROR } from "core/constants/Defaults";
import { FormConfig } from "core/models/FormConfig";
import { groupBy } from "core/utils/DataHelper";
import { SelectOptions } from "core/models/Settings";
import { convertToRank } from "core/utils/StringHelper";
import { Drawer, Modal } from 'rsuite';
import { ScheduledGroupWiseMatches } from 'core/models/Group';
import dayjs from 'dayjs';
import { useNotification } from "shared/hooks/useNotification";

interface UrlParams {
  playoffId: string;
}

export default function PlayoffDetailsPage() {
  const urlParams = useParams<UrlParams>();
  const dispatch = useTypedDispatch();
  const groupDetailsList = useTypedSelector((state) => state.groupDetails);
  const { groupDetails, isLoading } = groupDetailsList;
  const [showModal, setShowModal] = useState(false);
  const [placementPoints, setPlacementPoints] = useState<SelectOptions[]>([]);
  const [showAddResults, setShowAddResults] = useState(false);
  const showNotification = useNotification()
  const [playerFormConfig, setPlayerFormConfig] = useState<FormConfig[]>([]);
  const [teamFormConfig, setTeamFormConfig] = useState<FormConfig[]>([]);
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)
  const [matchDetails, setMatchDetails] = useState<ScheduledGroupWiseMatches[]>([])
  const [confirmPopOpen, setConfirmPopOpen] = useState(false)

  useEffect(() => {
    dispatch(getGroupDetails({ id: urlParams.playoffId }));
  }, [dispatch, urlParams]);

  useEffect(() => {
    async function getList() {
      const { status, data } = await getScheduledGroupWiseMatches({ groupId: urlParams.playoffId })
      if (status && data) {
        setMatchDetails(data.scheduledGroupWiseMatches)
      }
    }
    getList()
  }, [urlParams.playoffId])

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
      if (groupDetails.length) {
        getResultFormConfigs({ id: groupDetails[0].tournamentId }).then((res) => {
          if (res.status && res.data) {
            const config = groupBy(
              res.data.form,
              (form: FormConfig) => form.paramType
            );
            setPlayerFormConfig(config.get("player") || []);
            setTeamFormConfig(config.get("team") || []);
          }
        });
      }
    }
  }, [isLoading, dispatch, groupDetails]);

  function handleSendCredentials() {
    setShowModal(true);
  }

  async function handleAddResults() {
    const placementRes = await getPlacementPoints(
      {
        id: groupDetails[0].tournamentId,
        round: groupDetails[0].roundSequence
      }
    );
    if (
      placementRes.status &&
      placementRes.data &&
      placementRes.data.placementPoints
    ) {
      setPlacementPoints(
        placementRes.data.placementPoints.map((placement) => {
          if (typeof placement.rank === "number") {
            return {
              label: convertToRank(placement.rank),
              value: placement.rank,
            };
          } else {
            return {
              label: placement.rank,
              value: placement.rank,
            };
          }
        })
      );
    }
    setShowAddResults(true);
  }

  function handleClose() {
    setShowModal(false);
    setShowAddResults(false);
  }

  function sendCredentialsToTeams(credentials: any, time: any) {
    dispatch(showLoader());
    credentials.id = groupDetails[0].groupId;
    credentials.tournamentId = groupDetails[0].tournamentId;
    if (!dayjs(time).isSame(dayjs(credentials.roomDateTime))) {
      manualScheduleGroupWise({ groupId: credentials.id, matchSequence: groupDetails[0].matchSequence, scheduledTime: credentials.roomDateTime })
    }
    sendRoomCredentials(credentials).then((res) => {
      if (res.status) {
        dispatch(getGroupDetails({ id: urlParams.playoffId }));
        setShowModal(false);
        showNotification({
          title: "Success",
          message: "Credentials Sent Successfully",
          type: "success",
        });
      } else {
        showNotification({
          title: "Error",
          message: res.message || DEFAULT_API_ERROR,
          type: "error",
        });
      }
      dispatch(hideLoader());
    });
  }

  function handleSendResults(results: any) {
    dispatch(showLoader());
    const { tournamentId, groupId, roundSequence, matchSequence } =
      groupDetails[0];
    sendResults({
      ...results,
      tournamentId,
      groupId,
      roundSequence,
      matchSequence,
    }).then((res) => {
      dispatch(hideLoader());
      if (res.status) {
        dispatch(getGroupDetails({ id: urlParams.playoffId }));
        setShowAddResults(false);
        showNotification({
          message: "Results Added Successfully",
          title: "Success",
          type: "success",
        });
      } else {
        showNotification({
          message: res.message || DEFAULT_API_ERROR,
          title: "Error",
          type: "error",
        });
      }
    });
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])

  async function handleTeamBan(teams: number[]) {
    dispatch(showLoader())
    const res = await banTeams({ teams, groupId: urlParams.playoffId });
    dispatch(hideLoader())
    if (res.status) {
      dispatch(getGroupDetails({ id: urlParams.playoffId }));
      showNotification({
        message: "Teams Banned Successfully",
        title: "Success",
        type: "success",
      });
    } else {
      showNotification({
        message: res.message || DEFAULT_API_ERROR,
        title: "Error",
        type: "error",
      });
    }
  }

  return (
    <div className='play-off-detail-page'>
      {Boolean(groupDetails.length) && (
        <PlayoffDetails
          onSendCredentials={handleSendCredentials}
          onAddResults={handleAddResults}
          groupDetails={groupDetails[0]}
          matchDetails={matchDetails}
          setConfirmPopOpen={setConfirmPopOpen}
          confirmPopOpen={confirmPopOpen}
          onBanTeams={handleTeamBan}
        />
      )}
      {showModal && (
        isMobile ?
          <Drawer onHide={handleClose} show={showModal} placement="bottom" style={{ height: 'var(--app-height)' }}>
            <Drawer.Header>
              <Drawer.Title >Send Credentials</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <SendCredentialModal
                onSend={sendCredentialsToTeams}
                teams={groupDetails[0]?.teams || []}
                matchSequence={groupDetails[0]?.matchSequence}
                credentials={groupDetails[0]?.credentials}
                matchDetails={matchDetails}
              />
            </Drawer.Body>
          </Drawer>
          :
          <Modal overflow backdrop="static" onHide={handleClose} show={showModal} size="sm">
            <Modal.Header>
              <Modal.Title>Send Credentials</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SendCredentialModal
                onSend={sendCredentialsToTeams}
                teams={groupDetails[0]?.teams || []}
                credentials={groupDetails[0]?.credentials}
                matchDetails={matchDetails}
                matchSequence={groupDetails[0]?.matchSequence}
              />
            </Modal.Body>
          </Modal>
      )}
      {
        showAddResults &&
        <AddResults
          playerFormConfig={playerFormConfig}
          teamFormConfig={teamFormConfig}
          placementPoints={placementPoints}
          teams={groupDetails[0].teams}
          show={showAddResults}
          onClose={handleClose}
          onSendResults={handleSendResults}
        />
      }
    </div>
  );
}