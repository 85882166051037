import { GROUP_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Group } from 'core/models/Group';

const initialState: { groups: Group[], isLoading: boolean } = {
  groups: [],
  isLoading: false
}

interface ActionType extends Action {
  payload: {
    groups: Group[];
  }
}

export default function groupListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case GROUP_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case GROUP_LIST_ACTION.SUCCESS:
      const { groups } = action.payload;
      return { ...state, groups, isLoading: false }

    case GROUP_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}