import { Drawer, Modal } from 'rsuite';
import { ScheduledGroupWiseMatches } from 'core/models/Group';
import GroupWiseTable from './GroupWiseTable';

interface GroupWiseScheduleTableProps {
  isMobile: boolean;
  onHandleTime(data: any): void;
  open: boolean;
  onClose(): void;
  matchDetails?: ScheduledGroupWiseMatches[];
}

export default function GroupWiseScheduleTable({ isMobile, matchDetails, onHandleTime, open, onClose }: GroupWiseScheduleTableProps) {

  return (
    <>
      {
        isMobile
          ?
          <Drawer show={open} onHide={onClose} placement='bottom' style={{ height: 'var(--app-height)', width: "content-ft" }}  >
            <Drawer.Header>
              <Drawer.Title style={{ textTransform: "capitalize" }}>Schedule match</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <GroupWiseTable
                onHandleTime={onHandleTime}
                matchDetails={matchDetails}
              />
            </Drawer.Body>
          </Drawer>
          :
          <Modal show={open} onHide={onClose} className='player-stat-modal' size='sm'>
            <Modal.Header style={{ marginBottom: '2rem' }}>
              <Modal.Title style={{ textTransform: "capitalize" }}>Schedule match</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <GroupWiseTable
                onHandleTime={onHandleTime}
                matchDetails={matchDetails}
              />
            </Modal.Body>
          </Modal>
      }
    </>
  )
}