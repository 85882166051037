import { useTypedDispatch, useTypedSelector } from "App/Store";
import DeleteComment from 'components/DeleteComment';
import PostDetail from "components/PostDetail";
import { DEFAULT_API_ERROR } from "core/constants/Defaults";
import { createPostComment, deletePostComment, updateSpacePostStatus } from "core/services/Space";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { hideLoader, showLoader } from "redux/action/loaderAction";
import { getSpacePostDetails } from "redux/action/spacePostDetailsAction";
import { useNotification } from "shared/hooks/useNotification";

interface UrlParams {
  postId: string;
  spaceId: string;
}

export default function SpacePostDetail() {

  const urlParams = useParams<UrlParams>()
  const spacePostDetails = useTypedSelector(state => state.spacePost)
  const { isLoading, spacePost } = spacePostDetails
  const showNotification = useNotification()
  const dispatch = useTypedDispatch()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [commentId, setCommentId] = useState(0)
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)


  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])

  useEffect(() => {
    dispatch(getSpacePostDetails(urlParams.postId, urlParams.spaceId))
  }, [dispatch, urlParams])

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [isLoading, dispatch])

  function handleUpdateStatus(postData: any) {
    dispatch(showLoader())
    updateSpacePostStatus(postData)
      .then(updateRes => {
        if (updateRes.status) {
          showNotification({
            message: 'Space Post Updated Successfully',
            title: 'Success',
            type: 'success'
          })
          dispatch(getSpacePostDetails(urlParams.postId, urlParams.spaceId))
        } else {
          showNotification({
            message: updateRes.message || DEFAULT_API_ERROR,
            title: 'Error',
            type: 'error'
          })
        }
        dispatch(hideLoader())
      })
  }

  function handleCreateComment(commentData: any) {
    createPostComment({ ...commentData, spacePostId: urlParams.postId })
      .then(res => {
        if (res.status) {
          showNotification({
            message: 'Comment is Posted Successfully',
            title: 'Success',
            type: 'success'
          })
          dispatch(getSpacePostDetails(urlParams.postId, urlParams.spaceId))
        } else {
          showNotification({
            message: res.message || DEFAULT_API_ERROR,
            title: 'Error',
            type: 'error'
          })
        }
        dispatch(hideLoader)
      })
  }

  function deleteComment() {
    if (commentId) {
      deletePostComment(commentId).then(res => {
        if (res.status) {
          showNotification({
            message: 'Comment is Deleted Successfully',
            title: 'Success',
            type: 'success'
          })
          setDeleteOpen(false)
          dispatch(getSpacePostDetails(urlParams.postId, urlParams.spaceId))
        }
        else {
          showNotification({
            message: res.message || DEFAULT_API_ERROR,
            title: 'Error',
            type: 'error'
          })
        }
      })
    }
  }

  function handleStatusChange(postStatus: 'live' | 'inactive') {
    dispatch(getSpacePostDetails(urlParams.postId, urlParams.spaceId))
  }

  function handleCommentDelete(spaceCommentId: number) {
    setCommentId(spaceCommentId)
    setDeleteOpen(true)
  }

  return (
    <div className='space-post-detail'>
      {
        spacePost &&
        <PostDetail
          post={spacePost}
          onChangeStatus={handleStatusChange}
          onUpdateStatus={handleUpdateStatus}
          onDeleteComment={handleCommentDelete}
          onCreateComment={handleCreateComment} />
      }
      <DeleteComment
        onCancel={() => setDeleteOpen(false)}
        deleteComment={deleteComment}
        onOpen={deleteOpen}
        isMobile={isMobile}
        onClose={() => setDeleteOpen(false)} />
    </div>
  )
}
