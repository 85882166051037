import { IconTypes } from './Icons'

export default function SadEmoji({ fontSize = '1.6rem', className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize }}
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M20 10C20 15.5228 15.5228 20 10 20C4.47778 20 0 15.5228 0 10C0 4.47778 4.47778 0 10 0C15.5228 0 20 4.47778 20 10Z" fill="#73B0B5" />
      <path d="M6.38889 11.1115C7.15595 11.1115 7.77778 10.241 7.77778 9.1671C7.77778 8.09321 7.15595 7.22266 6.38889 7.22266C5.62183 7.22266 5 8.09321 5 9.1671C5 10.241 5.62183 11.1115 6.38889 11.1115Z" fill="#292F33" />
      <path d="M13.6112 11.1115C14.3782 11.1115 15.0001 10.241 15.0001 9.1671C15.0001 8.09321 14.3782 7.22266 13.6112 7.22266C12.8441 7.22266 12.2223 8.09321 12.2223 9.1671C12.2223 10.241 12.8441 11.1115 13.6112 11.1115Z" fill="#292F33" />
      <path d="M13.047 15.4884C13.0409 15.464 12.4109 13.0557 9.9998 13.0557C7.58868 13.0557 6.95868 15.464 6.95257 15.4884C6.92313 15.6068 6.97702 15.7279 7.08146 15.7907C7.18591 15.8529 7.32202 15.8384 7.41257 15.7573C7.41757 15.7534 7.97535 15.2779 9.9998 15.2779C11.9942 15.2779 12.5648 15.739 12.5865 15.7573C12.6392 15.8079 12.7081 15.8334 12.7776 15.8334C12.8242 15.8334 12.8715 15.8218 12.9142 15.7979C13.0231 15.7362 13.0776 15.6096 13.047 15.4884Z" fill="#292F33" />
      <path d="M5.55556 16.6669C5.55556 18.2013 4.31222 19.4447 2.77778 19.4447C1.24333 19.4447 0 18.2013 0 16.6669C0 15.1324 2.22222 11.1113 2.77778 11.1113C3.33333 11.1113 5.55556 15.1324 5.55556 16.6669Z" fill="#03656E" />
      <path d="M16.6666 7.22263C13.581 7.22263 12.3322 4.79652 12.2805 4.69318C12.1433 4.41874 12.2544 4.08485 12.5288 3.94763C12.8022 3.81041 13.1355 3.92096 13.2733 4.19374C13.3149 4.27485 14.2849 6.11152 16.6666 6.11152C16.9738 6.11152 17.2222 6.36041 17.2222 6.66707C17.2222 6.9743 16.9738 7.22263 16.6666 7.22263ZM3.33327 7.22263C3.0266 7.22263 2.77771 6.97374 2.77771 6.66707C2.77771 6.36041 3.0266 6.11152 3.33327 6.11152C6.15715 6.11152 6.66438 4.37818 6.68493 4.3043C6.76549 4.01096 7.06827 3.83318 7.36215 3.91041C7.6566 3.98763 7.83493 4.2843 7.76104 4.57985C7.73382 4.68763 7.05771 7.22263 3.33327 7.22263Z" fill="#292F33" />
    </svg>
  )
}
