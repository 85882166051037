import { ASSIGNED_SPACE_POST_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { SpacePost } from 'core/models/Space';

const initialState: { spacePosts: SpacePost[], isLoading: boolean, count: number, currentPage: number } = {
  spacePosts: [],
  isLoading: false,
  count: 4,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    spacePosts: SpacePost[];
    count: number,
    currentPage: number
  }
}

export default function spacePostssReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case ASSIGNED_SPACE_POST_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case ASSIGNED_SPACE_POST_LIST_ACTION.SUCCESS:
      const { spacePosts, count, currentPage } = action.payload;
      return { ...state, spacePosts, isLoading: false, count, currentPage }

    case ASSIGNED_SPACE_POST_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}