import { IconTypes } from './Icons'

export default function MenuThreeDot({ color = '#FFFFFF', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M4.8001 7.99961C4.8001 8.88326 4.08375 9.59961 3.2001 9.59961C2.31644 9.59961 1.6001 8.88326 1.6001 7.99961C1.6001 7.11595 2.31644 6.39961 3.2001 6.39961C4.08375 6.39961 4.8001 7.11595 4.8001 7.99961Z" fill={color} />
      <path d="M9.6001 7.99961C9.6001 8.88326 8.88375 9.59961 8.0001 9.59961C7.11644 9.59961 6.4001 8.88326 6.4001 7.99961C6.4001 7.11595 7.11644 6.39961 8.0001 6.39961C8.88375 6.39961 9.6001 7.11595 9.6001 7.99961Z" fill={color} />
      <path d="M14.4001 7.99961C14.4001 8.88326 13.6838 9.59961 12.8001 9.59961C11.9164 9.59961 11.2001 8.88326 11.2001 7.99961C11.2001 7.11595 11.9164 6.39961 12.8001 6.39961C13.6838 6.39961 14.4001 7.11595 14.4001 7.99961Z" fill={color} />
    </svg>
  )
}

