export function groupBy(list: any[], keyGetter: Function) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}

export function mergeJSON(partialData: any, defaultValue: any) {
  if (typeof partialData !== 'object' || typeof defaultValue !== 'object' || partialData === null || defaultValue === null) {
    return partialData;
  }

  if (Array.isArray(partialData) && Array.isArray(defaultValue)) {
    // Merge arrays while preserving the array structure
    const maxLength = Math.max(partialData.length, defaultValue.length);
    const mergedArray = new Array(maxLength);
    for (let i = 0; i < maxLength; i++) {
      mergedArray[i] = mergeJSON(partialData[i], defaultValue[i]);
    }
    return mergedArray;
  }

  const mergedData = { ...defaultValue };

  for (const key in partialData) {
    const partialValue = partialData[key];
    const defaultValueValue = defaultValue[key];

    if (defaultValue.hasOwnProperty(key)) {
      mergedData[key] = mergeJSON(partialValue, defaultValueValue);
    } else {
      mergedData[key] = partialValue;
    }
  }

  return mergedData;
}
