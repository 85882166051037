import { Team } from "core/models/GroupDetails";
import { useEffect, useState } from "react";
import { Button, Checkbox, CheckboxGroup, Drawer, Modal } from "rsuite";

interface BanTeamProps {
  isMobile: boolean;
  teams: Team[];
  onClose(): void;
  isOpen: boolean;
  onBanTeam(team: number[]): void;
}

export default function BanTeam({ isMobile, teams, isOpen, onClose, onBanTeam }: BanTeamProps) {

  const [bannedTeams, setBannedTeams] = useState<number[]>([]);

  useEffect(() => {
    if (!isOpen) {
      setBannedTeams([])
    }
  }, [isOpen])

  function handleTeamBan() {
    onBanTeam(bannedTeams)
  }

  return (
    isMobile ?
      <Drawer onHide={onClose} show={isOpen} placement="bottom" style={{ height: 'var(--app-height)' }}>
        <Drawer.Header>
          <Drawer.Title>Teams</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <div className="ban-team-drawer">
            <CheckboxGroup
              name="checkboxList"
              value={bannedTeams}
              onChange={setBannedTeams}
            >
              {teams.map(team => <Checkbox key={team.id} value={team.id}>{team.name}</Checkbox>)}
            </CheckboxGroup>
          </div>
          <div className="ban-button">
            <Button appearance="primary" disabled={!bannedTeams.length} onClick={handleTeamBan}>Ban Teams</Button>
            <Button appearance="ghost" onClick={onClose}>Cancel</Button>
          </div>
        </Drawer.Body>
      </Drawer >
      :
      <Modal overflow backdrop="static" onHide={onClose} show={isOpen} size='md' >
        <Modal.Header>
          <Modal.Title>Teams</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CheckboxGroup
            name="checkboxList"
            value={bannedTeams}
            onChange={setBannedTeams}
          >
            {teams.map(team => <Checkbox key={team.id} value={team.id}>{team.name}</Checkbox>)}
          </CheckboxGroup>
        </Modal.Body>
        <Modal.Footer>
          <div className="ban-button">
            <Button appearance="primary" disabled={!bannedTeams.length} onClick={handleTeamBan}>Ban Teams</Button>
            <Button appearance="ghost" onClick={onClose}>Cancel</Button>
          </div>
        </Modal.Footer>
      </Modal>
  )
}
