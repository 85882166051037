import { TypedDispatch, TypedThunk } from 'App/Store'
import { MATCH_SCHEDULE_LIST_ACTION } from 'core/constants/Actions'
import { getScheduleList } from 'core/services/Groups'

export function matchScheduleList(params: any): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: MATCH_SCHEDULE_LIST_ACTION.REQUEST })
    const apiResponse = await getScheduleList({ params })
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: MATCH_SCHEDULE_LIST_ACTION.SUCCESS,
        payload: { scheduledMatches: apiResponse.data.scheduledMatches, roundName: apiResponse.data.roundName }
      })
    } else {
      dispatch({
        type: MATCH_SCHEDULE_LIST_ACTION.FAILURE
      })
    }
  }
}
export function resetBannerDetails() {
  return {
    type: MATCH_SCHEDULE_LIST_ACTION.RESET
  }
}