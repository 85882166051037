import clsx from 'clsx';
import { GroupDetails } from 'core/models/GroupDetails';
import { getDate, getTime } from 'core/utils/Date';


interface ChallengeDetailProps {
  challengeDetail: GroupDetails;
}

export default function ChallengeDetail({ challengeDetail }: ChallengeDetailProps) {
  return (
    <div className='challenge-detail-container'>
      <div className='challenge-image'>
        <img src={challengeDetail?.tournamentIcon} alt='challengeIcon' className='image' />
      </div>
      <div className='challenge-detail-wrapper'>
        <div className='challenge-name-wrapper'>
          <div className='name'>{challengeDetail?.tournamentName}</div>
          <div className='quotes'>{challengeDetail?.description}</div>
        </div>
        <div className='challenge-info-wrapper'>
          <div className='entity-value-wrapper'>
            <span className='entity'>Date:</span>
            <span className='value'>{getDate(challengeDetail?.tournamentDate, "D MMM, YYYY")}</span>
          </div>
          <div className='entity-value-wrapper'>
            <span className='entity'>Time:</span>
            <span className='value'>{getTime(challengeDetail?.tournamentDate, "H:m IST")}</span>
          </div>
          <div className='entity-value-wrapper'>
            <span className='entity'>Moderator:</span>
            <span className='value'>{challengeDetail?.moderatorEmail}</span>
          </div>
          <div className='entity-value-wrapper'>
            <span className='entity'>Status</span>
            <span className={clsx('value', challengeDetail?.isCompleted ? 'completed' : 'live')}>{challengeDetail?.isCompleted ? 'Completed' : 'Live'}</span>
          </div>
          <div className='entity-value-wrapper'>
            <span className='entity'>Spot</span>
            <span className='value'>{challengeDetail?.maxTeams}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
