import { Reward } from "core/models/Reward"
import { Table } from "rsuite";

interface ViewRewardsProps {
  rewards: Reward[];
}

export default function ViewRewards({ rewards }: ViewRewardsProps) {
  return (
    <Table data={rewards} className='view-rewards-data' height={350} >
      <Table.Column width={150}>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.Cell dataKey="name" className='view-rewards-value' />
      </Table.Column>
      <Table.Column width={150}>
        <Table.HeaderCell >Username</Table.HeaderCell>
        <Table.Cell dataKey="userName" className='view-rewards-value' />
      </Table.Column>
      <Table.Column width={150}>
        <Table.HeaderCell>In Game Name</Table.HeaderCell>
        <Table.Cell dataKey="inGameName" className='view-rewards-value' />
      </Table.Column>
      <Table.Column width={150}>
        <Table.HeaderCell>Team Name</Table.HeaderCell>
        <Table.Cell dataKey="teamName" className='view-rewards-value' />
      </Table.Column>
      <Table.Column width={120}>
        <Table.HeaderCell>Amount</Table.HeaderCell>
        <Table.Cell dataKey="amount" className='view-rewards-value' />
      </Table.Column>
      <Table.Column width={100}>
        <Table.HeaderCell>Currency</Table.HeaderCell>
        <Table.Cell dataKey="currency" className='view-rewards-value' />
      </Table.Column>
    </Table>
  )
}
