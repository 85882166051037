import { ShortsComments } from 'core/models/Shorts';
import { useEffect, useRef, useState } from 'react';
import { Button, Icon, Input } from 'rsuite';
import SendButton from 'shared/icons/SendButton';
import ShortCommentCard from './ShortCommentCard';
import CommentMention from 'components/CommentMention';
import { SpaceUser } from 'core/models/Space';

interface ShortCommentSecProps {
  comments: ShortsComments[];
  onComment(data: any): void;
  onPageChange(): void;
  count: number;
  onDeleteComment(commentId: number): void;
}

export default function ShortCommentSec({ comments, onComment, onPageChange, count, onDeleteComment }: ShortCommentSecProps) {
  const [commentDetail, setCommentDetail] = useState<ShortsComments | null>(null)
  const [commentInput, setCommentInput] = useState('')
  const [mentionUser, setMentionUser] = useState<SpaceUser | null>(null)
  const commentTextAreaRef = useRef<HTMLTextAreaElement>(null)


  useEffect(() => {
    if (mentionUser) {
      commentTextAreaRef.current?.focus()
    }
  }, [mentionUser])

  function handleChangeComment(value: any) {
    setCommentInput(value)
  }

  function handleCreateComment() {
    let params: any = {}
    let comment = commentInput.trim()
    params = { comment }
    setCommentInput('')
    if (mentionUser) {
      params = { ...params, commentId: commentDetail?.id }
      setMentionUser(null)
    }
    onComment(params)
  }

  function handleClearMention() {
    setMentionUser(null)
  }


  function handleMentionTags() {
    let mention
    if (commentDetail?.type === 'Space') {
      mention = commentDetail?.space?.name || commentDetail?.space?.userName
    } else {
      mention = mentionUser?.name || mentionUser?.userName
    }
    return mention
  }

  function handleMentionUser(commentUser: SpaceUser, comment: ShortsComments) {
    setCommentDetail(comment)
    setMentionUser(commentUser)
  }

  return (
    <>
      <div className="short-comment-wrapper">
        {
          comments.length ?

            comments.map(
              comment => <ShortCommentCard
                onMention={handleMentionUser}
                onDeleteComment={onDeleteComment}
                comment={comment}
                key={comment.id}
              />
            )
            :
            <div className='no-comments'>
              <Icon icon='comments' size='4x' style={{ color: '#fff' }} />
              <span className='no-comment-text'>No Comments To Display</span>
            </div>
        }
        <div className='comment-view-more-btn'>
          {
            comments.length < count &&
            <Button appearance='ghost' className='comment-view-more' onClick={onPageChange}>View More</Button>
          }
        </div>
      </div >
      <div className='comment-send-container'>
        <div className='mention-user-container'>
          {
            mentionUser &&
            <CommentMention
              onClearMention={handleClearMention}
              onMentionTags={handleMentionTags}
            />
          }
        </div>
        <Input
          placeholder={mentionUser ? `Replying to ${handleMentionTags()}` : 'Type your message'}
          value={commentInput}
          className="comment"
          onChange={handleChangeComment}
          inputRef={commentTextAreaRef}
          onPressEnter={handleCreateComment}
        />
        {
          Boolean(commentInput?.trim()) &&
          <div
            className='send-btn'
            onClick={handleCreateComment}>
            <SendButton fontSize={16} />
          </div>
        }
      </div>
    </>
  )
}
