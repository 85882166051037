export interface Action {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
}

export interface ResetAction extends Action {
  RESET: string;
}

export const LOADER_ACTION = {
  SET: 'SET_LOADER',
  CLEAR: 'CLEAR_LOADER'
}

export const GROUP_LIST_ACTION: Action = {
  FAILURE: 'GROUP_LIST_ACTION_FAILURE',
  REQUEST: 'GROUP_LIST_ACTION_REQUEST',
  SUCCESS: 'GROUP_LIST_ACTION_SUCCESS'
}

export const GROUP_DETAILS_ACTION: Action = {
  FAILURE: 'GROUP_DETAILS_ACTION_FAILURE',
  REQUEST: 'GROUP_DETAILS_ACTION_REQUEST',
  SUCCESS: 'GROUP_DETAILS_ACTION_SUCCESS'
}

export const PLAYOFF_TOURNAMENT_LIST_ACTION: Action = {
  FAILURE: 'PLAYOFF_TOURNAMENT_LIST_ACTION_FAILURE',
  REQUEST: 'PLAYOFF_TOURNAMENT_LIST_ACTION_REQUEST',
  SUCCESS: 'PLAYOFF_TOURNAMENT_LIST_ACTION_SUCCESS'
}

export const PLAYOFF_ROUND_LIST_ACTION: Action = {
  FAILURE: 'PLAYOFF_ROUND_LIST_ACTION_FAILURE',
  REQUEST: 'PLAYOFF_ROUND_LIST_ACTION_REQUEST',
  SUCCESS: 'PLAYOFF_ROUND_LIST_ACTION_SUCCESS'
}

export const ASSIGNED_SPACE_LIST_ACTION: Action = {
  FAILURE: 'ASSIGNED_SPACE_LIST_ACTION_FAILURE',
  REQUEST: 'ASSIGNED_SPACE_LIST_ACTION_REQUEST',
  SUCCESS: 'ASSIGNED_SPACE_LIST_ACTION_SUCCESS'
}

export const ASSIGNED_SPACE_DETAILS_ACTION: Action = {
  FAILURE: 'ASSIGNED_SPACE_DETAILS_ACTION_FAILURE',
  REQUEST: 'ASSIGNED_SPACE_DETAILS_ACTION_REQUEST',
  SUCCESS: 'ASSIGNED_SPACE_DETAILS_ACTION_SUCCESS'
}

export const ASSIGNED_SPACE_POST_LIST_ACTION: Action = {
  FAILURE: 'ASSIGNED_SPACE_POST_LIST_ACTION_FAILURE',
  REQUEST: 'ASSIGNED_SPACE_POST_LIST_ACTION_REQUEST',
  SUCCESS: 'ASSIGNED_SPACE_POST_LIST_ACTION_SUCCESS'
}

export const ASSIGNED_SPACE_POST_DETAILS_ACTION: Action = {
  FAILURE: 'ASSIGNED_SPACE_POST_DETAILS_ACTION_FAILURE',
  REQUEST: 'ASSIGNED_SPACE_POST_DETAILS_ACTION_REQUEST',
  SUCCESS: 'ASSIGNED_SPACE_POST_DETAILS_ACTION_SUCCESS'
}
export const MATCH_SCHEDULE_LIST_ACTION: ResetAction = {
  FAILURE: 'MATCH_SCHEDULE_LIST_ACTION_FAILURE',
  REQUEST: 'MATCH_SCHEDULE_LIST_ACTION_REQUEST',
  SUCCESS: 'MATCH_SCHEDULE_LIST_ACTION_SUCCESS',
  RESET: 'MATCH_SCHEDULE_LIST_ACTION_RESET'
}
export const SHORTS_LIST_ACTION: ResetAction = {
  FAILURE: 'SHORTS_LIST_ACTION_FAILURE',
  REQUEST: 'SHORTS_LIST_ACTION_REQUEST',
  SUCCESS: 'SHORTS_LIST_ACTION_SUCCESS',
  RESET: 'SHORTS_LIST_ACTION_RESET'
}

export const SHORTS_DETAILS_ACTION: Action = {
  FAILURE: 'SHORTS_DETAILS_ACTION_FAILURE',
  REQUEST: 'SHORTS_DETAILS_ACTION_REQUEST',
  SUCCESS: 'SHORTS_DETAILS_ACTION_SUCCESS',
}
export const SHORTS_COMMENTS_ACTION: Action = {
  FAILURE: 'SHORTS_COMMENTS_ACTION_FAILURE',
  REQUEST: 'SHORTS_COMMENTS_ACTION_REQUEST',
  SUCCESS: 'SHORTS_COMMENTS_ACTION_SUCCESS',
}

//Theme Toggle
export const ENABLE_TOGGLE_SWITCH_ACTION: Action = {
  REQUEST: 'ENABLE_TOGGLE_SWITCH_REQUEST',
  FAILURE: 'ENABLE_TOGGLE_SWITCH_FAILURE',
  SUCCESS: 'ENABLE_TOGGLE_SWITCH_SUCCESS',
}
export const CHALLENGE_RESULT_ACTION: ResetAction = {
  FAILURE: 'CHALLENGE_RESULT_ACTION_FAILURE',
  REQUEST: 'CHALLENGE_RESULT_ACTION_REQUEST',
  SUCCESS: 'CHALLENGE_RESULT_ACTION_SUCCESS',
  RESET: 'CHALLENGE_RESULT_ACTION_RESET'
}
