const validate = (value: any) => {
  const errors: any = {}

  if (!value.startTime) {
    errors.startTime = "Start time is required"
  }
  return errors

}

export { validate }
