import history from 'App/History';
import { Space } from 'core/models/Space';
import { numberFormatter } from 'core/utils/NumberHelper';
import { useEffect, useState } from 'react';
import ArrowInsideCircle from 'shared/icons/ArrowInsideCircle';
import Members from 'shared/icons/Members';

interface SpaceCardProps {
  space: Space;
}

export default function SpaceCard({ space }: SpaceCardProps) {
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])

  function handleOpenDetails() {
    history.push(`/space/${space.id}`)
  }
  return (
    <div className="space-card-container" onClick={handleOpenDetails}>
      <img src={space.media.banner} className='space-img' alt='space-banner' />
      <div className='space-card-detail'>
        <div className='space-card-title'>
          {space.name}
        </div>
        <div className='members-container'>
          <Members fontSize={14} />
          <div className='members'>{numberFormatter(space.count, 1)} Members</div>
        </div>
        <ArrowInsideCircle fontSize={isMobile ? 24 : 32} />
      </div>
    </div>

  )
}
