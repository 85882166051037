import { Table } from "rsuite";
import StatusCell from "./StatusCell";
import TimeCell from "./TimeCell";
import { ScheduledGroupWiseMatches } from "core/models/Group";

interface GroupWiseScheduleTableProps {
  matchDetails?: ScheduledGroupWiseMatches[];
  onHandleTime(data: any): void;
}

export default function GroupWiseTable({ matchDetails, onHandleTime }: GroupWiseScheduleTableProps) {
  return (
    <Table data={matchDetails} className='group-wise-table' height={350} hover={false} >
      <Table.Column flexGrow={1} >
        <Table.HeaderCell >Match Sequence</Table.HeaderCell >
        <Table.Cell style={{ background: 'none' }} dataKey="matchSequence" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Status</Table.HeaderCell>
        <StatusCell dataKey='isCompleted' />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <Table.HeaderCell>Time</Table.HeaderCell>
        <TimeCell onClick={(rowData) => onHandleTime({ ...rowData, ...matchDetails })} rowData={matchDetails} />
      </Table.Column>
    </Table >
  )
}