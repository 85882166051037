import { TypedThunk, TypedDispatch } from 'App/Store'
import { SHORTS_DETAILS_ACTION } from 'core/constants/Actions'
import { getShortsDetail } from 'core/services/Short'


export function getShortsDetails(spaceId: string | number, shortsId: string | number): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: SHORTS_DETAILS_ACTION.REQUEST })
    const apiResponse = await getShortsDetail(spaceId, shortsId)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: SHORTS_DETAILS_ACTION.SUCCESS,
        payload: { spaceShort: apiResponse.data.short }
      })
    } else {
      dispatch({
        type: SHORTS_DETAILS_ACTION.FAILURE
      })
    }
  }
}
