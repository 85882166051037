import { Field, InjectedFormProps, reduxForm } from "redux-form"
import { Form, Button } from "rsuite"
import dayjs from 'dayjs'
import DatePicker from 'shared/components/redux-form/DatePicker'
import { validate } from './validate'
import { useState } from 'react'
import { manualScheduleGroupWise } from 'core/services/Groups'
import { useNotification } from "shared/hooks/useNotification"
import ConfirmationPop from "./Confirmation"

interface ManualScheduleProps {
  open?: boolean;
  onClose(): void;
  isMobile: boolean;
  groupId: string;
  matchSequence: number;
}

interface FormData {
  startTime: Date;
}

type FinalProps = InjectedFormProps<FormData, ManualScheduleProps> & ManualScheduleProps

function ManualSchedule({
  handleSubmit,
  onClose,
  matchSequence,
  groupId
}: FinalProps) {

  const showNotification = useNotification()
  const [disclaimer, setDisclaimer] = useState(false)

  function formatDate(date: Date) {
    if (!date) {
      return null
    }
    return date
  }

  function scheduleAllPlayoff(formData: FormData) {
    manualScheduleGroupWise({ groupId: groupId, matchSequence: matchSequence, scheduledTime: formData.startTime })
      .then((res) => {
        if (res.status) {
          showNotification({
            message: 'Playoffs are Scheduled Successfully',
            title: 'Success',
            type: 'success'
          })
          onClose()
        } else {
          showNotification({
            message: res.message || 'Something went wrong',
            title: 'Error',
            type: 'error'
          })
        }
      })
  }


  return (
    <>
      {
        !disclaimer ?
          <ConfirmationPop
            onAccept={() => setDisclaimer(true)}
            onClose={onClose}
          />
          :
          <Form fluid onSubmit={handleSubmit(scheduleAllPlayoff)} style={{ height: '35rem' }}>
            <Field
              name="startTime"
              dateFormat="DD-MM-YYYY HH:mm"
              min={dayjs().subtract(1, 'day').toDate()}
              component={DatePicker}
              format={formatDate}
              placeholder="Select Start Time"
              label="Start Time"
            />
            <Button size='xs' id='manual-schedule-btn' appearance="primary" type="submit">Schedule</Button>
          </Form>
      }
    </>
  )
}

export default reduxForm<FormData, ManualScheduleProps>({
  form: 'manual-schedule',
  validate,
  shouldValidate: () => true
})(ManualSchedule)