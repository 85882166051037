import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'redux/action/loaderAction';
import { Button, Input, SelectPicker } from 'rsuite'
import { useState } from 'react';
import UploadWithPreview from 'shared/components/UploadWithPreview';
import { convertFileToBase64 } from 'core/utils/Image';
import { maxLength } from 'core/utils/Normalizer';
import { useNotification } from 'shared/hooks/useNotification';

interface CreatePostProps {
  onCreatePost(postData: any): void;
}

export default function CreatePost({ onCreatePost }: CreatePostProps) {
  const dispatch = useDispatch()
  const [postImage, setPostImage] = useState('')
  const [postText, setPostText] = useState('')
  const [visibility, setVisibility] = useState('All')
  const [imageFile, setImageFile] = useState<File | null>(null)
  const showNotification = useNotification()

  function handleForm() {
    if (postText.trim() || imageFile) {
      const formdata: any = {}
      if (postText) {
        formdata.postText = postText.trim()
      }
      if (postImage) {
        formdata.file = imageFile
      }
      formdata.visibility = visibility

      setVisibility('All')
      setPostImage('')
      setPostText('')
      setImageFile(null)
      onCreatePost(formdata);
    } else {
      showNotification({
        message: 'Either upload post image or enter post text to create post',
        title: 'Error',
        type: 'error'
      })
    }
  }

  async function handleUpload(files: File[]) {
    dispatch(showLoader())
    const file = files[0]
    const fileUrl = await convertFileToBase64(file)
    setImageFile(file)
    setPostImage(fileUrl)
    dispatch(hideLoader())
  }

  function handlePostTextChange(text: string) {
    let msg = maxLength(text, 600)
    setPostText(msg)
  }

  return (
    <>
      <label className='create-post-label'>Caption</label>
      <Input placeholder="Write Something..."
        value={postText}
        className='input'
        onChange={handlePostTextChange}
      />
      <label className='create-post-label'>Visibility Type</label>
      <div className='select-container'>
        <SelectPicker
          data={[{ label: "All", value: 'All' }, { label: "Member Only", value: 'Member Only' }]}
          searchable={false}
          onChange={(value) => setVisibility(value)}
          value={visibility}
          cleanable={false}
          style={{ width: '100%' }}
        />
      </div>
      <div className='upload'>
        <UploadWithPreview
          onFileChange={handleUpload}
          onRemove={() => setPostImage('')}
          label="Post Image"
          placeholder="Upload post image"
          accept="image/*"
          value={postImage}
        />
      </div>
      <div className='create-post-btn-container'>
        <Button appearance="primary" id='create-post' onClick={handleForm}>Create Post</Button>
      </div>
    </>
  )
}
