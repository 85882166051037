import { Panel, Button, Icon } from 'rsuite'
import logo from 'assets/icons/logo-dark.png'
import { CONFIG } from 'core/config'
import { useEffect } from 'react'
import { isLoggedIn } from 'core/services/Auth'
import history from 'App/History'

export default function Login() {

  function handleGoogleLogin() {
    const gauthUrl = new URL(CONFIG.gauthOAuth)
    gauthUrl.searchParams.append('client_id', CONFIG.gauthClientId)
    gauthUrl.searchParams.append('redirect_uri', `${window.location.origin}${CONFIG.redirectUrl}`)
    gauthUrl.searchParams.append('response_type', 'code')
    gauthUrl.searchParams.append('scope', 'email profile openid')
    gauthUrl.searchParams.append('state', 'google')
    window.location.href = gauthUrl.toString()
  }

  useEffect(() => {
    if (isLoggedIn()) {
      history.replace('/playoff/tournaments')
    }
  }, [])

  return (
    <div className="login-container">
      <Panel shaded >
        <div className="logo-wrapper">
          <img src={logo} alt="BattleXo Logo" />
        </div>
        <div className="signin-conatiner">
          <Button id='login' appearance="primary" onClick={handleGoogleLogin}><Icon icon="google" style={{ marginRight: '1rem' }} /> Sign in with Google</Button>
        </div>
      </Panel>
    </div>
  )
}
