import { useEffect, useRef, useState } from 'react'
import { Modal, Form, Button, Message, Drawer } from 'rsuite'
import { FieldArray, reduxForm, InjectedFormProps, getFormValues } from 'redux-form'
import TeamResult from 'shared/components/TeamResult'
import { FormConfig } from 'core/models/FormConfig'
import { Team } from 'core/models/GroupDetails'
import { SelectOptions } from 'core/models/Settings'
import { mergeJSON } from 'core/utils/DataHelper'
import PromptModal from 'shared/components/PromptModal'
import { useTypedSelector } from 'App/Store'

interface AddResultsProps {
  onClose(): void;
  show: boolean;
  playerFormConfig: FormConfig[];
  teamFormConfig: FormConfig[];
  placementPoints: SelectOptions[];
  teams: Team[];
  onSendResults(data: any): void;
}

type FinalProps = InjectedFormProps<{}, AddResultsProps> & AddResultsProps

function AddResults({
  onClose,
  show,
  handleSubmit,
  initialize,
  teamFormConfig,
  playerFormConfig,
  teams,
  invalid,
  submitFailed,
  onSendResults,
  placementPoints
}: FinalProps) {
  const [formError, setFormError] = useState(false)
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)
  const [openPrompt, setOpenPrompt] = useState(false)
  const defaultValues = useRef<any>({})
  const formValues = useTypedSelector(state => getFormValues('add-results')(state))

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])

  function handleForm(formData: any) {
    setOpenPrompt(true)
  }

  function onAcceptPrompt() {
    onSendResults(mergeJSON(formValues, defaultValues.current))
  }

  function onRejectPrompt() {
    setOpenPrompt(false)
  }

  useEffect(() => {
    const results: any[] = []
    const defaults: any[] = []
    teams.forEach(team => {
      results.push({
        teamId: team.id,
        ...(Boolean(playerFormConfig.length) && { playerDetails: team.user_teams.map(member => ({ userId: member.userId })) })
      })
    })
    const teamDefaults: any = {}
    if (teamFormConfig.length) {
      teamFormConfig.forEach(config => {
        teamDefaults[config.key] = 0
      })
    }
    const playerDefaults: any = {}
    if (playerFormConfig.length) {
      playerFormConfig.forEach(config => {
        playerDefaults[config.key] = 0
      })
    }
    results.forEach(result => {
      let teamConfig: any = { teamId: result.teamId }
      if (teamFormConfig.length) {
        teamConfig = { ...teamConfig, stats: teamDefaults }
      }
      if (playerFormConfig.length) {
        teamConfig = {
          ...teamConfig,
          playerDetails: result.playerDetails.map((member: any) => ({
            userId: member.userId,
            stats: playerDefaults
          }))
        }
      }
      defaults.push(teamConfig)
    })
    defaultValues.current = { results: defaults }
    initialize({ results })
  }, [initialize, playerFormConfig, playerFormConfig.length, teamFormConfig, teams])

  useEffect(() => {
    if (submitFailed) {
      if (invalid) {
        setFormError(true)
      } else {
        setFormError(false)
      }
    }
  }, [invalid, submitFailed])

  return (
    <>
      {
        isMobile
          ?
          <Drawer size="md" onHide={onClose} show={show} placement="bottom" style={{ height: 'var(--app-height)' }}  >
            <Drawer.Header closeButton>
              <Drawer.Title>
                Add Results
              </Drawer.Title>
            </Drawer.Header>
            {
              formError &&
              <Message type="error" description="Fill all required field before submitting the results" />
            }
            <Form fluid onSubmit={handleSubmit(handleForm)}>
              <Drawer.Body>
                <FieldArray name="results" props={{ teams, playerFormConfig, teamFormConfig, placementPoints }} component={TeamResult} />
              </Drawer.Body>
              <Drawer.Footer>
                <Button appearance="primary" id='add-result' type="submit">Submit Results</Button>
              </Drawer.Footer>
            </Form>
          </Drawer>
          :
          <Modal backdrop="static" onHide={onClose} show={show} size="lg">
            <Modal.Header>
              <Modal.Title>Add Results</Modal.Title>
            </Modal.Header>
            {
              formError &&
              <Message type="error" description="Fill all required field before submitting the results" />
            }
            <Form fluid onSubmit={handleSubmit(handleForm)}>
              <Modal.Body >
                <FieldArray name="results" props={{ teams, playerFormConfig, teamFormConfig, placementPoints }} component={TeamResult} />
              </Modal.Body>
              <Modal.Footer style={{ marginTop: "1.4rem" }}>
                <Button appearance="primary" id='add-result-submit' type="submit">Submit Results</Button>
              </Modal.Footer>
            </Form>
          </Modal>
      }
      <PromptModal
        message="Are you sure you want to submit the results?"
        onAccept={onAcceptPrompt}
        onReject={onRejectPrompt}
        open={openPrompt}
      />
    </>

  )
}

export default reduxForm<{}, AddResultsProps>({ form: 'add-results' })(AddResults)