import { ASSIGNED_SPACE_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Space } from 'core/models/Space';

const initialState: { assignedSpaces: Space[], isLoading: boolean, currentPage: number, count: number } = {
  assignedSpaces: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    assignedSpaces: Space[];
    count: number;
    currentPage: number;
  }
}

export default function assignedSpacesReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case ASSIGNED_SPACE_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case ASSIGNED_SPACE_LIST_ACTION.SUCCESS:
      const { assignedSpaces, count, currentPage } = action.payload;
      return { ...state, assignedSpaces, count, currentPage, isLoading: false }

    case ASSIGNED_SPACE_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}