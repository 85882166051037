import { PrizeConfig } from "core/models/Tournament"

interface ValidatesProps {
  prizeConfig: PrizeConfig[];
}

const validate = (values: any, props: ValidatesProps) => {
  const { prizeConfig } = props
  const errors: any = { rewards: {} }
  if (values.rewards) {
    const titles = Object.keys(values.rewards)
    prizeConfig.forEach(conf => {
      if (!titles.includes(conf.title)) {
        errors.rewards[conf.title] = 'Select Team for This Title'
      }
    })
  } else {
    prizeConfig.forEach(conf => {
      errors.rewards[conf.title] = 'Select Team for This Title'
    })
  }

  return errors
}

export { validate }