
import clsx from 'clsx'
import { ReactionCounts } from 'core/models/Space';
import { numberFormatter } from 'core/utils/NumberHelper';
import AngryFaceEmoji from 'shared/icons/AngryFaceEmoji';
import FireGlow from 'shared/icons/FireGlow';
import GoggleFaceOPEmoji from 'shared/icons/GoggleFaceOPEmoji';
import SadEmoji from 'shared/icons/SadEmoji';
import SupportEmoji from 'shared/icons/SupportEmoji';
import WowEmoji from 'shared/icons/WowEmoji';


interface ReactionPromptProps {
  className?: string;
  reactionCount: ReactionCounts;
}

export default function ReactionPrompt({ reactionCount, className }: ReactionPromptProps) {
  return (
    <div className={clsx('reaction-container', className)}>
      <div className='reaction-count-wrapper'>
        <FireGlow fontSize={20} />
        <div className='count-text'>{numberFormatter(reactionCount?.fireReactionCount, 1)}</div>
      </div>
      <div className='reaction-count-wrapper'>
        <SupportEmoji fontSize={20} />
        <div className='count-text'>{numberFormatter(reactionCount?.supportReactionCount, 1)}</div>
      </div>
      <div className='reaction-count-wrapper'>
        <GoggleFaceOPEmoji fontSize={20} />
        <div className='count-text'>{numberFormatter(reactionCount?.opReactionCount, 1)}</div>
      </div>
      <div className='reaction-count-wrapper'>
        <AngryFaceEmoji fontSize={20} />
        <div className='count-text'>{numberFormatter(reactionCount?.angryReactionCount, 1)}</div>
      </div>
      <div className='reaction-count-wrapper'>
        <SadEmoji fontSize={20} />
        <div className='count-text'>{numberFormatter(reactionCount?.sadReactionCount, 1)}</div>
      </div>
      <div className='reaction-count-wrapper'>
        <WowEmoji fontSize={20} />
        <div className='count-text'>{numberFormatter(reactionCount?.wowReactionCount, 1)}</div>
      </div>
    </div>
  )
}
