import clsx from 'clsx';
import { Group } from 'core/models/Group'
import Team from 'shared/icons/Team';
import history from 'App/History'
import Types from 'shared/icons/Types';

export interface PlayoffCardProps {
  group: Group;
}

export default function PlayoffCard({ group }: PlayoffCardProps) {

  function openDetails() {
    history.push(`/playoff/tournaments/details/${group.groupId}`)
  }

  return (
    <div className={clsx("playoff-card-wrapper")} onClick={openDetails}>
      <div className={clsx("playoff-img-wrapper ")}>
        <img src={group.tournamentImage} alt="TournamentImage" className='playoff-img' />
      </div>
      <div className='playoff-detail'>
        <div className='round-name'>{group.groupName}</div>
        <div className='playoff-name'>
          {group.moderatorEmail}
        </div>
        <div className='team-types'>
          <div className='team-types-detail'>
            <div className='team-types-icon'>
              <Team className='icon' />
              <div className='team-types-text'>Teams</div>
            </div>
            <div className='team-types-text'>
              {group.teams}
            </div>
          </div>
          <div className='team-types-detail'>
            <div className='team-types-icon'>
              <Types className='icon' />
              <div className='team-types-text'>Types</div>
            </div>
            <div className='team-types-text'>
              {group.roundType}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
