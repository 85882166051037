import { useEffect, useState } from 'react'
import { Row, Col, Button } from 'rsuite'
import { useTypedDispatch, useTypedSelector } from 'App/Store'
import { hideLoader, showLoader } from 'redux/action/loaderAction'
import TournamentCard from 'components/TournamentCard'
import { playoffTournamentList } from 'redux/action/playoffTournamentAction'
import { Tournament } from 'core/models/Tournament'
import history from 'App/History'
import { useQuery } from 'shared/hooks/useQuery'
import Pagination from 'components/Pagination'
import NoTournament from 'shared/icons/NoTournament'

export default function PlayoffTournamentPage() {

  const playoffTournamentsDetails = useTypedSelector(state => state.playoffTournaments)
  const { playoffTournaments, isLoading, count, currentPage } = playoffTournamentsDetails
  const dispatch = useTypedDispatch()
  const query = useQuery()
  const [status, setStatus] = useState<"live" | "completed">()


  useEffect(() => {
    let page = 0;
    if (query.get("page") && !isNaN(Number(query.get("page")))) {
      page = Number(query.get("page"));
    }
    let status: "live" | "completed"
    const params: any = { page, size: 8, tournamentType: 'tournament' }
    if (query.get('resultStatus')) {
      status = query.get("resultStatus") as "live" | "completed"
      setStatus(status)
      params.resultStatus = status
    }
    dispatch(playoffTournamentList(params));
  }, [dispatch, query])


  useEffect(() => {
    if (isLoading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [isLoading, dispatch])

  function handleCardClick(tournament: Tournament) {
    history.push(`/playoff/tournaments/rounds/${tournament.id}`)
  }

  function handlePagination(pageNumber: number) {
    const page = pageNumber - 1;
    const type = query.get("resultStatus") as "live" | "completed"
    if (type) {
      history.push(`/playoff/tournaments?page=${page}&resultStatus=${type}`)
    } else {
      history.push(`/playoff/tournaments?page=${page}`)
    }
  }

  function handleType(type: "live" | "completed") {
    history.push(`/playoff/tournaments?page=0&resultStatus=${type}`)
    setStatus(type)
  }

  return (
    <div className='space-list-page'>
      <div className='playoff-container'>
        <div className='playoff-title-button-container'>
          <div className='playoff-title'>
            Playoffs
          </div>
          {
            !!playoffTournaments.length &&
            <div>
              <Button id='live' appearance='primary' onClick={() => handleType("live")} className='playoff-button'>Live</Button>
              <Button id='completed' appearance='primary' onClick={() => handleType("completed")}>Completed</Button>
            </div>
          }
        </div>
        <div className='mv-playoff-title'>
          Playoffs
        </div>
        {
          !!playoffTournaments.length &&
          <div className='tournament-status-button'>
            <div className='tournament-status-text'>
              {status === 'live' ? 'Live' : 'Completed'}
            </div>
            {
              status === 'live'
                ?
                <div className='tournament-status-text-inactive' onClick={() => handleType("completed")}>
                  Completed
                </div>
                :
                <div className='tournament-status-text-inactive' onClick={() => handleType("live")}>
                  Live
                </div>
            }
          </div>
        }
      </div >
      {
        playoffTournaments.length ?
          <>
            <Row gutter={24}>
              {
                playoffTournaments.map(
                  tournament =>
                    <Col key={tournament.id} xs={48} sm={12} md={12} lg={6} style={{ padding: "0px", marginBottom: '2.4rem' }} >
                      <TournamentCard
                        onClick={handleCardClick}
                        tournament={tournament} />
                    </Col>
                )
              }
            </Row>
            {
              count > 8 &&
              <div className="pagination">
                <Pagination
                  pages={Math.ceil(count / 8)}
                  limit={10}
                  activePage={currentPage + 1}
                  maxButtons={6}
                  onPageChange={handlePagination}
                />
              </div>
            }
          </>
          :
          <div className='dummy-container'>
            <div className='dummy'>
              <NoTournament fontSize='200' />
              <span className='dummy-text'>No Playoff Available!!</span>
              <span className='dummy-content'>
                Playoff haven&apos;t been assigned to you.
              </span>
            </div>
          </div>
      }
    </div>
  )
}
