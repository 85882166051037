import { ReactNode } from 'react'
import { Nav } from 'rsuite'

export interface TabPanelProps {
  tabTitle?: ReactNode;
  key?: string;
  icon?: any;
  tabContent?: any;
}

export interface TabsProps extends TabPanelProps {
  tabs: TabPanelProps[];
  appearance?: 'default' | 'tabs' | 'subtle';
  onSelect?(key: string): void;
  active: string;
  className?: string;
}

export default function Tabs({ tabs = [], appearance, onSelect, active, className, ...rest }: TabsProps) {
  return (
    <Nav {...rest} appearance={appearance} onSelect={onSelect} activeKey={active} className={className} >
      {
        tabs.map((tab, index) =>
          <Nav.Item icon={tab.icon} eventKey={tab.key || index} >
            {tab.tabTitle}
          </Nav.Item>)
      }
    </Nav>
  )
}
