import { FC } from 'react'
import { FormGroup, InputGroup, Input, Icon, FormControl, ControlLabel } from 'rsuite'
import { IconNames } from 'rsuite/lib/Icon'

interface ComponentProps {
  icon?: IconNames;
  placeholder: string;
  type: string;
  label?: string;
  disabled?: boolean;
  limit?: number;
  value?: any;
  onChange?(value: any): void;
  rows?: number;
}

const CustomInput: FC<ComponentProps> = ({ placeholder, type, disabled, limit, onChange, value, rows }) => {

  function handleInputChange(inputValue: string) {
    if (onChange) {
      onChange(inputValue.substring(0, limit))
    }
  }

  return (
    <>
      <Input
        onChange={handleInputChange}
        placeholder={placeholder}
        type={type}
        autoComplete="off"
        disabled={disabled}
        value={value}
        componentClass="textarea"
        rows={rows}
        style={{ resize: 'none' }}
      />
      <div style={{ width: '100%', textAlign: 'right', paddingRight: 5, color: '#575757' }}>{value.length}/{limit}</div>
    </>
  )
}


const InputWithLimit: FC<ComponentProps> = (props: ComponentProps) => {
  const { placeholder = "Input", icon, type = "text", label, disabled, limit = 100, onChange, value, rows = 3 } = props
  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      <InputGroup inside style={{ width: '100%' }}>
        <FormControl
          accepter={CustomInput}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          onChange={onChange}
          limit={limit}
          value={value}
          rows={rows}
        />
        {
          icon &&
          <InputGroup.Addon>
            <Icon icon={icon} />
          </InputGroup.Addon>
        }
      </InputGroup>
    </FormGroup>
  )
}

export default InputWithLimit