import { IconTypes } from "./Icons";

export default function PlayOff({ color = '#AAAAB0', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <rect width="32" height="32" rx="12" fill="#272c35" className="fill-path" />
      <path className='stroke-fill' fillRule="evenodd" clipRule="evenodd" d="M9.6001 12.0004C9.6001 11.1167 10.3164 10.4004 11.2001 10.4004H20.8002C21.6838 10.4004 22.4002 11.1167 22.4002 12.0004V13.6004C22.4002 14.484 21.6838 15.2004 20.8002 15.2004H11.2001C10.3164 15.2004 9.6001 14.484 9.6001 13.6004V12.0004ZM20.8002 12.8004C20.8002 13.2422 20.442 13.6004 20.0002 13.6004C19.5583 13.6004 19.2001 13.2422 19.2001 12.8004C19.2001 12.3586 19.5583 12.0004 20.0002 12.0004C20.442 12.0004 20.8002 12.3586 20.8002 12.8004Z" fill={color} />
      <path className='stroke-fill' fillRule="evenodd" clipRule="evenodd" d="M9.6001 18.4004C9.6001 17.5167 10.3164 16.8004 11.2001 16.8004H20.8002C21.6838 16.8004 22.4002 17.5167 22.4002 18.4004V20.0004C22.4002 20.884 21.6838 21.6004 20.8002 21.6004H11.2001C10.3164 21.6004 9.6001 20.884 9.6001 20.0004V18.4004ZM20.8002 19.2004C20.8002 19.6422 20.442 20.0004 20.0002 20.0004C19.5583 20.0004 19.2001 19.6422 19.2001 19.2004C19.2001 18.7586 19.5583 18.4004 20.0002 18.4004C20.442 18.4004 20.8002 18.7586 20.8002 19.2004Z" fill={color} />
    </svg>
  )
}