import { MATCH_SCHEDULE_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { ScheduledMatches } from 'core/models/Group';

const initialState: { scheduledMatches: ScheduledMatches[], roundName: string, isLoading: boolean } = {
  scheduledMatches: [],
  roundName: '',
  isLoading: false,
}

interface ActionType extends Action {
  payload: {
    scheduledMatches: ScheduledMatches[];
    roundName: string
  }
}

export default function matchScheduleListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case MATCH_SCHEDULE_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case MATCH_SCHEDULE_LIST_ACTION.SUCCESS:
      const { scheduledMatches, roundName } = action.payload;
      return { ...state, scheduledMatches, roundName, isLoading: false }

    case MATCH_SCHEDULE_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    case MATCH_SCHEDULE_LIST_ACTION.RESET:
      return initialState

    default:
      return state
  }
}