export function onlyNumber(value: string, min: number = 0, max: number = Infinity) {
  if (!value) return value
  const number = Number(value.replace(/[^\d]/g, ''))
  if (number < min) {
    return `${min}`
  }
  if (number > max) {
    return `${max}`
  }
  return `${number}`
}

export function maxLength(value: string, max = 10) {
  if (!value) {
    return value
  }
  if (value.length > max) {
    return value.slice(0, max)
  }
  return value
}
