import { IconTypes } from "./Icons";

export default function Types({ color = '#62676B', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path d="M10.6 12.8665L7.33332 9.59981V4.58315L6.06666 5.84981C5.94444 5.97204 5.79155 6.03315 5.60799 6.03315C5.42444 6.03315 5.26621 5.96648 5.13332 5.83315C4.99999 5.69981 4.93333 5.54137 4.93333 5.35781C4.93333 5.17426 4.99999 5.01604 5.13332 4.88315L7.53332 2.48315C7.59999 2.41648 7.67221 2.36915 7.74999 2.34115C7.82777 2.31315 7.9111 2.29937 7.99999 2.29982C8.08888 2.29982 8.17221 2.31359 8.24999 2.34115C8.32777 2.3687 8.39999 2.41604 8.46666 2.48315L10.8667 4.88315C10.9889 5.00537 11.0529 5.15804 11.0587 5.34115C11.0644 5.52426 11.0004 5.6827 10.8667 5.81648C10.7333 5.94982 10.5749 6.01648 10.3913 6.01648C10.2078 6.01648 10.0495 5.94982 9.91666 5.81648L8.66666 4.58315V9.06648L11.55 11.9498C11.6722 12.072 11.7333 12.222 11.7333 12.3998C11.7333 12.5776 11.6667 12.7331 11.5333 12.8665C11.4111 12.9887 11.2555 13.0498 11.0667 13.0498C10.8778 13.0498 10.7222 12.9887 10.6 12.8665ZM4.48332 12.8831C4.34999 12.7498 4.28333 12.5914 4.28333 12.4078C4.28333 12.2243 4.34444 12.0716 4.46666 11.9498L6.13332 10.2831L7.06666 11.2331L5.41666 12.8831C5.28333 13.0165 5.12488 13.0805 4.94133 13.0751C4.75777 13.0698 4.6051 13.0058 4.48332 12.8831Z" fill="white" />
    </svg>
  )
}