import history from 'App/History';
import { useTypedDispatch, useTypedSelector } from 'App/Store';
import CreatePost from 'components/CreatePost';
import Pagination from 'components/Pagination';
import PostCard from 'components/PostCard'
import SpaceDetailHeader from 'components/SpaceDetailHeader';
import SpaceFilter from 'components/SpaceFilter';
import Tabs, { TabPanelProps } from 'components/Tabs';
import { DEFAULT_API_ERROR } from 'core/constants/Defaults';
import { createSpacePost, updateSpacePostStatus } from 'core/services/Space';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { hideLoader, showLoader } from 'redux/action/loaderAction';
import { getAssigneSpaceDetails } from 'redux/action/spaceDetailsAction';
import { getSpacePostList } from 'redux/action/spacePostsAction';
import { Col, Drawer, Icon, Modal, Row } from 'rsuite'
import { useFirstRender } from 'shared/hooks/useFirstRender';
import { useNotification } from 'shared/hooks/useNotification';
import { useQuery } from 'shared/hooks/useQuery';
import FilterIcon from 'shared/icons/Filtericon';

interface UrlParams {
  spaceId: string;
}

const TABS: TabPanelProps[] = [
  {
    tabTitle: 'Spaces',
    key: 'space',
  },
  {
    tabTitle: 'Members',
    key: 'member',
  }
]

export default function SpaceDetail() {

  const [postOpen, setPostOpen] = useState(false)
  const spaceDetails = useTypedSelector(state => state.space)
  const { spacePosts, isLoading: isPostLoading, count, currentPage } = useTypedSelector(state => state.spacePosts)
  const { space, isLoading } = spaceDetails
  const showNotification = useNotification()
  const dispatch = useTypedDispatch()
  const urlParams = useParams<UrlParams>()
  const [postStatus, setPostStatus] = useState('all')
  const [postType, setPostType] = useState('space')
  const query = useQuery()
  const firstRender = useFirstRender()
  const [filterOpen, setFilterOpen] = useState(false)
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)


  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])

  useEffect(() => {
    dispatch(getAssigneSpaceDetails(urlParams.spaceId))
  }, [dispatch, urlParams.spaceId])


  useEffect(() => {
    if (firstRender) {
      let status: string = query.get('status') || 'all'
      let type: string = query.get('type') || 'space'
      setPostStatus(status)
      setPostType(type)
    }
  }, [firstRender, query, postType])

  useEffect(() => {
    let page: number = 0
    let status: string = query.get('status') || 'all'
    let type: string = query.get('type') || 'space'
    if (query.get('page') && !isNaN(Number(query.get('page')))) {
      page = Number(query.get('page'))
    }
    dispatch(getSpacePostList(urlParams.spaceId, { size: 8, page, type, status }))
  }, [dispatch, query, urlParams.spaceId, postType])

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [isLoading, dispatch])

  useEffect(() => {
    if (isPostLoading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [isPostLoading, dispatch])

  function handleCreatePostOpen() {
    setPostOpen(true)
  }

  function handleUpdateStatus(postData: any) {
    dispatch(showLoader())
    updateSpacePostStatus(postData)
      .then(updateRes => {
        if (updateRes.status) {
          showNotification({
            message: 'Space Post Updated Successfully',
            title: 'Success',
            type: 'success'
          })
          dispatch(getSpacePostList(urlParams.spaceId, { type: postType, status: postStatus, page: 0, size: 8 }))
        } else {
          showNotification({
            message: updateRes.message || DEFAULT_API_ERROR,
            title: 'Error',
            type: 'error'
          })
        }
        dispatch(hideLoader())
      })
  }

  async function handleCreatePost(postData: any) {
    dispatch(showLoader())
    const formData = new FormData()
    formData.append('spaceId', urlParams.spaceId)
    Object.entries(postData).forEach(([key, value]: any) => {
      formData.append(key, value)
    })
    const res = await createSpacePost(formData)
    if (res.status) {
      showNotification({
        message: "Post created Successfully",
        title: "Success",
        type: "success",
      });
      setPostOpen(false)
      dispatch(getSpacePostList(urlParams.spaceId))
    } else {
      showNotification({
        message: res.message || DEFAULT_API_ERROR,
        title: "Error",
        type: "error",
      });
    }
    dispatch(hideLoader())
  }



  const handleApplyFilter = useCallback(() => {
    history.push(`/space/${urlParams.spaceId}?page=0&type=${postType}&status=${postStatus}`)
    setFilterOpen(false)
  }, [postStatus, postType, urlParams.spaceId])

  function handlePageChange(pageNumber: number) {
    history.push(`/space/${space?.id}?page=${pageNumber - 1}&status=${postStatus}&type=${postType}`)
  }

  function handleSelect(key: 'member' | 'space') {
    setPostType(key);
  }

  useEffect(() => {
    handleApplyFilter()
  }, [handleApplyFilter])

  function handleFilterOpen() {
    setFilterOpen(true)
  }

  function handleFilterClear() {
    setPostStatus('all')
    handleApplyFilter()
    setFilterOpen(false)
  }

  return (
    <div className='space-detail-page'>
      <SpaceDetailHeader
        space={space}
        handleCreatePostOpen={handleCreatePostOpen}
      />
      <div className='post-heading-filter'>
        <div className='posts'>POSTS</div>
        <div className='filter-button-container' onClick={handleFilterOpen}>
          <div className='filter-text'>Filter</div>
          <FilterIcon color="#ffffff" />
        </div>
      </div>
      <Tabs
        className='tabs-wrapper'
        tabs={TABS}
        appearance='subtle'
        active={postType}
        onSelect={handleSelect} />
      <div className='post-card-grid' >
        {
          postType === 'space'
          &&
          <div className='mv-create-post' onClick={handleCreatePostOpen}>
            <Icon icon={'plus'} style={{ color: '#414B82' }} />
            <div className='create-post-text'>Create Post</div>
          </div>
        }
        <Row gutter={32} >
          {
            spacePosts.length ?
              spacePosts.map(post =>
                <Col key={post.id} md={6} sm={12} style={{ marginBottom: "2.4rem" }}>
                  <PostCard
                    post={post}
                    onUpdateStatus={handleUpdateStatus} />
                </Col>
              )
              :
              <div className='no-post-text'>
                <span>No posts found</span>
              </div>
          }
        </Row>
        {
          count > 8 &&
          <div className="pagination">
            <Pagination
              pages={Math.ceil(count / 8)}
              maxButtons={6}
              limit={10}
              activePage={currentPage + 1}
              onPageChange={handlePageChange}
            />
          </div>
        }
      </div>
      {
        isMobile
          ?
          <Drawer
            show={postOpen}
            onHide={() => setPostOpen(false)}
            placement="bottom"
            style={{ height: "48rem" }}>
            <Drawer.Header>
              <Drawer.Title>Create Post</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
              <CreatePost onCreatePost={handleCreatePost}
              />
            </Drawer.Body>
          </Drawer>
          :
          <Modal show={postOpen} onHide={() => setPostOpen(false)}>
            <Modal.Header>
              <Modal.Title>Create Post</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CreatePost onCreatePost={handleCreatePost}
              />
            </Modal.Body>
          </Modal>
      }
      {isMobile ?
        <Drawer
          show={filterOpen}
          onHide={() => setFilterOpen(false)}
          placement="bottom"
          style={{ height: "38rem" }} >
          <Drawer.Body>
            <SpaceFilter
              onApplyFilter={handleApplyFilter}
              onPostStatusChange={setPostStatus}
              postStatus={postStatus}
              handleFilterClear={handleFilterClear}
            />
          </Drawer.Body>
        </Drawer>
        :
        <Modal
          show={filterOpen}
          onHide={() => setFilterOpen(false)}
          size='xs'>
          <Modal.Body>
            <SpaceFilter
              onApplyFilter={handleApplyFilter}
              onPostStatusChange={setPostStatus}
              postStatus={postStatus}
              handleFilterClear={handleFilterClear}
            />
          </Modal.Body>
        </Modal>
      }
      <div className='float-filter-button' onClick={() => setFilterOpen(true)}>
        <FilterIcon color="#ffffff" fontSize={24} />
      </div>
    </div>
  )
}
