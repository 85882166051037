import { TypedDispatch, TypedThunk } from 'App/Store'
import { ASSIGNED_SPACE_LIST_ACTION } from 'core/constants/Actions'
import { getAssignedSpaces } from 'core/services/Space'

export function getAssignedSpaceList(params: any): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: ASSIGNED_SPACE_LIST_ACTION.REQUEST })
    const apiResponse = await getAssignedSpaces(params)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: ASSIGNED_SPACE_LIST_ACTION.SUCCESS,
        payload: { assignedSpaces: apiResponse.data.spaces, count: apiResponse.data.count, currentPage: params.page }
      })
    } else {
      dispatch({
        type: ASSIGNED_SPACE_LIST_ACTION.FAILURE
      })
    }
  }
}