import { IconTypes } from "./Icons";

export default function RivalryZone({ color = '#AAAAB0', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <rect x="1" width="32" height="32" rx="12" fill="#272c35" className="fill-path" />
      <path className='stroke-fill' d="M15.4686 21.1807L15.1152 21.5341L13.7598 20.1787L13.4652 19.8841L13.1705 20.1787L11.5791 21.7701L11.2257 21.4166L12.8172 19.8246L13.1117 19.5299L12.8171 19.2353L11.4618 17.8806L11.8153 17.5271L12.4633 18.1747L12.758 18.4693L13.0526 18.1745L13.7002 17.5264L15.4674 19.2947L14.8198 19.9428L14.5253 20.2374L14.8199 20.532L15.4686 21.1807ZM22.7753 21.4166L22.4219 21.7701L20.8305 20.1787L20.5359 19.8841L20.2412 20.1787L18.8859 21.5341L18.5323 21.1806L19.1806 20.5319L19.475 20.2372L19.1805 19.9427L11.419 12.1813L11.4175 10.417L13.1917 10.4185L20.9479 18.1753L21.2424 18.4698L21.5371 18.1754L22.1858 17.5272L22.5393 17.8807L21.1845 19.2361L20.8901 19.5307L21.1845 19.8252L22.7753 21.4166ZM18.5318 12.6953L20.8109 10.4168L22.5841 10.4183L22.5851 12.1781L20.2999 14.4628L18.5318 12.6953Z" fill={color} stroke={color} strokeWidth="0.833333" />
    </svg>
  )
}