import { NavLink } from 'react-router-dom'
import { Sidebar as SideBar, Nav, Sidenav } from 'rsuite'
import PlayOff from 'shared/icons/PlayOff'
import RivalryZone from 'shared/icons/RivalryZone'
import Spaces from 'shared/icons/Spaces'
import VersusMode from 'shared/icons/VersusMode'

export default function Sidebar() {

  return (
    <SideBar width={249} className="app-sidebar" >
      <Sidenav defaultOpenKeys={['3']} appearance="subtle">
        <Sidenav.Header className='sidebar-menu-style'>MENU</Sidenav.Header>
        <Sidenav.Body className='sidebar-menu-item'>
          <Nav className='nav-item-wrapper'>
            <Nav.Item
              componentClass={NavLink}
              activeClassName="active"
              to="/playoff/tournaments"
              icon={<PlayOff fontSize={32} className='nav-icon' />}
            >
              <p className="nav-content">
                Playoffs
              </p>
            </Nav.Item>
            <Nav.Item
              componentClass={NavLink}
              activeClassName="active"
              to="/challenges"
              icon={<VersusMode fontSize={32} className='nav-icon' />}
            >
              <p className="nav-content">
                Challenge
              </p>
            </Nav.Item>
            <Nav.Item
              componentClass={NavLink}
              activeClassName="active"
              to="/space"
              icon={<Spaces fontSize={32} className='nav-icon' />}
            >
              <p className="nav-content">
                Spaces
              </p>
            </Nav.Item>
            <Nav.Item
              componentClass={NavLink}
              activeClassName="active"
              to="/rivalryZone"
              icon={<RivalryZone fontSize={32} className='nav-icon' />}
            >
              <p className="nav-content">
                Rivalry Zone
              </p>
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </SideBar >
  )
}