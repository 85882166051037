import { createRef, FC } from 'react'
import { FormGroup, InputGroup, DatePicker as DatePickerInput, FormControl, ControlLabel } from 'rsuite'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

interface Meta {
  touched: boolean;
  error: string;
}

type CustomDate = string | Date | undefined

interface ComponentProps {
  input: any;
  placeholder: string;
  meta?: Meta;
  label?: string;
  disabled?: boolean;
  dateFormat?: string;
  min?: CustomDate;
  max?: CustomDate;
  cleanable?: boolean;
  containerRef: any;
}


const disableDates = (min: CustomDate, max: CustomDate, date: Date | undefined): boolean => {
  if (min && max) {
    if (dayjs(date).isSameOrAfter(min) && dayjs(date).isSameOrBefore(max)) {
      return false
    }
    return true
  } else {
    if (max) {
      if (dayjs(date).isSameOrBefore(max)) {
        return false
      }
      return true
    }
    if (min) {
      if (dayjs(date).isSameOrAfter(min)) {
        return false
      }
      return true
    }
    return false
  }
}

const CustomInput: FC<ComponentProps> = ({ placeholder, input, disabled, dateFormat, max, min, cleanable, containerRef }) => (
  <DatePickerInput
    block
    oneTap
    disabledDate={date => disableDates(min, max, date)}
    ranges={[]}
    cleanable={cleanable}
    placeholder={placeholder}
    {...input}
    disabled={disabled}
    format={dateFormat}
    placement='bottomEnd'
    container={() => containerRef.current}
  />
)

const DatePicker: FC<ComponentProps> = (props: ComponentProps) => {
  const { placeholder = "Input", meta, input, label, disabled, dateFormat = "DD/MM/YYYY", max, min, cleanable } = props
  const divRef = createRef<HTMLDivElement>();
  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      <div style={{ position: 'relative' }} ref={divRef}>
        <InputGroup inside style={{ width: '100%' }}>
          <FormControl
            accepter={CustomInput}
            placeholder={placeholder}
            input={input}
            disabled={disabled}
            dateFormat={dateFormat}
            errorMessage={meta?.touched && meta.error}
            min={min}
            max={max}
            cleanable={cleanable}
            containerRef={divRef}
          />
        </InputGroup>
      </div>
    </FormGroup>
  )
}

export default DatePicker
