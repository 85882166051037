import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export function getDate(date: string, format = 'D MMM YYYY') {
  if (!date) return date
  return dayjs(date).format(format)
}

export function getTime(date: string, format = 'H:m IST') {
  if (!date) return date
  return dayjs(date).format(format)
}

export function isExpired(date: string) {
  if (!date) return true
  return dayjs().isAfter(date)
}

export function timeElapsed(date: string) {
  if (!date) return date
  return dayjs(date).fromNow()
}