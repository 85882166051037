import ReactionPrompt from 'components/ReactionPrompt';
import ShortsPlayer from 'components/ShortsPlayer';
import { Shorts } from 'core/models/Shorts';
import { timeElapsed } from 'core/utils/Date';
import { numberFormatter } from "core/utils/NumberHelper";
import { Icon } from 'rsuite';
import Comment from 'shared/icons/Comment';
import HappyPlusEmoji from 'shared/icons/HappyPlusEmoji';

interface ShortsDetailSecProps {
  short: Shorts;
}

export default function ShortsDetailSec({ short }: ShortsDetailSecProps) {


  function handleLikeTag() {
    return (
      <div className="like-comment">
        <HappyPlusEmoji fontSize={20} />
        <span className='like-comment-text' >{numberFormatter(short?.reactionCounts?.total, 1)}</span>
      </div>
    )
  }

  function handleCommentTag() {
    return (
      <div className="like-comment" >
        <Comment fontSize={20} />
        <span className='like-comment-text'>{numberFormatter(short?.commentCount, 1)}</span>
      </div>
    )
  }

  function getPostIcon() {
    let icon = <Icon icon="user" className='icon' />
    if (short?.type === 'space' && short?.space?.media && short?.space?.media?.icon) {
      icon = <img src={short?.space?.media?.icon} alt="Space Icon" className='space-short-detail-icon' />
    } else if (short?.space?.media?.icon && short?.space?.media?.banner) {
      icon = <img src={short?.space?.media?.icon} alt="User Icon" className='space-post-detail-icon' />
    }
    return icon
  }


  return (
    <div className="short-detail-wrapper">
      <div className='icon-name-time'>
        {getPostIcon()}
        <div className='name-time'>
          <div className="user-name">{short?.space?.name || short?.space?.userName}</div>
          <div className="publish-time">{timeElapsed(short?.publishedAt)}</div>
        </div>
      </div>
      <ShortsPlayer shorts={short} />
      <div>
        <div className="like-comment-wrapper">
          <div>
            {handleLikeTag()}
          </div>
          <div style={{ marginLeft: 16 }} >{handleCommentTag()}</div>
        </div>
        <ReactionPrompt reactionCount={short?.reactionCounts} />
        <div className="short-caption">
          {short?.caption}
        </div>
      </div>
    </div>
  )
}
