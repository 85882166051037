import { IconTypes } from './Icons'

export default function AngryFaceEmoji({ fontSize = '1.6rem', className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize }}
      className={className}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M20 10C20 15.5228 15.5228 20 10 20C4.47778 20 0 15.5228 0 10C0 4.47778 4.47778 0 10 0C15.5228 0 20 4.47778 20 10Z" fill="#DA2F47" />
      <path d="M14.1582 16.599C14.1332 16.4996 13.5093 14.1662 9.99985 14.1662C6.48985 14.1662 5.86652 16.4996 5.84152 16.599C5.81096 16.7196 5.86541 16.8446 5.97319 16.9068C6.08152 16.9673 6.21707 16.9507 6.30652 16.864C6.31707 16.8535 7.39207 15.8329 9.99985 15.8329C12.6076 15.8329 13.6832 16.8535 13.6932 16.8635C13.7465 16.9162 13.8176 16.944 13.8887 16.944C13.9354 16.944 13.9826 16.9323 14.0254 16.9085C14.1343 16.8462 14.1887 16.7201 14.1582 16.599ZM8.72596 9.60679C6.91763 7.79845 4.01152 7.77734 3.88874 7.77734C3.58207 7.77734 3.33374 8.02568 3.33374 8.33179C3.33319 8.63845 3.58152 8.88734 3.88819 8.88845C3.9043 8.88845 4.95763 8.90068 6.10096 9.2979C5.77152 9.65345 5.55541 10.2057 5.55541 10.8329C5.55541 11.9073 6.17707 12.7773 6.9443 12.7773C7.71152 12.7773 8.33319 11.9073 8.33319 10.8329C8.33319 10.7362 8.32263 10.644 8.31263 10.5512C8.31985 10.5512 8.32652 10.5551 8.33319 10.5551C8.47541 10.5551 8.61763 10.5007 8.72596 10.3923C8.94318 10.1751 8.94318 9.82401 8.72596 9.60679ZM16.111 7.77734C15.9882 7.77734 13.0826 7.79845 11.2737 9.60679C11.0565 9.82401 11.0565 10.1751 11.2737 10.3923C11.3821 10.5007 11.5243 10.5551 11.6665 10.5551C11.6737 10.5551 11.6799 10.5512 11.6865 10.5512C11.6776 10.644 11.6665 10.7362 11.6665 10.8329C11.6665 11.9073 12.2882 12.7773 13.0554 12.7773C13.8226 12.7773 14.4443 11.9073 14.4443 10.8329C14.4443 10.2057 14.2282 9.65345 13.8987 9.2979C15.0421 8.90068 16.0954 8.88845 16.1121 8.88845C16.4182 8.88734 16.6665 8.63845 16.666 8.33179C16.6654 8.02568 16.4176 7.77734 16.111 7.77734Z" fill="#292F33" />
    </svg>
  )
}
