import { IconTypes } from './Icons'

export default function Plus({ color = '#999AB1', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M19.68 8.44V12H11.72V20.36H8V12H0V8.44H8V0.119999H11.72V8.44H19.68Z" fill={color} />
    </svg>
  )
}
