import { createRef, FC } from 'react'
import { FormGroup, InputGroup, CheckPicker, FormControl, ControlLabel } from 'rsuite'
import { SelectOptions } from 'core/models/Settings'
import { ItemDataType } from 'rsuite/lib/@types/common';

interface Meta {
  touched: boolean;
  error: string;
}

interface ComponentProps {
  input: any;
  placeholder: string;
  meta?: Meta;
  options: SelectOptions[];
  label?: string;
  searchable: boolean;
  disabled?: boolean;
  cleanable?: boolean;
  groupBy?: string;
  containerRef: any;
}

function handleLabel(data: ItemDataType | ItemDataType[]) {
  if (Array.isArray(data)) {
    if (data.length) {
      return <>{data[0].label}{data.length > 1 && <span className='rs-picker-value-count'>{data.length}</span>}</>
    }
    return null
  }
  return data?.label || null
}

const CustomSelect: FC<ComponentProps> = ({ placeholder, input, options, searchable, disabled, cleanable, groupBy, containerRef }) => (
  <CheckPicker
    searchable={searchable}
    block
    placeholder={placeholder}
    data={options}
    maxHeight={140}
    disabled={disabled}
    cleanable={cleanable}
    groupBy={groupBy}
    renderValue={(_, item) => handleLabel(item)}
    {...input}
    container={() => containerRef.current}
  />
)

const MultiSelect: FC<ComponentProps> = (props: ComponentProps) => {
  const {
    placeholder = "Select",
    meta,
    input,
    label,
    options,
    searchable = false,
    disabled,
    cleanable = false,
    groupBy = ''
  } = props
  const divRef = createRef<HTMLDivElement>();
  return (
    <FormGroup>
      <ControlLabel>{label}</ControlLabel>
      <div style={{ position: 'relative' }} ref={divRef}>
        <InputGroup inside style={{ width: '100%' }}>
          <FormControl
            accepter={CustomSelect}
            placeholder={placeholder}
            input={input}
            options={options}
            disabled={disabled}
            searchable={searchable}
            cleanable={cleanable}
            errorMessage={meta?.touched && meta.error}
            groupBy={groupBy}
            containerRef={divRef}
          />
        </InputGroup>
      </div>
    </FormGroup>
  )
}

export default MultiSelect