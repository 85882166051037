import { CHALLENGES_USER_RESULT_STATUS } from 'core/constants/Defaults';
import { ChallengeResults } from 'core/models/Challenge';
import { GroupDetails } from 'core/models/GroupDetails';
import { SelectOptions } from 'core/models/Settings';
import { useState } from 'react';
import { Field, WrappedFieldArrayProps } from 'redux-form';
import { Button, Dropdown, Icon, Input, Modal } from 'rsuite';
import Select from 'shared/components/redux-form/Select';

interface ChallengeResultProps {
  challengeResults: ChallengeResults[];
  placementPoints: SelectOptions[];
  onOpenImage(image: string): void;
  onStatusUpdate(data: any): void;
  groupDetails: GroupDetails[];
}

type AllProps = WrappedFieldArrayProps<ChallengeResultProps> & ChallengeResultProps

export default function ChallengeResult({ challengeResults, placementPoints, fields, groupDetails, onOpenImage, onStatusUpdate }: AllProps) {
  const [rejectPlayerOpen, setRejectionModal] = useState(false)
  const [currentPlayerIndex, setCurrentPlayerIndex] = useState<number | null>(null);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [rejectionReason, setRejectionReason] = useState('');
  const [reasonError, setReasonError] = useState('');


  function handleVerification(id: number, status: string) {
    setVerificationStatus(status)
    setCurrentPlayerIndex(id);
    if (status === CHALLENGES_USER_RESULT_STATUS.REJECTED) {
      setRejectionModal(true);
    } else {
      onStatusUpdate({ id, status })
    }
  }

  function handleCloseRejectModal() {
    if (verificationStatus === CHALLENGES_USER_RESULT_STATUS.REJECTED) {
      if (!rejectionReason.trim()) {
        setReasonError('Please Enter the Reason')
      } else {
        setReasonError('')
        setRejectionReason('')
        onStatusUpdate({ id: currentPlayerIndex, status: verificationStatus, reason: rejectionReason })
        setRejectionModal(false);
      }
    }
  }

  function handleReason(reason: string) {
    setRejectionReason(reason.trim())
  }

  return (
    <div key={0}>
      {
        groupDetails[0]?.teams.map((team, index) =>
          <div className='table-data' key={team.id} >
            <span className='table-value' key={team.createdBy}> {team?.name} </span>
            <Field
              name={`${fields.name}[${index}].stats.teamRank`}
              placeholder="Select Player Rank"
              component={Select}
              options={placementPoints}
              className='select'
            />
            {
              challengeResults.filter(result => result.userId === team.createdBy).map(result =>
                <>
                  <div className='screen-shot' onClick={() => onOpenImage(result?.media?.resultImage)}>
                    <img src={result?.media?.resultImage} alt="TournamentImage" className='image' />
                  </div>
                  <Dropdown
                    renderTitle={() => <Button appearance='default'>{result.status} <Icon icon="arrow-down-line" /></Button>}
                    placement='bottomStart'
                    className='mods-menu'
                  >
                    <Dropdown.Item active onSelect={() => handleVerification(result.id, CHALLENGES_USER_RESULT_STATUS.REJECTED)}>{CHALLENGES_USER_RESULT_STATUS.REJECTED}</Dropdown.Item>
                    <Dropdown.Item id='playoff-add-result' onSelect={() => handleVerification(result.id, CHALLENGES_USER_RESULT_STATUS.ACCEPTED)}>{CHALLENGES_USER_RESULT_STATUS.ACCEPTED}</Dropdown.Item>
                  </Dropdown>
                </>
              )}
          </div>
        )
      }
      <Modal overflow backdrop="static" onHide={() => setRejectionModal(false)} show={rejectPlayerOpen} size='xs'>
        <Modal.Header>
          <Modal.Title>Add Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='rejection-prompt'>
            <Input
              placeholder="Enter rejecting reason"
              className="comment"
              onChange={(value) => handleReason(value.trim())}
            />
            <div>{reasonError}</div>
            <div className='rejection-button-wrapper'>
              <Button onClick={handleCloseRejectModal} className='rejection-button' appearance='primary' type='button'>Add</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div >
  )
}
