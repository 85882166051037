import { TypedDispatch, TypedThunk } from 'App/Store'
import { ASSIGNED_SPACE_DETAILS_ACTION } from 'core/constants/Actions'
import { getSpaceDetails } from 'core/services/Space'

export function getAssigneSpaceDetails(spaceId: string | number): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: ASSIGNED_SPACE_DETAILS_ACTION.REQUEST })
    const apiResponse = await getSpaceDetails(spaceId)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: ASSIGNED_SPACE_DETAILS_ACTION.SUCCESS,
        payload: { space: apiResponse.data.space }
      })
    } else {
      dispatch({
        type: ASSIGNED_SPACE_DETAILS_ACTION.FAILURE
      })
    }
  }
}