import { SpacePost } from "core/models/Space";
import { Divider } from "rsuite";
import PostContent from "./PostContent";
import PostMedia from "./PostMedia";

interface PostDetailProps {
  post: SpacePost;
  onUpdateStatus(postData: any): void;
  onChangeStatus(status: any): void;
  onDeleteComment(commentId: number): void;
  onCreateComment(commentData: any): void;
}

export default function PostDetail({
  post,
  onCreateComment,
  onUpdateStatus,
  onChangeStatus,
  onDeleteComment }: PostDetailProps) {

  return (
    <div className="post-detail-container" >
      <div className="post-left-container">
        <PostMedia post={post} onUpdateStatus={onUpdateStatus} onChangeStatus={onChangeStatus} />
      </div>
      <Divider vertical className="v-divider" />
      <div className="post-right-container">
        <PostContent
          post={post}
          onUpdateStatus={onUpdateStatus}
          onChangeStatus={onChangeStatus}
          onDeleteComment={onDeleteComment}
          onCreateComment={onCreateComment} />
      </div>
    </div>
  )
}
