import { Router as BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import Router from './Router'
import Loader from './Loader'
import store from './Store'
import history from './History'
import 'rsuite/lib/styles/index.less'
import '../scss/app.scss'
import { NotifierProvider } from './Notifier'
import { useCallback, useEffect } from 'react'
import ScrollToTop from './ScrollToTop'

function App() {

  const handleResize = useCallback(() => {
    document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
  }, [])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
  }, [handleResize])

  return (
    <Provider store={store}>
      <NotifierProvider>
        <BrowserRouter history={history}>
          <Loader />
          <ScrollToTop>
            <Router />
          </ScrollToTop>
        </BrowserRouter>
      </NotifierProvider>
    </Provider>
  )
}

export default App
