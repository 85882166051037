import ReplyCommentCard from 'components/ReplyCommentCard';
import { ShortsComments } from 'core/models/Shorts';
import { SpaceUser } from 'core/models/Space';
import { timeElapsed } from 'core/utils/Date';
import { getShortName } from 'core/utils/StringHelper';
import { useEffect, useState } from 'react';
import { Avatar } from "rsuite";

interface ShortCommentCardProps {
  comment: ShortsComments;
  onMention(commentUser: SpaceUser, comment: ShortsComments): void;
  onDeleteComment(commentId: number): void;
}

export default function ShortCommentCard({ comment, onMention, onDeleteComment }: ShortCommentCardProps) {
  const [replyLength, setReplyLength] = useState(1)
  const [viewReply, setViewReply] = useState(true)

  useEffect(() => {
    if (!viewReply) {
      setReplyLength(Infinity)
    } else {
      setReplyLength(1)
    }
  }, [viewReply])


  function handleDeleteComment() {
    onDeleteComment(comment.id)
  }

  function handleViewComment() {
    setViewReply(!viewReply)
  }

  function handleChangeMention() {
    onMention(comment.user, comment)
  }

  return (
    <>
      <div className="short-comment-card">
        <Avatar circle src={comment?.space?.media?.icon || comment?.space?.media?.banner || comment?.space?.userName || comment?.user?.media?.profileImg} className='comment-icon' >
          {getShortName(comment?.space?.name || comment?.space?.userName || comment?.user?.name || comment?.user?.userName || '')}
        </Avatar>
        <div className='commenter-name'>
          <p>
            <span className='comment-post-by'>
              {comment?.space?.name || comment?.space?.userName || comment?.user?.name || comment?.user?.userName}
            </span>
            <span className='short-comment' >
              &nbsp;{comment?.comment}
            </span>
          </p>
          <div className='comment-options-container'>
            <div className='comment-options'>{timeElapsed(comment?.createdAt || '')}</div>
            <div className='dot' />
            <button id='comment-reply' className='comment-options' onClick={handleChangeMention}>Reply</button>
            {
              comment?.replyCount > 1 &&
              <>
                <div className='dot' />
                <button id='show-reply' className="show-reply" onClick={handleViewComment}>{viewReply ? 'View Replies' : 'Hide Replies'}</button>
              </>
            }
            <div className='dot' />
            <button id='comment-delete' className='comment-options' onClick={handleDeleteComment}>Delete</button>
          </div>
        </div>
      </div>
      <div className="reply-container">
        {
          comment.replies.slice(0, replyLength).map(reply => {
            return <ReplyCommentCard
              key={reply.id}
              shortsReply={reply}
              onDeleteComment={onDeleteComment}
            />
          })
        }
      </div>
    </>
  )
}
