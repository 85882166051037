import { SHORTS_COMMENTS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { ShortsComments } from 'core/models/Shorts';

const initialState: { shortsComments: ShortsComments[], count: number, currentPage: number, isLoading: boolean, acc: boolean } = {
  shortsComments: [],
  isLoading: false,
  count: 0,
  currentPage: 0,
  acc: false
}

interface ActionType extends Action {
  payload: {
    shortsComments: ShortsComments[];
    count: number;
    currentPage: number;
    acc: boolean;
  }
}

export default function shortsCommentReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case SHORTS_COMMENTS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case SHORTS_COMMENTS_ACTION.SUCCESS:
      const { shortsComments, count, currentPage, acc } = action.payload;
      const params = { ...state, shortsComments, isLoading: false, count, currentPage }
      if (acc) {
        params.shortsComments = [...state.shortsComments, ...shortsComments]
      }
      return params

    case SHORTS_COMMENTS_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}