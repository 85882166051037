import { IconTypes } from "./Icons";

export default function FileUpload({ color = '#AAAAB0', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path d="M8 32L8 34C8 37.3137 10.6863 40 14 40L34 40C37.3137 40 40 37.3137 40 34L40 32M32 16L24 8M24 8L16 16M24 8L24 32" stroke="#1E233D" strokeWidth="4" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}