import { IconTypes } from "./Icons";

export default function Team({ color = '#62676B', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path d="M7.20005 4.80039C7.20005 6.12587 6.12553 7.20039 4.80005 7.20039C3.47457 7.20039 2.40005 6.12587 2.40005 4.80039C2.40005 3.47491 3.47457 2.40039 4.80005 2.40039C6.12553 2.40039 7.20005 3.47491 7.20005 4.80039Z" fill="white" />
      <path d="M13.6 4.80039C13.6 6.12587 12.5255 7.20039 11.2 7.20039C9.87457 7.20039 8.80005 6.12587 8.80005 4.80039C8.80005 3.47491 9.87457 2.40039 11.2 2.40039C12.5255 2.40039 13.6 3.47491 13.6 4.80039Z" fill="white" />
      <path d="M10.3434 13.6004C10.3807 13.3391 10.4001 13.072 10.4001 12.8004C10.4001 11.4922 9.95153 10.2889 9.19985 9.33564C9.78825 8.99523 10.4714 8.80039 11.2001 8.80039C13.4092 8.80039 15.2001 10.5913 15.2001 12.8004V13.6004H10.3434Z" fill="white" />
      <path d="M4.80005 8.80039C7.00919 8.80039 8.80005 10.5913 8.80005 12.8004V13.6004H0.800049V12.8004C0.800049 10.5913 2.59091 8.80039 4.80005 8.80039Z" fill="white" />
    </svg>
  )
}