import { TypedDispatch, TypedThunk } from 'App/Store'
import { GROUP_DETAILS_ACTION } from 'core/constants/Actions'
import { getGroupDetails } from 'core/services/Groups'

export function groupDetails(params: any): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: GROUP_DETAILS_ACTION.REQUEST })
    const apiResponse = await getGroupDetails(params)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: GROUP_DETAILS_ACTION.SUCCESS,
        payload: { groupDetails: apiResponse.data.groupDetails }
      })
    } else {
      dispatch({
        type: GROUP_DETAILS_ACTION.FAILURE
      })
    }
  }
}