import CommentMention from 'components/CommentMention';
import { SpacePost, SpacePostComment, SpaceUser } from "core/models/Space";
import { useEffect, useRef, useState } from "react";
import { Icon, Input } from "rsuite";
import SendButton from 'shared/icons/SendButton';
import CommentCard from './CommentCard';

interface PostContentProps {
  post: SpacePost;
  onCreateComment(commentData: any): void;
  onUpdateStatus(postData: any): void;
  onChangeStatus(status: any): void;
  onDeleteComment(commentId: any): void;
}

export default function PostContent({ post, onCreateComment, onDeleteComment }: PostContentProps) {

  const [commentDetail, setCommentDetail] = useState<SpacePostComment | null>(null)
  const [commentInput, setCommentInput] = useState('')
  const [mentionUser, setMentionUser] = useState<SpaceUser | null>(null)
  const commentTextAreaRef = useRef<HTMLTextAreaElement>(null)


  useEffect(() => {
    if (mentionUser) {
      commentTextAreaRef.current?.focus()
    }
  }, [mentionUser])

  function handleChangeComment(value: any) {
    setCommentInput(value)
  }

  function handleMentionUser(commentUser: SpaceUser, comment: SpacePostComment) {
    setMentionUser(commentUser)
    setCommentDetail(comment)
  }

  function handleCreateComment() {
    let params: any = {}
    let comment = commentInput.trim()
    params = { comment }
    setCommentInput('')
    if (mentionUser) {
      params = { ...params, commentId: commentDetail?.id }
      setMentionUser(null)
    }
    onCreateComment(params)
  }

  function handleClearMention() {
    setMentionUser(null)
  }

  function handleMentionTags() {
    let mention
    if (commentDetail?.type === 'space') {
      mention = post?.space.name
    } else {
      mention = mentionUser?.name || mentionUser?.userName
    }
    return mention
  }

  return (
    <>
      <div className="comment-wrapper">
        {post.comments.length ?
          post.comments.map(
            comment => <CommentCard
              key={comment.id}
              post={post}
              comment={comment}
              onDeleteComment={onDeleteComment}
              onMention={handleMentionUser}
            />
          )
          :
          <div className='no-comments'>
            <Icon icon='comments' size='4x' style={{ color: '#fff' }} />
            <span className='no-comment-text'>No Comments To Display</span>
          </div>
        }
      </div>
      <div className='comment-send-container'>
        <div className='mention-user-container'>
          {
            mentionUser &&
            <CommentMention
              onClearMention={handleClearMention}
              onMentionTags={handleMentionTags}
            />
          }
        </div>
        <Input
          placeholder={mentionUser ? `Replying to ${handleMentionTags()}` : 'Type your message'}
          value={commentInput}
          className="comment"
          onChange={handleChangeComment}
          inputRef={commentTextAreaRef}
          onPressEnter={handleCreateComment}
        />
        {
          Boolean(commentInput?.trim()) &&
          <div
            className='send-btn'
            onClick={handleCreateComment}>
            <SendButton fontSize={16} />
          </div>
        }
      </div>
    </>
  )
}
