import { IconTypes } from "./Icons";

export default function Spaces({ color = '#AAAAB0', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <rect width="32" height="32" rx="12" fill="#272c35" className="fill-path" />
      <path className='stroke-fill' d="M9.63353 15.0499L12.4335 12.2499C12.5891 12.0943 12.7724 11.9832 12.9835 11.9166C13.1946 11.8499 13.4113 11.8388 13.6335 11.8832L14.5002 12.0666C13.9002 12.7777 13.428 13.4221 13.0835 13.9999C12.7391 14.5777 12.4058 15.2777 12.0835 16.0999L9.63353 15.0499ZM13.0502 16.5666C13.3058 15.7666 13.6529 15.011 14.0915 14.2999C14.5306 13.5888 15.0613 12.9221 15.6835 12.2999C16.6613 11.3221 17.778 10.5914 19.0335 10.1079C20.2891 9.62478 21.4613 9.47767 22.5502 9.66655C22.7391 10.7554 22.5946 11.9277 22.1169 13.1832C21.6391 14.4388 20.9113 15.5554 19.9335 16.5332C19.3224 17.1443 18.6558 17.675 17.9335 18.1252C17.2113 18.575 16.4502 18.9277 15.6502 19.1832L13.0502 16.5666ZM17.6502 14.5666C17.9058 14.8221 18.2198 14.9499 18.5922 14.9499C18.9642 14.9499 19.278 14.8221 19.5335 14.5666C19.7891 14.311 19.9169 13.997 19.9169 13.6246C19.9169 13.2526 19.7891 12.9388 19.5335 12.6832C19.278 12.4277 18.9642 12.2999 18.5922 12.2999C18.2198 12.2999 17.9058 12.4277 17.6502 12.6832C17.3946 12.9388 17.2669 13.2526 17.2669 13.6246C17.2669 13.997 17.3946 14.311 17.6502 14.5666ZM17.1835 22.5832L16.1169 20.1332C16.9391 19.811 17.642 19.4777 18.2255 19.1332C18.8086 18.7888 19.4558 18.3166 20.1669 17.7166L20.3335 18.5832C20.378 18.8054 20.3669 19.025 20.3002 19.2419C20.2335 19.4583 20.1224 19.6443 19.9669 19.7999L17.1835 22.5832ZM10.7002 18.6999C11.0891 18.311 11.5613 18.1137 12.1169 18.1079C12.6724 18.1026 13.1446 18.2943 13.5335 18.6832C13.9224 19.0721 14.1169 19.5443 14.1169 20.0999C14.1169 20.6554 13.9224 21.1277 13.5335 21.5166C13.2558 21.7943 12.792 22.0332 12.1422 22.2332C11.492 22.4332 10.5946 22.611 9.4502 22.7666C9.60575 21.6221 9.78353 20.7277 9.98353 20.0832C10.1835 19.4388 10.4224 18.9777 10.7002 18.6999Z" fill={color} />
    </svg>
  )
}