import { CommentReply } from 'core/models/Shorts';
import { Comment, SpacePost } from "core/models/Space"
import { timeElapsed } from "core/utils/Date";

interface ReplyCommentCardProps {
  reply?: Comment;
  shortsReply?: CommentReply;
  spacePost?: SpacePost
  onDeleteComment(commentId?: number): void;
  onCommentType?(): void
}

export default function ReplyCommentCard({ reply, spacePost, shortsReply, onDeleteComment }: ReplyCommentCardProps) {

  function getPostIcon() {
    if (reply) {
      if ((reply?.type === 'space' && spacePost?.space?.media && spacePost?.space?.media?.icon)) {
        return <img src={spacePost?.space?.media?.icon || spacePost?.space.media.banner} alt="Space Icon" className='reply-card-icon' />
      } else if (reply?.user?.media && reply?.user?.media?.profileImg) {
        return <img src={reply?.user?.media?.profileImg} alt="User Icon" className='reply-card-icon' />
      }
    }
    else {
      if (shortsReply?.type === 'Space') {
        return <img src={shortsReply?.space?.media?.icon || shortsReply?.space?.media?.banner} alt="Space Icon" className='reply-card-icon' />
      } else if (shortsReply?.type === 'Member') {
        return <img src={shortsReply?.user?.media?.profileImg} alt="User Icon" className='reply-card-icon' />
      }
    }
  }

  function handleDeleteComment() {
    onDeleteComment(reply ? reply?.id : shortsReply?.id)
  }

  return (
    <div className='reply-card-wrapper'>
      {getPostIcon()}
      <div className='reply-card-comment'>
        <p>
          {
            reply
              ?
              <span className="reply-posted-by">{reply?.type === 'space' ? spacePost?.space.name : reply?.user.name || reply?.user.userName}</span>
              :
              <span className="reply-posted-by">{shortsReply?.type === 'Space' ? shortsReply?.space.name || shortsReply?.space.userName : shortsReply?.user.name || shortsReply?.user.userName}</span>
          }
          &nbsp;
          <span className="reply-posted">{reply?.comment || shortsReply?.comment}</span>
        </p>
        <div className='reply-options-container'>
          <div className='reply-options'>{timeElapsed(reply?.createdAt || shortsReply?.createdAt || '')}</div>
          <div className='dot' />
          <button id='reply-delete' className='reply-options' onClick={handleDeleteComment}>Delete</button>
        </div>
      </div>
    </div>
  )
}
