import { Button, Radio, RadioGroup } from 'rsuite';

interface SpaceFilterProps {
  onApplyFilter(): void;
  onPostStatusChange(value: string): void;
  postStatus: string;
  handleFilterClear(): void;
}

export default function SpaceFilter({
  onApplyFilter,
  onPostStatusChange,
  postStatus,
  handleFilterClear,
}: SpaceFilterProps) {

  return (
    <div className='space-filter-container'>
      <div className='filter-clear-btn' onClick={handleFilterClear}>
        <span className='filter-clear-text'>Filters</span>
        <div className='filter-clear-button'>Clear All</div>
      </div>
      <div className='hr-line'></div>
      <div className='post-title-text'>Status</div>
      <RadioGroup inline className='menu' value={postStatus} onChange={(val) => onPostStatusChange(val)}>
        <div className='radio-button-container'>
          <div className='radio-label'>All</div>
          <Radio value="all" />
        </div>
        <div className='radio-button-container' >
          <div className='radio-label'>Live</div>
          <Radio value="live" />
        </div>
        <div className='radio-button-container'>
          <div className='radio-label'>Inactive</div>
          <Radio value="inactive" />
        </div>
      </RadioGroup>
      <div className='apply-btn'>
        <Button onClick={onApplyFilter} appearance='primary' block>Apply</Button>
      </div>
    </div >
  )
}
