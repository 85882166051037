import { TypedDispatch, TypedThunk } from 'App/Store'
import { CHALLENGE_RESULT_ACTION } from 'core/constants/Actions'
import { getChallengeResult } from 'core/services/Challenges'

export function fetchChallengeResult(params: any): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: CHALLENGE_RESULT_ACTION.REQUEST })
    const apiResponse = await getChallengeResult(params)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: CHALLENGE_RESULT_ACTION.SUCCESS,
        payload: { challengeResult: apiResponse.data.tournamentUserResultMedia }
      })
    } else {
      dispatch({
        type: CHALLENGE_RESULT_ACTION.FAILURE
      })
    }
  }
}