import history from 'App/History';
import { Button } from 'rsuite'

interface SpaceDetailHeaderProps {
  handleCreatePostOpen(): void;
  space: any;
}

export default function SpaceDetailHeader({ space, handleCreatePostOpen }: SpaceDetailHeaderProps) {

  function redirectToShorts() {
    history.push(`/space/shorts/${space?.id}`)
  }

  return (
    <>
      <div className='btn'>
        <Button id='create-post-button' appearance='primary' style={{ marginRight: '2.4rem' }} className='dv-create-btn' onClick={handleCreatePostOpen}>Create Post</Button>
        <Button id='create-short-button' className='dv-short-btn' appearance='primary' onClick={redirectToShorts}>View Bytes</Button>
      </div>
      <div>
        <img src={space?.media.banner} alt='Banner' className='space-detail-banner' />
        <div className='space-icon-wrapper'>
          <img src={space?.media.icon} alt='icon' className='space-detail-icon' />
        </div>
        <div className='name-description'>
          <div className='space-detail-name'>{space?.name}</div>
          <p className='space-description'>{space?.description}</p>
        </div>
      </div>
    </>
  )
}
