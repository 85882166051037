import { ReactNode } from 'react'
import { Container, Content } from 'rsuite'
import Sidebar from './Sidebar'
import Topbar from './Topbar'

interface LayoutProps {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps) {
  return (
    <Container className="main-container">
      <Topbar />
      <Container>
        <Sidebar />
        <Content className="main-content">{children}</Content>
      </Container>
    </Container>
  )
}
