import ChallengeResult from 'components/ChallengeResult';
import { ChallengeResults } from 'core/models/Challenge';
import { GroupDetails, Team } from 'core/models/GroupDetails';
import { SelectOptions } from 'core/models/Settings';
import { useEffect } from 'react';
import { FieldArray, InjectedFormProps, reduxForm } from 'redux-form';
import { Button, Form } from 'rsuite';
import { useNotification } from 'shared/hooks/useNotification';

interface ChallengeResultFormProps {
  challengeResults: ChallengeResults[];
  onOpenImage(image: string): void;
  onStatusUpdate(data: any): void;
  addResult(data: any): void;
  placementPoints: SelectOptions[];
  groupDetails: GroupDetails[];
}

type FinalProps = InjectedFormProps<{}, ChallengeResultFormProps> & ChallengeResultFormProps

function ChallengeResultForm({
  challengeResults,
  groupDetails,
  placementPoints,
  addResult,
  onStatusUpdate,
  onOpenImage,
  handleSubmit,
  initialize
}: FinalProps) {
  const showNotification = useNotification()

  useEffect(() => {
    const results: any[] = []
    groupDetails[0]?.teams?.map((team: Team) => {
      results.push({ teamId: team.id })
    })
    initialize({ results })
  }, [groupDetails, initialize])

  function handleResult(formData: any) {
    const { results } = formData
    if (results.every((result: any) => result.hasOwnProperty('stats'))) {
      addResult(formData)
    }
    else {
      showNotification({
        message: 'Please give rank to all teams',
        title: 'Error',
        type: 'error'
      })
    }
  }


  return (
    <>
      <Form onSubmit={handleSubmit(handleResult)}>
        <div className='playoff-btn-container'>
          <div className='playoff-grp-title'>
            <span>Add Results</span>
          </div>
          {!!challengeResults.length && <Button appearance="primary" className='schedule-btn' type="submit">Save</Button>}
        </div>
        <div className='challenge-result-form-container'>
          <div className='table-header'>
            <span className='table-heading'>Team Name</span>
            <span className='table-heading'>Team Rank</span>
            <span className='table-heading' >Verification</span>
            <span className='table-heading'>Action</span>
          </div>
          <FieldArray name="results" props={{ challengeResults, groupDetails, placementPoints, onStatusUpdate, onOpenImage }} component={ChallengeResult} />
        </div>
      </Form >
    </>
  )
}

export default reduxForm<{}, ChallengeResultFormProps>({ form: 'challenge-result-form' })(ChallengeResultForm)