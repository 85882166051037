import { Field, InjectedFormProps, reduxForm } from "redux-form"
import { Form, Button } from "rsuite"
import dayjs from 'dayjs'
import InputWithLabel from "shared/components/redux-form/InputWithLabel"
import DatePicker from 'shared/components/redux-form/DatePicker'
import { onlyNumber } from "core/utils/Normalizer"
import { validate } from './validate'
import { Group } from "core/models/Group"
import { useEffect, useState } from 'react'
import { autoScheduleRoundWise, getPlayoffModerators } from 'core/services/Groups'
import { SelectOptions } from 'core/models/Settings'
import MultiSelect from 'shared/components/redux-form/MultiSelect'
import { useNotification } from "shared/hooks/useNotification"

interface AutoScheduleProps {
  group: Group | null;
  isSuperModerator?: boolean;
  onClose(): void;
}

interface FormData {
  startTime: string;
  matchesPerDay: number;
  matchDuration: number;
  moderators?: SelectOptions;
}

type FinalProps = InjectedFormProps<FormData, AutoScheduleProps> & AutoScheduleProps

function AutoSchedule({
  isSuperModerator,
  group,
  handleSubmit,
  onClose
}: FinalProps) {

  const [moderator, setModerator] = useState<SelectOptions[]>([])
  const showNotification = useNotification()

  useEffect(() => {
    async function load() {
      if (isSuperModerator) {
        const moderatorRes = await getPlayoffModerators(group?.tournamentId)
        if (moderatorRes.status && moderatorRes.data) {
          setModerator(moderatorRes.data.moderatorList.map(moderator => ({ value: moderator.value, label: moderator.label })))
        }
      }
    }
    load()
  }, [group?.tournamentId, isSuperModerator])



  function handleOnlyNumberInput(value: string) {
    const normalizedValue = onlyNumber(value)
    let sanitizedNumber = Number(normalizedValue)
    return `${sanitizedNumber || ''}`
  }

  function formatDate(date: Date) {
    if (!date) {
      return null
    }
    return date
  }

  function handleForm(formData: FormData) {
    const formField = { ...formData }
    if (!formField.moderators) {
      delete formField.moderators
    }
    autoScheduleAllPlayoff({ ...formField, roundSequence: group?.roundSequence, tournamentId: group?.tournamentId })
  }

  function autoScheduleAllPlayoff(formData: any) {
    autoScheduleRoundWise(formData)
      .then((res) => {
        if (res.status) {
          showNotification({
            message: 'Playoffs are Scheduled Successfully',
            title: 'Success',
            type: 'success'
          })
          onClose()
        } else {
          showNotification({
            message: 'Failed to Update Scheduled',
            title: 'Error',
            type: 'error'
          })
          onClose()
        }
      })
  }

  return (
    <>
      <Form fluid onSubmit={handleSubmit(handleForm)}>
        <Field
          name="startTime"
          dateFormat="DD-MM-YYYY HH:mm"
          min={dayjs().subtract(1, 'day').toDate()}
          component={DatePicker}
          format={formatDate}
          placeholder="Select Start Time"
          label="Start Time"
        />
        <Field
          name="matchesPerDay"
          component={InputWithLabel}
          label="Number of matches per day"
          placeholder="Enter Number of Matches per day"
          normalize={handleOnlyNumberInput}
        />
        <Field
          name="matchDuration"
          component={InputWithLabel}
          label="Match Duration (in min)"
          placeholder="Enter Duration"
          normalize={handleOnlyNumberInput}
        />
        {
          isSuperModerator &&
          <Field
            name="moderators"
            component={MultiSelect}
            placeholder="Select Moderator"
            label="Moderator"
            options={moderator}
          />
        }
        <Button id='auto-schedule-btn' size='xs' appearance="primary" type="submit">Schedule</Button>
      </Form>
    </>
  )
}

export default reduxForm<FormData, AutoScheduleProps>({
  form: 'auto-schedule-round',
  validate,
  shouldValidate: () => true
})(AutoSchedule)