import { useTypedDispatch, useTypedSelector } from 'App/Store'
import ChallengeCard from 'components/ChallengeCard'
import { useEffect, useState } from 'react'
import { hideLoader, showLoader } from 'redux/action/loaderAction'
import { playoffTournamentList } from 'redux/action/playoffTournamentAction'
import { Button, Col, Row } from 'rsuite'
import { useQuery } from 'shared/hooks/useQuery'
import history from 'App/History'
import Pagination from 'components/Pagination'
import NoTournament from 'shared/icons/NoTournament'

export default function Challenge() {
  const challengeDetails = useTypedSelector(state => state.playoffTournaments)
  const { playoffTournaments, isLoading, count, currentPage } = challengeDetails
  const query = useQuery()
  const dispatch = useTypedDispatch()
  const [status, setStatus] = useState<"live" | "completed">()


  useEffect(() => {
    let page = 0;
    if (query.get("page") && !isNaN(Number(query.get("page")))) {
      page = Number(query.get("page"));
    }
    let status: "live" | "completed"
    const params: any = { page, size: 8, tournamentType: 'Challenges' }
    if (query.get('resultStatus')) {
      status = query.get("resultStatus") as "live" | "completed"
      setStatus(status)
      params.resultStatus = status
    }
    dispatch(playoffTournamentList(params));
  }, [dispatch, query])


  useEffect(() => {
    if (isLoading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [isLoading, dispatch])

  function handlePagination(pageNumber: number) {
    const page = pageNumber - 1;
    const type = query.get("resultStatus") as "live" | "completed"
    if (type) {
      history.push(`/challenges?page=${page}&resultStatus=${type}`)
    } else {
      history.push(`/challenges?page=${page}`)
    }
  }

  function handleType(type: "live" | "completed") {
    history.push(`/challenges?page=0&resultStatus=${type}`)
    setStatus(type)
  }

  return (
    <>
      <div className='playoff-btn-container'>
        <div className='playoff-grp-title'>Challenge</div>
        <div>
          <Button id='live' appearance='primary' style={{ marginRight: '10px' }} size='xs' onClick={() => handleType("live")} className='playoff-button'>Live</Button>
          <Button id='completed' appearance='primary' className='playoff-button' size='xs' onClick={() => handleType("completed")}>Completed</Button>
        </div>
      </div>
      {
        !!playoffTournaments.length
          ?
          <>
            <Row gutter={24}>
              {
                playoffTournaments.map(
                  challenge =>
                    <Col key={challenge.id} xs={24} sm={12} md={10} lg={6} style={{ padding: "0px", marginBottom: '2.4rem' }} >
                      <ChallengeCard
                        challenge={challenge} />
                    </Col>
                )
              }
            </Row>
            {
              count > 8 &&
              <div className="pagination">
                <Pagination
                  pages={Math.ceil(count / 8)}
                  limit={10}
                  activePage={currentPage + 1}
                  maxButtons={6}
                  onPageChange={handlePagination}
                />
              </div>
            }
          </>
          :
          <div className='dummy-container'>
            <div className='dummy'>
              <NoTournament fontSize='200' />
              <span className='dummy-text'>{status === 'completed' ? 'No challenges completed yet.' : `Challenges haven't been assigned to you.`}</span>
            </div>
          </div>
      }
    </>
  )
}
