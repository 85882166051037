import { WrappedFieldArrayProps, Field, FieldArray } from 'redux-form'
import { Tooltip, Whisper, Icon } from 'rsuite'
import { FormConfig } from 'core/models/FormConfig'
import { Team } from 'core/models/GroupDetails'
import { SelectOptions } from 'core/models/Settings'
import { onlyNumber } from 'core/utils/Normalizer'
import MemberResult from '../MemberResult'
import InputWithLabel from '../redux-form/InputWithLabel'
import Select from '../redux-form/Select'
interface Type {
  teams: Team[];
  playerFormConfig: FormConfig[];
  teamFormConfig: FormConfig[];
  placementPoints: SelectOptions[];
}

type AllProps = WrappedFieldArrayProps<Type> & Type

export default function TeamResult({ fields, playerFormConfig, teamFormConfig, teams, placementPoints }: AllProps) {

  function teamDetail(team: Team) {
    const tip = <Tooltip className='tooltip-style'>
      {
        team.user_teams.map(team =>
          <div key={team.id} className='in-game-name'>{`${team.inGameName} <${team.userName}>`}</div>
        )
      }
    </Tooltip>
    return (
      <div className='tooltip-wrapper'>
        <div className='team-name-head'>{team.name}</div>
        <Whisper
          speaker={tip}
          placement="right"
          trigger="hover"
        >
          <Icon icon="info-circle" style={{ color: "#808191" }} />
        </Whisper>
      </div>
    )
  }

  return (
    <div key={0} className='team-result-container'>
      {
        teams.map((team, index) =>
          <div key={team.id} className="team-result-wrapper" >
            {teamDetail(team)}
            <Field
              name={`${fields.name}[${index}].teamId`}
              type="hidden"
              component="input"
              style={{ color: "red" }}
            />
            {
              Boolean(teamFormConfig.length) &&
              teamFormConfig.map(config =>
                <Field
                  key={config.key}
                  normalize={(val: string) => onlyNumber(val)}
                  name={`${fields.name}[${index}].stats.${config.key}`}
                  placeholder={config.placeHolder}
                  component={InputWithLabel}
                />
              )
            }
            {
              Boolean(placementPoints.length) &&
              <Field
                name={`${fields.name}[${index}].stats.teamRank`}
                placeholder="Select Team Rank"
                component={Select}
                options={placementPoints}
              />
            }
            {
              Boolean(playerFormConfig.length) &&
              <FieldArray
                name="playerDetails"
                props={{ members: team.user_teams, playerFormConfig, currentTeamIndex: index }}
                component={MemberResult}
              />
            }
          </div>
        )
      }
    </div>
  )
}