import { GROUP_DETAILS_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { GroupDetails } from 'core/models/GroupDetails';

const initialState: { groupDetails: GroupDetails[], isLoading: boolean } = {
  groupDetails: [],
  isLoading: false
}

interface ActionType extends Action {
  payload: {
    groupDetails: GroupDetails[];
  }
}

export default function groupDetailsReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case GROUP_DETAILS_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case GROUP_DETAILS_ACTION.SUCCESS:
      const { groupDetails } = action.payload;
      return { ...state, groupDetails, isLoading: false }

    case GROUP_DETAILS_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}