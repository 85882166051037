import { TypedDispatch, TypedThunk } from 'App/Store'
import { PLAYOFF_ROUND_LIST_ACTION } from 'core/constants/Actions'
import { getplayoffRounds } from 'core/services/Groups'

export function playoffRoundsList(params: any): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: PLAYOFF_ROUND_LIST_ACTION.REQUEST })
    const apiResponse = await getplayoffRounds(params)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: PLAYOFF_ROUND_LIST_ACTION.SUCCESS,
        payload: {
          playoffRounds: apiResponse.data.playoffRounds,
          rewarded: apiResponse.data.rewarded,
          tournamentCompleted: apiResponse.data.tournamentCompleted,
          winnerTeams: apiResponse.data.winnerTeams,
          prizeConfig: apiResponse.data.prizeConfig
        }
      })
    } else {
      dispatch({
        type: PLAYOFF_ROUND_LIST_ACTION.FAILURE
      })
    }
  }
}