import { useTypedDispatch, useTypedSelector } from 'App/Store';
import ChallengeResultForm from 'components/ChallengeResultForm';
import { useEffect, useState } from 'react';
import { Drawer, Modal } from 'rsuite';
import { useNotification } from 'shared/hooks/useNotification';
import { useParams } from 'react-router-dom';
import { fetchChallengeResult } from 'redux/action/challengeResultAction';
import { hideLoader, showLoader } from 'redux/action/loaderAction';
import { updateChallengeStatus } from 'core/services/Challenges';
import { DEFAULT_API_ERROR } from 'core/constants/Defaults';
import { SelectOptions } from 'core/models/Settings';
import { getPlacementPoints, sendResults } from 'core/services/Groups';
import { convertToRank } from 'core/utils/StringHelper';
import { groupDetails as getGroupDetails } from "redux/action/groupDetailsAction";
import history from 'App/History'

interface UrlParams {
  challengeId: string;
}

export default function ChallengeResult() {
  const challengeResults = useTypedSelector((state) => state.challengeResult)
  const { challengeResult } = challengeResults
  const [screenShot, setScreenShot] = useState('')
  const [viewImageModal, setViewImageModal] = useState(false)
  const showNotification = useNotification()
  const dispatch = useTypedDispatch();
  const urlParams = useParams<UrlParams>();
  const challengeDetail = useTypedSelector((state) => state.groupDetails);
  const { groupDetails } = challengeDetail;
  const [placementPoints, setPlacementPoints] = useState<SelectOptions[]>([]);
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)

  useEffect(() => {
    dispatch(getGroupDetails({ id: urlParams.challengeId }));
  }, [dispatch, urlParams.challengeId]);


  useEffect(() => {
    if (groupDetails[0]) {
      dispatch(fetchChallengeResult({ tournamentId: groupDetails[0].tournamentId }));
      fetchPlacementPoints()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, groupDetails]);

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])

  async function fetchPlacementPoints() {
    const placementRes = await getPlacementPoints(
      {
        id: groupDetails[0].tournamentId,
        round: groupDetails[0].roundSequence
      }
    );
    if (
      placementRes.status &&
      placementRes.data &&
      placementRes.data.placementPoints
    ) {
      setPlacementPoints(
        placementRes.data.placementPoints.map((placement) => {
          if (typeof placement.rank === "number") {
            return {
              label: convertToRank(placement.rank),
              value: placement.rank,
            };
          } else {
            return {
              label: placement.rank,
              value: placement.rank,
            };
          }
        })
      );
    }
  }

  function handleSendResults(results: any) {
    dispatch(showLoader());
    const { tournamentId, groupId, roundSequence, matchSequence } = groupDetails[0];
    sendResults({ ...results, tournamentId, groupId, roundSequence, matchSequence }).then((res) => {
      dispatch(hideLoader());
      if (res.status) {
        showNotification({
          message: "Results Added Successfully",
          title: "Success",
          type: "success",
        });
        history.push(`/challenges/${urlParams.challengeId}`)
      } else {
        showNotification({
          message: res.message || DEFAULT_API_ERROR,
          title: "Error",
          type: "error",
        });
      }
    });
  }

  async function handleVerification(data: any) {
    dispatch(hideLoader());
    const res = await updateChallengeStatus(data);
    if (res.status && res.data) {
      showNotification({
        message: "Verified Successfully",
        title: "Success",
        type: "success",
      });
      dispatch(fetchChallengeResult({ tournamentId: groupDetails[0].tournamentId }));
    } else {
      showNotification({
        message: res.message || DEFAULT_API_ERROR,
        title: "Error",
        type: "error",
      });
      dispatch(hideLoader());
    }
  };

  function handleImageModal(image: string) {
    setViewImageModal(true)
    setScreenShot(image)
  }

  return (
    <>

      <ChallengeResultForm
        addResult={handleSendResults}
        onStatusUpdate={handleVerification}
        challengeResults={challengeResult}
        onOpenImage={handleImageModal}
        placementPoints={placementPoints}
        groupDetails={groupDetails}
      />
      {isMobile ?
        <Drawer onHide={() => setViewImageModal(false)} placement='bottom' show={viewImageModal} style={{ height: 'var(--app-height)' }}>
          <Drawer.Header>
          </Drawer.Header>
          <Drawer.Body>
            <div className='verification-screen-shot'>
              <img src={screenShot} alt="TournamentImage" className='screen-shot' />
            </div>
          </Drawer.Body>
        </Drawer>
        :
        <Modal backdrop="static" onHide={() => setViewImageModal(false)} show={viewImageModal} size={isMobile ? 'xs' : 'sm'} >
          <Modal.Header>
          </Modal.Header>
          <Modal.Body>
            <div className='verification-screen-shot'>
              <img src={screenShot} alt="TournamentImage" className='screen-shot' />
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  )
}
