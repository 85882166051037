import { IconTypes } from "./Icons";

export default function EmptyPostIcon({ color = '#6E6AF0', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{ width: '1em', height: '1em', fontSize, transform: `${rotate}` }}
    >
      <path fillRule="evenodd" opacity='35%' clipRule="evenodd" d="M4 3.5C2.89543 3.5 2 4.39543 2 5.5V15.5C2 16.6046 2.89543 17.5 4 17.5H16C17.1046 17.5 18 16.6046 18 15.5V5.5C18 4.39543 17.1046 3.5 16 3.5H4ZM16 15.5H4L8 7.5L11 13.5L13 9.5L16 15.5Z" fill={color} />
    </svg>

  )
}