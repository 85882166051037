import { IconTypes } from './Icons'

export default function ArrowInsideCircle({ color = '#FFFFFF', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="16" cy="16" r="16" fill="#6E6AF0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.3344 12.2343C18.6468 11.9219 19.1534 11.9219 19.4658 12.2343L22.6658 15.4343C22.9782 15.7467 22.9782 16.2533 22.6658 16.5657L19.4658 19.7657C19.1534 20.0781 18.6468 20.0781 18.3344 19.7657C18.022 19.4533 18.022 18.9467 18.3344 18.6343L20.1687 16.8H10.9001C10.4583 16.8 10.1001 16.4418 10.1001 16C10.1001 15.5582 10.4583 15.2 10.9001 15.2H20.1687L18.3344 13.3657C18.022 13.0533 18.022 12.5467 18.3344 12.2343Z" fill={color} />
    </svg>
  )
}

