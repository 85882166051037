const validate = (value: any) => {
  const errors: any = {}

  if (!value.startTime) {
    errors.startTime = "Start time is required"
  }
  if (!value.matchesPerDay) {
    errors.matchesPerDay = "No of match is required"
  }
  if (!value.matchDuration) {
    errors.matchDuration = "Match duration is required"
  }
  return errors

}

export { validate }