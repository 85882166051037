import { useState, useEffect, FocusEvent } from 'react'
import {
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  List,
  Checkbox,
  DatePicker,
} from 'rsuite'
import { RoomCredential, Team } from 'core/models/GroupDetails'
import InputWithLimit from 'shared/components/InputWithLimit'
import dayjs from 'dayjs'
import { ScheduledGroupWiseMatches } from 'core/models/Group'
import { useNotification } from 'shared/hooks/useNotification'

interface SendCredentialModalProps {
  onSend(data: any, time: any): void;
  teams: Team[];
  credentials?: RoomCredential;
  matchDetails?: ScheduledGroupWiseMatches[];
  matchSequence?: number;
}

export default function SendCredentialModal({ onSend, matchDetails, matchSequence, teams, credentials }: SendCredentialModalProps) {
  const [roomId, setRoomId] = useState('')
  const [roomPassword, setRoomPassword] = useState('')
  const [roomIdError, setRoomIdError] = useState('')
  const [dateError, setDateError] = useState('')
  const [description, setDescription] = useState('')
  const [date, setDate] = useState<Date | undefined>(undefined)
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(undefined)
  const [roomPasswordError, setRoomPasswordError] = useState('')
  const [selectedTeams, setSelectedTeams] = useState<number[]>([])
  const showNotification = useNotification()
  const [matchTime]: any = matchDetails?.filter(eachMatchDetail => eachMatchDetail.matchSequence === matchSequence).map(matchDetail => matchDetail.time) || []

  function handleSendCredential() {
    const formValid = validateForm()
    if (!formValid) {
      setTimeout(() => {
        const errorElem = document.getElementsByClassName('rs-error-message-wrapper')[0]
        if (errorElem) {
          errorElem.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
      }, 0);
    } else {
      const payload: any = { roomDateTime: dayjs(date || '').toISOString(), teams: selectedTeams }
      if (description.trim()) {
        payload.description = description.trim()
      }
      if (roomId.trim()) {
        payload.roomId = roomId.trim()
      }
      if (roomPassword.trim()) {
        payload.roomPassword = roomPassword.trim()
      }

      onSend(payload, matchTime);
    }
  }

  function validateForm() {
    setRoomIdError('')
    setRoomPasswordError('')
    setDateError('')
    let isValid = true

    if (roomId.trim() || roomPassword.trim()) {
      if (!roomId.trim()) {
        setRoomIdError('Room Id Required')
        isValid = false
      }
      if (!roomPassword.trim()) {
        setRoomPasswordError('Room Password Required')
        isValid = false
      }
      if (!date) {
        setDateError('Room Date and time Required')
        isValid = false
      }
    }
    if (!selectedTeams.length && isValid) {
      showNotification({
        title: 'Error',
        message: 'Select at least 1 team to send credentials',
        type: 'error'
      })
      isValid = false
    }
    return isValid
  }

  function handleRoomIDChange(value: string) {
    setRoomId(value)
  }

  function handleRoomPasswordChange(value: string) {
    setRoomPassword(value)
  }

  function handleCheckAll(value: number, checked: boolean) {
    checked
      ?
      setSelectedTeams(teams.map(team => team.id))
      :
      setSelectedTeams([])
  }

  function handleCheck(value: number, checked: boolean) {
    const previousTeams = [...selectedTeams]
    if (checked) {
      previousTeams.push(value)
      setSelectedTeams(previousTeams)
    } else {
      setSelectedTeams(previousTeams.filter(teamId => teamId !== value))
    }
  }

  function handleDescriptionChange(descriptionData: string) {
    setDescription(descriptionData);
  }

  useEffect(() => {
    if (matchTime) {
      setDate(dayjs(matchTime).toDate())
      setSelectedDate(dayjs(matchTime).toDate())
    }
    if (credentials) {
      if (credentials.roomId) {
        setRoomId(credentials.roomId)
      }
      if (credentials.roomPassword) {
        setRoomPassword(credentials.roomPassword)
      }
    }
  }, [credentials, matchTime])

  function handleInputFocus(eve: FocusEvent<HTMLInputElement>) {
    if (eve.target.value) {
      eve.target.select();
    }
  }

  function disabledDates(selectedDate: Date | undefined) {
    if (dayjs(selectedDate).isAfter(dayjs(), 'date') || dayjs(selectedDate).isSame(dayjs(), 'date')) {
      return false
    }
    return true
  }


  function handleDateChange(selectedDate: Date) {
    setDate(selectedDate)
  }

  function handleSelectedDateChange(selectedDate: Date) {
    setSelectedDate(selectedDate)
  }

  function disabledHours(hour: number) {
    if (dayjs().get('date') >= dayjs(selectedDate).get('date')) {
      if (hour < dayjs().get('hour')) {
        return true
      }
    }
    return false
  }

  function disabledMinutes(minutes: number, selectedDate: Date) {
    const hour = dayjs().get('hour')
    if (dayjs().get('date') >= dayjs(selectedDate).get('date')) {
      if (hour === dayjs(selectedDate).get('hour') && minutes < dayjs().get('minute')) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <Form fluid>
        <FormGroup>
          <ControlLabel>Room ID</ControlLabel>
          <FormControl
            placeHolder={credentials?.roomId ? 'Enter room ID' : 'Will be Updated soon'}
            value={roomId}
            errorMessage={roomIdError}
            onChange={handleRoomIDChange}
            onFocus={handleInputFocus}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Room Password</ControlLabel>
          <FormControl
            placeHolder={credentials?.roomPassword ? 'Enter password' : 'Will be Updated soon'}
            value={roomPassword}
            errorMessage={roomPasswordError}
            onChange={handleRoomPasswordChange}
            onFocus={handleInputFocus}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Select Date Time</ControlLabel>
          <FormControl
            placeholder="Date Time"
            disabledDate={disabledDates}
            disabledHours={disabledHours}
            disabledMinutes={disabledMinutes}
            format="DD-MM-YYYY HH:mm"
            onChange={handleDateChange}
            onChangeCalendarDate={handleSelectedDateChange}
            accepter={DatePicker}
            value={date}
            ranges={[]}
            block
            errorMessage={dateError}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>Description</ControlLabel>
          <FormControl
            placeholder="Description"
            type='text'
            onChange={handleDescriptionChange}
            accepter={InputWithLimit}
            value={description}
            block
            limit={150}
            rows={4}
          />
        </FormGroup>
      </Form>
      <div>
        <div className='select-team-wrapper'>
          <h6 style={{ color: '#FFFFFF' }}>Select Teams:</h6>
          <span style={{ display: "flex", alignItems: 'center', color: '#FFFFFF' }}>Select all<Checkbox onChange={handleCheckAll} />
          </span>
        </div>
        <List className='select-team-list'>
          {
            teams.map(
              team =>
                <List.Item key={team.id}>
                  <Checkbox
                    value={team.id}
                    onChange={handleCheck}
                    checked={selectedTeams.includes(team.id)}

                  >
                    <span style={{ color: "#FFFFFF", fontSize: "14px" }}> {team.name}</span>
                  </Checkbox>
                </List.Item>
            )
          }
        </List>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: "2rem" }}>
        <Button id='send-credential-button' onClick={handleSendCredential} appearance="primary">
          Send
        </Button>
      </div>
    </>
  )
}