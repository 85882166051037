import { MouseEvent, useRef, useState } from "react"
import { Shorts } from "core/models/Shorts"
import { Icon } from "rsuite";

interface ShortsPlayerProps {
  shorts: Shorts;
}

export default function ShortsPlayer({ shorts }: ShortsPlayerProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMute, setIsMute] = useState(true);
  const [progress, setProgress] = useState(0);

  function handleTimeUpdate() {
    const video = videoRef.current;
    if (video) {
      const progress = (video.currentTime / video.duration) * 100
      setProgress(progress)
    }
  };

  function handlePlayPause() {
    const video = videoRef.current;
    if (video?.paused) {
      video?.play();
      setIsPlaying(true);
    } else {
      video?.pause();
      setIsPlaying(false);
    }
  };

  function handleSeek(e: MouseEvent<HTMLDivElement>) {
    const video = videoRef.current;
    if (video) {
      const seekPercentage = (e.nativeEvent.offsetX / e.currentTarget.offsetWidth) * 100;
      const seekTime = (seekPercentage / 100) * (video?.duration || 0);
      video.currentTime = seekTime;
    }
  };

  function handleSound() {
    setIsMute(!isMute);
  }

  function handleVideoEnded() {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
    }
    setIsPlaying(false);
  }

  return (
    <div className="shorts-player-wrapper">
      <div className="shorts-video">
        <video
          ref={videoRef}
          src={shorts.video}
          onTimeUpdate={handleTimeUpdate}
          muted={isMute}
          onEnded={handleVideoEnded}
          onClick={handlePlayPause}
          poster={shorts.preview}
        />
        <div className="shorts-control-container">
          <button className="play-control" onClick={handlePlayPause}>
            <Icon style={{ color: '#ffffff' }} icon={isPlaying ? 'pause' : 'play'} />
          </button>
          <div className="seeker-container">
            <div className="seeker" onClick={handleSeek}>
              <div className="seeker-complete" style={{ width: `${progress}%` }} />
            </div>
          </div>
          <button className="sound-control" onClick={handleSound}>
            <Icon style={{ color: '#ffffff' }} icon={isMute ? 'volume-off' : 'volume-up'} />
          </button>
        </div>
      </div>
    </div>
  )
}
