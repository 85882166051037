import { SHORTS_LIST_ACTION } from 'core/constants/Actions'
import { TypedThunk, TypedDispatch } from 'App/Store'
import { getShorts } from 'core/services/Short'


export function shortsList(
  spaceId: string | number,
  params: any = {
    page: 0,
    size: 8
  }
): TypedThunk {
  return async (dispatch: TypedDispatch) => {
    dispatch({ type: SHORTS_LIST_ACTION.REQUEST })
    const apiResponse = await getShorts(spaceId, params)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: SHORTS_LIST_ACTION.SUCCESS,
        payload: { shorts: apiResponse.data.shorts, count: apiResponse.data.count, currentPage: Number(params.page) }
      })
    } else {
      dispatch({
        type: SHORTS_LIST_ACTION.FAILURE
      })
    }
  }
}
export function resetShorts() {
  return {
    type: SHORTS_LIST_ACTION.RESET
  }
}
