import { TypedDispatch, TypedThunk } from 'App/Store'
import { ASSIGNED_SPACE_POST_LIST_ACTION } from 'core/constants/Actions'
import { getSpacePosts } from 'core/services/Space'

export function getSpacePostList(
  spaceId: string | number,
  params: any = {
    page: 0,
    size: 8
  }): TypedThunk {
  return async (dispatch: TypedDispatch): Promise<void> => {
    dispatch({ type: ASSIGNED_SPACE_POST_LIST_ACTION.REQUEST })
    const apiResponse = await getSpacePosts(spaceId, params)
    if (apiResponse.status && apiResponse.data) {
      dispatch({
        type: ASSIGNED_SPACE_POST_LIST_ACTION.SUCCESS,
        payload: { spacePosts: apiResponse.data.spacePosts, count: apiResponse.data.count, currentPage: params.page }
      })
    } else {
      dispatch({
        type: ASSIGNED_SPACE_POST_LIST_ACTION.FAILURE
      })
    }
  }
}