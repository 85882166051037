import { useTypedDispatch, useTypedSelector } from 'App/Store';
import DeleteComment from 'components/DeleteComment';
import ShortCommentSec from 'components/ShortCommentSec';
import ShortsDetailSec from 'components/ShortsDetailSec';
import { DEFAULT_API_ERROR } from 'core/constants/Defaults';
import { createShortsComment, deleteShortsComment } from 'core/services/Short';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { hideLoader, showLoader } from 'redux/action/loaderAction';
import { getShortsCommentsList } from 'redux/action/shortsCommentAction';
import { getShortsDetails } from 'redux/action/shortsDetailAction';
import { Divider } from 'rsuite';
import { useNotification } from 'shared/hooks/useNotification';
interface UrlParams {
  spaceId: string;
  shortsId: string;
}

const PAGE_SIZE = 10

export default function ShortsDetail() {
  const [screenWidth, setScreenWidth] = useState(0)
  const [isMobile, setMobile] = useState(true)
  const { spaceShort } = useTypedSelector(state => state.spaceShort)
  const { shortsComments, count, currentPage } = useTypedSelector(state => state.shortsComments)
  const dispatch = useTypedDispatch();
  const showNotification = useNotification()
  const urlParams = useParams<UrlParams>()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [commentId, setCommentId] = useState(0)

  useEffect(() => {
    let params = { spaceId: urlParams.spaceId, shortsId: urlParams.shortsId, page: 0, size: PAGE_SIZE }
    dispatch(getShortsCommentsList(params))
  }, [dispatch, urlParams.shortsId, urlParams.spaceId])

  useEffect(() => {
    dispatch(getShortsDetails(urlParams.spaceId, urlParams.shortsId))
  }, [dispatch, urlParams.shortsId, urlParams.spaceId])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleResize() {
    setScreenWidth(
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    )
  }

  useEffect(() => {
    if (screenWidth > 540) {
      setMobile(false)
    }
    else setMobile(true)
  }, [screenWidth])

  async function handleComment(commentData: any) {
    dispatch(showLoader)
    const res = await createShortsComment({ spaceId: urlParams.spaceId, shortsId: urlParams.shortsId, ...commentData })
    if (res.status) {
      showNotification({
        message: 'Comment Posted Successfully',
        title: 'Success',
        type: 'success'
      })
      const params = { spaceId: urlParams.spaceId, shortsId: urlParams.shortsId, page: 0, size: PAGE_SIZE }
      dispatch(getShortsCommentsList(params))
      dispatch(getShortsDetails(urlParams.spaceId, urlParams.shortsId))

    } else {
      showNotification({
        message: res.message || DEFAULT_API_ERROR,
        title: 'Error',
        type: 'error'
      })
    }
    dispatch(hideLoader)
  }

  function handlePageChange() {
    const page = currentPage + 1
    dispatch(getShortsCommentsList({ spaceId: urlParams.spaceId, shortsId: urlParams.shortsId, page, size: PAGE_SIZE }, true))
  }

  async function onCommentDelete() {
    dispatch(showLoader())
    const res = await deleteShortsComment(urlParams.spaceId, commentId)
    if (res.status) {
      showNotification(
        {
          message: 'Comment Deleted Successfully',
          type: 'success',
          title: 'success'
        })
      const params = { spaceId: urlParams.spaceId, shortsId: urlParams.shortsId, page: 0, size: PAGE_SIZE }
      dispatch(getShortsCommentsList(params))
      dispatch(getShortsDetails(urlParams.spaceId, urlParams.shortsId))
    }
    else {
      showNotification({
        message: res.message || DEFAULT_API_ERROR,
        title: 'Error',
        type: 'error'
      })
    }
    dispatch(hideLoader())
    setDeleteOpen(false)
  }


  function handleCommentDelete(commentId: number) {
    setCommentId(commentId)
    setDeleteOpen(true)
  }

  return (
    <>
      <div className='short-detail-container'>
        <div className='short-detail'>
          {
            spaceShort &&
            <ShortsDetailSec short={spaceShort} />
          }
        </div>
        <Divider vertical className="v-divider" />
        <div className='short-comment'>
          <ShortCommentSec
            comments={shortsComments}
            count={count} onComment={handleComment}
            onPageChange={handlePageChange}
            onDeleteComment={handleCommentDelete} />
        </div>
      </div>
      <DeleteComment
        onCancel={() => setDeleteOpen(false)}
        deleteComment={onCommentDelete}
        onOpen={deleteOpen}
        isMobile={isMobile}
        onClose={() => setDeleteOpen(false)} />
    </>
  )
}