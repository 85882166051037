import clsx from 'clsx';
import { ScheduledMatches } from 'core/models/Group';
import { getDate } from 'core/utils/Date';

interface MatchScheduleCardProps {
  matchDetail: ScheduledMatches;
  onHandleTime(data: any): void;
}

export default function MatchScheduleCard({ matchDetail, onHandleTime }: MatchScheduleCardProps) {
  return (
    <div id='schedule-match-card' className={clsx('match-schedule-card', matchDetail.isCompleted && 'status', matchDetail.isCompleted && 'card-disable')} onClick={() => onHandleTime(matchDetail)} >
      <div className="key-value">
        <div className='key'>Moderator:</div>
        <div className='value'>{matchDetail.email}</div>
      </div>
      <div className="key-value">
        <div className='key'>Group :</div>
        <div className='value'>{matchDetail.groupName}</div>
      </div>
      <div className="key-value">
        <div className='key'>Match No. :</div>
        <div className='value'>{matchDetail.matchSequence}</div>
      </div>
      <div className="key-value">
        <div className='key'>Status :</div>
        <div className={clsx('value', matchDetail.isCompleted ? 'completed' : 'live')}>{matchDetail.isCompleted ? 'Completed' : 'Live'}</div>
      </div>
      <div className="key-value">
        <div className='key'>Date & Time :</div>
        <div className={clsx('value', 'time')} >{matchDetail?.time ? getDate(matchDetail.time, 'DD-MMM-YYYY,HH:mm') : 'Not Scheduled'}</div>
      </div>
    </div >
  )
}