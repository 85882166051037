import { createContext, FC, useMemo } from "react"
import { Notification } from "../core/models/Notification"
import { Notification as Notifier } from 'rsuite'

export const NotifierContext = createContext(
  {
    showNotification: ({ message, title, type }: Notification) => {
      console.log(`Dummy Notification ${message} ${type} ${title}`)
    }
  }
)

export const NotifierProvider: FC = ({ children }) => {

  const showNotification = ({ message, title, type }: Notification) => {
    Notifier[type]({
      title,
      description: message,
      top: 60,
      duration: 2000
    })
  }

  const value = useMemo(() => (({ showNotification })), [])

  return (
    <NotifierContext.Provider value={value}>
      {children}
    </NotifierContext.Provider>
  )
}