import { IconTypes } from "./Icons";

export default function Groups({ color = '#62676B', fontSize = '1.6rem', rotate = 0, className = '' }: IconTypes) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1em', height: '1em', fontSize, transform: `rotate(${rotate}deg)` }}
      className={className}
    >
      <path d="M10.4 4.80039C10.4 6.12587 9.32553 7.20039 8.00005 7.20039C6.67456 7.20039 5.60005 6.12587 5.60005 4.80039C5.60005 3.47491 6.67456 2.40039 8.00005 2.40039C9.32553 2.40039 10.4 3.47491 10.4 4.80039Z" fill="white" />
      <path d="M14.4 6.40039C14.4 7.28405 13.6837 8.00039 12.8 8.00039C11.9164 8.00039 11.2 7.28405 11.2 6.40039C11.2 5.51674 11.9164 4.80039 12.8 4.80039C13.6837 4.80039 14.4 5.51674 14.4 6.40039Z" fill="white" />
      <path d="M11.2 12.0004C11.2 10.2331 9.76736 8.80039 8.00005 8.80039C6.23274 8.80039 4.80005 10.2331 4.80005 12.0004V14.4004H11.2V12.0004Z" fill="white" />
      <path d="M4.80005 6.40039C4.80005 7.28405 4.0837 8.00039 3.20005 8.00039C2.31639 8.00039 1.60005 7.28405 1.60005 6.40039C1.60005 5.51674 2.31639 4.80039 3.20005 4.80039C4.0837 4.80039 4.80005 5.51674 4.80005 6.40039Z" fill="white" />
      <path d="M12.8 14.4004V12.0004C12.8 11.1571 12.5826 10.3646 12.2006 9.67585C12.3922 9.62659 12.5931 9.60039 12.8 9.60039C14.1255 9.60039 15.2 10.6749 15.2 12.0004V14.4004H12.8Z" fill="white" />
      <path d="M3.79946 9.67585C3.41753 10.3646 3.20005 11.1571 3.20005 12.0004V14.4004H0.800049V12.0004C0.800049 10.6749 1.87457 9.60039 3.20005 9.60039C3.40702 9.60039 3.60786 9.62659 3.79946 9.67585Z" fill="white" />
    </svg>
  )
}