import { Button, Form, FormControl, FormGroup } from 'rsuite'
import { ChangeEvent, createRef, useContext, useState } from 'react';
import { NotifierContext } from 'App/Notifier';
import { maxLength } from 'core/utils/Normalizer';
import Plus from 'shared/icons/Plus';
import InputWithLimit from 'shared/components/InputWithLimit';
import Cross from 'shared/icons/Cross';

interface PostShortsProps {
  onCreatePost(postData: any): void;
  shortsFile: File | null;
  onSelectShortsFile(file: File | null): void;
}

export default function PostShorts({ onCreatePost, shortsFile, onSelectShortsFile }: PostShortsProps) {

  const [caption, setCaption] = useState('')
  const { showNotification } = useContext(NotifierContext)
  const uploadRef = createRef<HTMLInputElement>()


  function handleForm() {
    if (!shortsFile) {
      showNotification({
        message: 'Select Video to Create Bytes',
        title: 'Error',
        type: 'error'
      })
    }
    else {
      onCreatePost({ shortsFile, caption })
    }
  }

  function handleCaptionChange(text: string) {
    let msg = maxLength(text, 150)
    setCaption(msg)
  }

  function onFileSelection(event: ChangeEvent<HTMLInputElement>) {
    const file = Array.from(event.target.files || [])[0]
    if (file) {
      const maxSize = 250 * 1024 * 1024
      if (file.size > maxSize) {
        showNotification({
          type: 'error',
          title: 'error',
          message: 'File size should be less than 250 MB '
        })
      }
      else if (file.type && !file.type.startsWith('video/')) {
        showNotification({
          type: 'error',
          title: 'error',
          message: 'Only Video can be Uploaded',
        })
      }
      else {
        onSelectShortsFile(file)
      }
    }
  }

  function handleRemoveFile() {
    onSelectShortsFile(null)
  }
  return (
    <>
      <Form fluid>
        <FormGroup>
          <FormControl
            placeholder="Write Something..."
            type='text'
            onChange={handleCaptionChange}
            accepter={InputWithLimit}
            value={caption}
            block
            limit={150}
            rows={3}
          />
        </FormGroup>
      </Form>
      <div className='upload-container' onClick={() => uploadRef.current?.click()}>
        <input
          type='file'
          accept='video/*'
          multiple={false}
          ref={uploadRef}
          style={{ display: 'none' }}
          onChange={onFileSelection}
          onClick={event => (event.target as HTMLInputElement).value = ''}
        />
        {
          shortsFile
            ?
            <div className='upload-cross-wrapper'>
              <p className='upload-text'>{shortsFile.name}</p>
              <span onClick={handleRemoveFile}>
                <Cross color='#6E6AF0' fontSize={20} />
              </span>
            </div>
            :
            <div className='upload-wrapper'>
              <Plus color='antiquewhite' />
              <p className='upload-text'>Upload Bytes</p>
            </div>
        }
      </div>
      <div className='create-post-btn-container'>
        <Button appearance="primary" id='create-post' onClick={handleForm}>Upload</Button>
      </div>
    </>
  )
}
