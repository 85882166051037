import history from "App/History";
import { useTypedDispatch, useTypedSelector } from "App/Store";
import Pagination from 'components/Pagination';
import SpaceCard from "components/SpaceCard";
import { useEffect } from "react";
import { getAssignedSpaceList } from "redux/action/assignedSpacesAction";
import { hideLoader, showLoader } from "redux/action/loaderAction";
import { Col, Row } from "rsuite";
import { useQuery } from "shared/hooks/useQuery";
import NoSpace from 'shared/icons/NoSpace';

export default function SpaceList() {

  const { assignedSpaces, isLoading, count, currentPage } = useTypedSelector(state => state.spaces)
  const dispatch = useTypedDispatch()
  const query = useQuery();

  useEffect(() => {
    let page = 0;
    if (query.get("page") && !isNaN(Number(query.get("page")))) {
      page = Number(query.get("page"));
    }
    dispatch(getAssignedSpaceList({ page, size: 8 }))
  }, [dispatch, query])

  useEffect(() => {
    if (isLoading) {
      dispatch(showLoader())
    } else {
      dispatch(hideLoader())
    }
  }, [isLoading, dispatch])

  function handlePageChange(pageNumber: number) {
    const page = pageNumber - 1;
    history.push({ search: `?page=${page}` })
    dispatch(getAssignedSpaceList({ page, size: 8 }))
  }

  return (
    <div className='space-list-page'>
      <div className='space-title'>
        Spaces
      </div>
      {
        assignedSpaces.length
          ?
          <>
            <Row gutter={24}>
              {
                assignedSpaces.map(
                  space =>
                    <Col key={space.id} xs={12} sm={12} md={12} lg={6} style={{ marginBottom: '2.4rem', paddingLeft: "1px", paddingRight: "1px" }} >
                      <SpaceCard space={space} />
                    </Col>
                )
              }
            </Row>
            {
              count > 8 &&
              <div className="pagination">
                <Pagination
                  pages={Math.ceil(count / 8)}
                  limit={10}
                  activePage={currentPage + 1}
                  maxButtons={6}
                  onPageChange={handlePageChange}
                />
              </div>
            }
          </>
          :
          <div className='dummy-container'>
            <div className='dummy'>
              <NoSpace fontSize='160' />
              <span className='dummy-text'>No Space Assign to You!!</span>
            </div>
          </div>
      }
    </div>
  )
}
