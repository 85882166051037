
import history from 'App/History'
import clsx from 'clsx';
import { Tournament } from 'core/models/Tournament';
import { getDate, getTime } from 'core/utils/Date';
import { Tag } from 'rsuite';

interface TournamentCardProps {
  challenge: Tournament;
}

export default function ChallengeCard({ challenge }: TournamentCardProps) {
  function openDetails() {
    history.push(`/challenges/${challenge.groupId}`)
  }

  return (
    <div className='challenge-card' onClick={openDetails}>
      <div className='challenge-detail'>
        <div className='challenge-id-status-wrapper'>
          <span className='challenge-id'>{challenge?.title}</span>
          <Tag className='status' color={challenge?.resultUpdateStatus === 'live' ? 'green' : "blue"}>{challenge.resultUpdateStatus.toUpperCase()}</Tag>
        </div>
        <div className='quotes'>{challenge?.description}</div>
        <div className='image-wrapper'>
          <img className='challenge-image' src={challenge?.content?.icon} alt='challengeImage' />
        </div>
      </div>
      <div className='date-time-wrapper'>
        <span className={clsx('date', 'name')}>{challenge?.gameData?.name}</span>
        <span className='date'>&#x2022;</span>
        <span className='date'> {getDate(challenge?.startDate, "DD MMM")}</span>
        <span className='date'>&#x2022;</span>
        <span className='date'>{getTime(challenge?.startDate, "H:m")}</span>
      </div>
    </div>
  )
}
