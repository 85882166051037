export function setToken(token: string) {
  localStorage.setItem('moderator-token', token)
}

export function getToken() {
  return localStorage.getItem('moderator-token')
}

export function removeToken() {
  localStorage.removeItem('moderator-token')
}

export function clearUser() {
  removeToken()
}