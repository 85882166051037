import history from 'App/History';
import { Shorts } from 'core/models/Shorts'
import { getDate } from 'core/utils/Date';
import { useState } from 'react';
import { Button, Drawer, Icon, Modal } from 'rsuite';
import { useNotification } from 'shared/hooks/useNotification';


interface ShortsCardProps {
  short: Shorts;
  isMobile: boolean;
  onDelete(id: any): void;
  spaceId: string;

}

export default function ShortsCard({ short, isMobile, onDelete, spaceId }: ShortsCardProps) {
  const [confirm, setConfirm] = useState(false)
  const showNotification = useNotification()


  function handleDelete() {
    setConfirm(false)
    onDelete(short.id)
  }

  function redirectToDetail() {
    if (short.status === 'Published') {
      history.push(`/space/${spaceId}/short-detail/${short.id}`)
    }
    else {
      showNotification({
        title: 'Error',
        type: 'error',
        message: 'This bytes is Unpublished'
      })
    }
  }

  function ConfirmationPop() {
    return (
      <>
        <div className='confirmation-pop'>Are you sure you want to delete this Bytes ?</div>
        <div className='btn-container'>
          <Button id='schedule-cancel' className='cancel-btn' appearance='primary' onClick={() => setConfirm(false)}>No</Button>
          <Button id='schedule-confirm' appearance='primary' onClick={handleDelete}>Yes</Button>
        </div>
      </>
    )
  }

  return (
    <>
      <div className='shorts-card'>
        <div className='published-details'>
          <div className='publish'>{short.status}</div>
          <div className='date'>{getDate(short.publishedAt, 'DD-MM-YYYY hh:mm A')}</div>
        </div>
        <div className='shorts-preview-wrapper' onClick={redirectToDetail}>
          <img src={short.preview} alt=' Bytes preview' className='shorts-preview' />
        </div>
        <button id='short-delete' onClick={() => setConfirm(true)} className='short-delete'>
          Delete
          <Icon icon='trash-o' className='delete-icon' />
        </button>
      </div>
      {
        isMobile ?
          <Drawer show={confirm} placement='bottom' onHide={() => setConfirm(false)} style={{ height: '20rem' }}>
            <Drawer.Header />
            <Drawer.Body>
              <ConfirmationPop />
            </Drawer.Body>
          </Drawer>
          :
          <Modal show={confirm} size='sm' onHide={() => setConfirm(false)}>
            <ConfirmationPop />
          </Modal>
      }
    </>
  )
}
