import { handleError } from "core/utils/HandleError";
import { handleResponse } from "core/utils/HandleResponse";
import { AuthHttpClient } from "core/utils/HttpClient";

export function getAssignedSpaces(params: any) {
  return AuthHttpClient
    .get('/moderator/space', { params })
    .then(handleResponse)
    .catch(handleError)
}

export function getSpaceDetails(spaceId: string | number) {
  return AuthHttpClient
    .get(`/moderator/space/${spaceId}`)
    .then(handleResponse)
    .catch(handleError)
}

export function getSpacePosts(spaceId: string | number, params: any) {
  return AuthHttpClient
    .get(`/moderator/space/post/${spaceId}`, { params })
    .then(handleResponse)
    .catch(handleError)
}

export function getSpacePostById(postId: string | number, spaceId: number | string) {
  return AuthHttpClient
    .get(`/moderator/post/${postId}`, { params: { spaceId } })
    .then(handleResponse)
    .catch(handleError)
}

export function createSpacePost(payload: any) {
  return AuthHttpClient
    .post(`moderator/space/post`, payload)
    .then(handleResponse)
    .catch(handleError)
}

export function updateSpacePostStatus(payload: any) {
  return AuthHttpClient
    .put(`moderator/space/post`, payload)
    .then(handleResponse)
    .catch(handleError)
}

export function createPostComment(payload: any) {
  return AuthHttpClient
    .post('/moderator/post/comment', payload)
    .then(handleResponse)
    .catch(handleError)
}

export function deletePostComment(commentId: number) {
  return AuthHttpClient
    .delete(`/moderator/post/comment/${commentId}`)
    .then(handleResponse)
    .catch(handleError)
}