import { handleError } from 'core/utils/HandleError';
import { handleResponse } from 'core/utils/HandleResponse';
import { AuthHttpClient } from 'core/utils/HttpClient';

export function getChallengeResult(params: any) {
  return AuthHttpClient
    .get('/moderator/user/result', { params })
    .then(handleResponse)
    .catch(handleError)
}
export function updateChallengeStatus(params: any) {
  return AuthHttpClient
    .put('/moderator/user/result/status', { ...params })
    .then(handleResponse)
    .catch(handleError)
}