import { SHORTS_LIST_ACTION } from 'core/constants/Actions'
import { Action } from 'core/models/ActionTypes'
import { Shorts } from 'core/models/Shorts';

const initialState: { shorts: Shorts[], count: number, currentPage: number, isLoading: boolean } = {
  shorts: [],
  isLoading: false,
  count: 0,
  currentPage: 0
}

interface ActionType extends Action {
  payload: {
    shorts: Shorts[];
    count: number;
    currentPage: number;
  }
}

export default function shortsListReducer(state = initialState, action: ActionType) {

  switch (action.type) {
    case SHORTS_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case SHORTS_LIST_ACTION.SUCCESS:
      const { shorts, count, currentPage } = action.payload;
      return { ...state, shorts, isLoading: false, count, currentPage }

    case SHORTS_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}