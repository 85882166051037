import { getDate } from "core/utils/Date";
import { Table, TableCellProps } from "rsuite"

interface StatusCellProps extends TableCellProps {
  onClick(rowData: any): void;
  rowData: any;
}

export default function TimeCell({ onClick, rowData, ...props }: StatusCellProps) {
  return (
    <Table.Cell  {...props} style={{ background: 'none', color: 'red' }} >
      <div id='match-time' className={rowData.isCompleted ? 'disable' : 'active'} onClick={() => onClick(rowData)}>
        <p className='match-time-value'> {rowData.time ? getDate(rowData.time, 'DD-MMM-YYYY,HH:mm') : 'Not Scheduled'}</p>
      </div>
    </Table.Cell >
  )
}