import { WrappedFieldArrayProps, Field } from 'redux-form'
import { FormConfig } from 'core/models/FormConfig'
import { TeamMember } from 'core/models/GroupDetails'
import { onlyNumber } from 'core/utils/Normalizer'
import InputWithLabel from '../redux-form/InputWithLabel'
import { Col, Grid, Row } from 'rsuite'

interface Type {
  members: TeamMember[];
  playerFormConfig: FormConfig[];
  currentTeamIndex: number;
}

type AllProps = WrappedFieldArrayProps<Type> & Type

export default function MemberResult({ fields, playerFormConfig, members, currentTeamIndex }: AllProps) {

  function getNormalizer(form: FormConfig) {
    if (form.fieldType === 'number') {
      return (input: any) => onlyNumber(input, form.validations.min || 0, form.validations.max || Infinity)
    }
    return (input: any) => input
  }

  return (
    <Grid fluid className='grid-style'>
      <Row>
        {
          members.map((member, index) =>
            <div key={member.id}>
              <Col lg={6} sm={18} md={1} xs={24}>
                <div className='in-game-name'>{member.inGameName}</div>
                <Field
                  name={`results[${currentTeamIndex}].${fields.name}[${index}].userId`}
                  type="hidden"
                  component="input"
                />
                {
                  playerFormConfig.map(form =>
                    <Field
                      key={form.key}
                      normalize={getNormalizer(form)}
                      name={`results[${currentTeamIndex}].${fields.name}[${index}].stats.${form.key}`}
                      placeholder={form.placeHolder}
                      component={InputWithLabel}
                      className='member-result-input-style'
                    />
                  )
                }
              </Col>
            </div>
          )
        }
      </Row>
    </Grid>
  )
}